import * as appSettingsApi from "../apis/appSettingsApi";

const documentExtension = "Document/";

export const getDocument = async (key, fileName, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + documentExtension + key + "/" + fileName;
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.blob());
        } else if (response && response.status === 400) {
            return response.text().then(Promise.reject.bind(Promise));
        } else {
            return Promise.reject('Error occured during File download.');
        }
    }).catch(err => {
        console.log(err) // eventually to be handling in App logging
        return Promise.reject(err);
    });
}

export const uploadDocument = async (file, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + documentExtension;
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'POST',
        body: file,
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        let r = response.text();
        if (response && response.status && response.status === 200) {
            return Promise.resolve(r);
        } else if (response && response.status === 400) {
            return r.then(Promise.reject.bind(Promise));
        } else {
            return Promise.reject('Error occured during File upload.');
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}
