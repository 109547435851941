import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

export const SubSectionHeader = (props) => {
    let { children } = props;
    return <Row>
        <Col md={12} className="sub-header">
            <span>{children}</span>
        </Col>
    </Row>;
}

SubSectionHeader.propTypes = {
    children: PropTypes.node
}