import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

export const FormField = (props) => {
    let { required, name, id, label, type, options, placeholder, value, error, selectedOptions, datasource, min, max, maxLength, mask, disabled, showRequiredErrorOnLoad, onChange = (() => { }), onFocusOut = (() => { }) } = props;
    const [requiredError, setRequiredError] = useState((!showRequiredErrorOnLoad || value ? '' : (label + ' is required'))); 
    const [dropdownOpenLang, setDropdownOpenLang] = useState(false);
    const toggleLang = () => setDropdownOpenLang(prevState => !prevState);

    useEffect(() => {
        setRequiredError((showRequiredErrorOnLoad && (!value && typeof value !== "boolean") ? (label + ' is required') : ''));
    }, [showRequiredErrorOnLoad])

    const onFieldChange = (e) => {
        if (required) {
            if (!e.target.value)
                setRequiredError(label + " is required");
            else
                setRequiredError(null);     
        }
        onChange(e);
    }

    const onFocusLost = (e) => {
        onFocusOut(e);
    }

    if (type === "yes/no") {
        return <FormGroup className="form-field">
            <Label className={required ? "required-field" : ""} for={name}>{label}</Label>
            <Row>
                <Col md={2} sm={3}>
                    <Input type="radio" name={name} id={id+"_yes"} value="Y" onChange={onFieldChange} checked={value === true}/><Label>Yes</Label>
                </Col>
                <Col md={2} sm={3}>
                    <Input type="radio" name={name} id={id+"_no"} value="N" onChange={onFieldChange} checked={value === false}/><Label>No</Label>
                </Col>
            </Row>
            {error && <span className='error'>{error}</span>}
            {requiredError && <span className='error'>{requiredError}</span>}
        </FormGroup>;
    }
    if (type === "select") {
        return <FormGroup className="form-field">
            <Label className={required ? "required-field" : ""} for={name}>{label}</Label>
            <Input type={type} name={name} id={id} placeholder={placeholder} value={value} onChange={onFieldChange}>
                <option value="" >Select</option>
                {options.map(o => <option value={o.value} key={"option-" + o.value} > {o.label}</option>)}
            </Input>
            {error && <span className='error'>{error}</span>}
            {requiredError && <span className='error'>{requiredError}</span>}
        </FormGroup>;
    }
    if (type === "Dropdown") {
        return <FormGroup className="form-field">
            <Label className={required ? "required-field" : ""} >{label}</Label>
            <Dropdown direction="down" isOpen={dropdownOpenLang} toggle={toggleLang}>
                <DropdownToggle className="dropdown text-left">
                    <Label className="dropdown-label">{value}</Label>
                    <Label className="arrow down pull-right"></Label>
                </DropdownToggle>
                <DropdownMenu
                    modifiers={{
                        setMaxHeight: {
                            enabled: true,
                            fn: (data) => {
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                    },
                                };
                            },
                        },
                    }}
                >
                    {datasource ? datasource.map(e => {
                        return (
                            <Col md={12} key={e.name}>
                                <FormGroup className="margin-left">
                                    <Input type="checkbox" name={name} id={e.value} key={e.name} onChange={onFieldChange}
                                        checked={(selectedOptions?.includes(e.value) > 0 ? "checked" : "")} />{"   "}{e.name}
                                </FormGroup>
                            </Col>

                        )
                    }) : ""}
                </DropdownMenu>
            </Dropdown>
            {/*<Input className="" type="text" name="formNpi" id="formNpi" value="" autoComplete="off" />*/}
        </FormGroup>
    }
    if (type === "InputMask") {
        return <FormGroup className="form-field">
            <Label className={required ? "required-field" : ""} for={name}>{label}</Label>
            <InputMask mask={mask} maskChar={null} name={name} id={id}
                value={value} errors={error} onChange={onFieldChange}
                autoComplete="off" className="inputMask, form-control" placeholder={placeholder} />
            {error && <span className='error'>{error}</span>}
            {!error && requiredError && <span className='error'>{requiredError}</span>}
        </FormGroup>;
    }
    return <FormGroup className="form-field">
        <Label className={required ? "required-field" : ""} for={name}>{label}</Label>
        <Input type={type} name={name} id={id} placeholder={placeholder} value={value}
            disabled={disabled}
            onChange={onFieldChange} onBlur={onFocusLost} min={min} max={max} maxLength={maxLength} />
        {error && <span className='error'>{error}</span>}
        {!error && required && requiredError && <span className='error'>{requiredError}</span>}
    </FormGroup>;
};

FormField.propTypes = {
    type: PropTypes.oneOf(["text", "email", "select", "file", "radio", "checkbox", "textarea", "button", "reset", "submit",
        "date", "datetime", "datetime-local", "hidden", "image", "month", "number", "range", "search", "tel", "url", "week",
        "password", "datetime", "time", "color", "yes/no", "Dropdown", "InputMask"]).isRequired,
    required: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    placeholder: PropTypes.string,
    mask: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocusOut: PropTypes.func,
    error: PropTypes.string,
    selectedOptions: PropTypes.array,
    datasource: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    showRequiredErrorOnLoad: PropTypes.bool
}
