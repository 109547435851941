import * as applicationUserApis from "../apis/applicationUserApis";
import * as appSettingsApis from "../apis/appSettingsApi";
import * as types from "../constants/actionTypes";
import { call, put, takeLatest, select } from "redux-saga/effects";

function* userSagas() {
    yield takeLatest(types.SIGNUP_UPDATE_USER_INFO_START, updateUserInfo);
    yield takeLatest(types.SIGNUP_UPDATE_USER_INFO_INITIALIZE_START, updateUserInfoInitialize);
    yield takeLatest(types.SIGNUP_GET_USER_BY_EMAIL_START, getUserByEmail);
    yield takeLatest(types.SIGNUP_GET_TAX_ID_START, getTaxId);
    yield takeLatest(types.SIGNUP_GET_NPI_START, getNpi);
    yield takeLatest(types.SIGNUP_RESEND_EMAIL_START, resendEmail);
    yield takeLatest(types.SIGNIN_GET_USER_INFO_START, getUserInfo);
    yield takeLatest(types.LOGOUT, logOut);
    yield takeLatest(types.FORGOT_PASSWORD_START, forgotPassword);
    yield takeLatest(types.RESET_PASSWORD_START, resetPassword);
    yield takeLatest(types.CONFIRM_EMAIL_START, confirmEmail);
    yield takeLatest(types.GET_TIMEOUT_SETTINGS_START, getTimoutSettings);
}

function* getUserInfo(params) {
    try {
        const userInfo = yield call(applicationUserApis.signIn, params.username, params.password);
        if (typeof userInfo !== "string") {
            yield put({ type: types.SIGNIN_GET_USER_INFO_SUCCESS, userInfo });
        }
        else {
            yield put({ type: types.SIGNIN_GET_USER_INFO_FAILURE, error: userInfo });
        }
        
    } catch (e) {
        yield put({ type: types.SIGNIN_GET_USER_INFO_FAILURE, error: e });
    }
}

function* updateUserInfo(params) {
    try {
        const result = yield call(applicationUserApis.signUp, params.npi, params.taxId, params.firstName, params.lastName, params.email, params.password, params.providerType, params.phoneNumber);
        yield put({ type: types.SIGNUP_UPDATE_USER_INFO_SUCCESS, accountCreated: result });
    } catch (e) {
        yield put({ type: types.SIGNUP_UPDATE_USER_INFO_FAILURE, error: e });
    }
}

function* updateUserInfoInitialize() {
    yield put({ type: types.SIGNUP_UPDATE_USER_INFO_INITIALIZE_SUCESS });
}

function* getUserByEmail(params) {
    try {
        const userInfo = yield call(applicationUserApis.getUserByEmail, params.email);
        yield put({ type: types.SIGNUP_GET_USER_BY_EMAIL_SUCCESS, userExists: userInfo ? true : false });
    } catch (e) {
        yield put({ type: types.SIGNUP_GET_USER_BY_EMAIL_FAILURE, error: e });
    }
}

function* getTaxId(params) {
    try {
        const userInfo = yield select((state) => state.user?.userInfo);
        const taxId = yield call(applicationUserApis.getTaxId, params.taxId, userInfo);
        yield put({ type: types.SIGNUP_GET_TAX_ID_SUCCESS, taxIdInUse: taxId ? 'Tax Id is already contracted or associated to another application' : '' });
    } catch (e) {
        yield put({ type: types.SIGNUP_GET_TAX_ID_FAILURE, error: e });
    }
}

function* getNpi(params) {
    try {
        const npi = yield call(applicationUserApis.getNpi, params.npi);
        yield put({ type: types.SIGNUP_GET_NPI_SUCCESS, npiIsValid: npi ? '' : 'NPI could not be validated against NPPES NPI Registry.' });
    } catch (e) {
        yield put({ type: types.SIGNUP_GET_NPI_FAILURE, error: e });
    }
}

function* resendEmail(params) {
    try {
        yield call(applicationUserApis.resendEmail, params.email);
        yield put({ type: types.SIGNUP_RESEND_EMAIL_SUCCESS});
    } catch (e) {
        yield put({ type: types.SIGNUP_RESEND_EMAIL_FAILURE, error: e });
    }
}

function* logOut(action) {
    try {
        const success = yield call(applicationUserApis.logOut);
        yield put({ type: types.LOGOUT_SUCCESS, success: success });
    } catch (e) {
        yield put({ type: types.LOGOUT_FAILURE, message: e.message });
    }
}

function* forgotPassword(params) {
    try {
        const user = yield call(applicationUserApis.forgotPassword, params.email);
        yield put({ type: types.FORGOT_PASSWORD_SUCCESS, emailConfirmation: user ? true : false  });
    } catch (e) {
        yield put({ type: types.FORGOT_PASSWORD_FAILURE, error: e });
    }
}

function* resetPassword(params) {
    try {        
        const resetSuccess = yield call(applicationUserApis.resetPassword, params.password, params.email, params.token);
        yield put({ type: types.RESET_PASSWORD_SUCCESS, resetSuccess: resetSuccess });
    } catch (e) {
        yield put({ type: types.RESET_PASSWORD_FAILURE, error: e });
    }
}

function* confirmEmail(params) {

    try {
        const result = yield call(applicationUserApis.confirmEmail, params.email, params.token);
        yield put({ type: types.CONFIRM_EMAIL_SUCCESS, emailVerified: result });
    } catch (e) {
        yield put({ type: types.CONFIRM_EMAIL_FAILURE, error: e });
    }
}

function* getTimoutSettings(params) {
    try {
        const result = yield call(appSettingsApis.getTimoutSettings);
        yield put({ type: types.GET_TIMEOUT_SETTINGS_SUCCESS, timeoutSettings: result });
    } catch (e) {
        yield put({ type: types.GET_TIMEOUT_SETTINGS_FAILURE, error: e });
    }
}
export default userSagas;