import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as dashboardApis from '../apis/dashboardApis';

function* dashboardSagas() {
    yield takeLatest(types.DASHBOARD_GET_APPLICATION_STATUS, getApplicationStatus);
    yield takeLatest(types.DASHBOARD_GET_MESSAGES, getMessages);
}

function* getApplicationStatus(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const applicationStatus = yield call(dashboardApis.getApplicationStatus, userInfo);
        yield put({ type: types.DASHBOARD_GET_APPLICATION_STATUS_SUCCESS, applicationStatus });
    } catch (e) {
        yield put({ type: types.DASHBOARD_GET_APPLICATION_STATUS_FAILURE, message: e.message });
    }
}

function* getMessages(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const messages = yield call(dashboardApis.getMessages, userInfo);
        const messageCount = messages.messages.length;
        yield put({ type: types.DASHBOARD_GET_MESSAGES_SUCCESS, messageCount });
    } catch (e) {
        yield put({ type: types.DASHBOARD_GET_MESSAGES_FAILURE, message: e.message });
    }
}

export default dashboardSagas;