import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

export const CustomAlert = (props) => {
    let { type } = props;

    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);

    useEffect(() => {
        setVisible(true);
        window.setTimeout(() => {
            setVisible(false);
        }, 5000);
    }, [])

    return (
        <Alert color={type} isOpen={visible} toggle={onDismiss}>
            {type === "success" ? "Changes saved successfully."
                : type === "danger" ? "Error occured while saving. Please try again."
                    : ""}
        </Alert>
    );
}

CustomAlert.propTypes = {
    type: PropTypes.string
}