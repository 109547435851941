import React from 'react';

export const ProviderNavigation = () => {
    return <div className="provider-navigation">
        <nav className="navbar-content navbar navbar-expand-lg">
            <a className="navbar-brand" href="https://www.bswhealthplan.com/Providers/Pages/default.aspx" target="_blank" rel="noreferrer">Providers</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Network Participation <span className="caret"></span>
                        </a>
                        <div className="dropdown-menu" >
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/default.aspx#join" target="_blank" rel="noreferrer">Join Our Network</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/default.aspx#manage-account" target="_blank" rel="noreferrer">Account Management</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/default.aspx#phcs" target="_blank" rel="noreferrer">PHCS Providers</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
                            Provider Resources <span className="caret"></span>
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Resources.aspx" target="_blank" rel="noreferrer">Contact Us</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Resources.aspx#provider-news" target="_blank" rel="noreferrer">Provider News</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Resources.aspx#provider-manual-training" target="_blank" rel="noreferrer">Provider Manual &amp; Training</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Resources.aspx#complaints-appeals" target="_blank" rel="noreferrer">Complaints &amp; Appeals</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
                            Pharmacy <span className="caret"></span>
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Pharmacy.aspx" target="_blank" rel="noreferrer">Resources</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Pharmacy.aspx#pharmacy-locations" target="_blank" rel="noreferrer">Pharmacy Locations</a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Pharmacy.aspx#drug-list-formularies" target="_blank" rel="noreferrer">Drug Lists/Formularies </a>
                            <a className="dropdown-item" href="https://www.bswhealthplan.com/Providers/Pages/Pharmacy.aspx#medication-authorization" target="_blank" rel="noreferrer">Drug Requests</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="https://www.bswhealthplan.com/Providers/Pages/Medical.aspx" target="_blank" rel="noreferrer">Medical Resources</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="https://www.bswhealthplan.com/Providers/Pages/Claims-Billing.aspx" target="_blank" rel="noreferrer">Claims &amp; Billing</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>;
}