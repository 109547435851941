import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import * as types from '../../constants/actionTypes';
import * as validators from '../../constants/validations';
import PropTypes from 'prop-types';
import * as FileSaver from 'file-saver';

export const FileUpload = (props) => {
    const maxFileSize = 16;  //MAX FILE SIZE ALLOWED IN MB.
    const validFileTypes = ".doc,.docx,.pdf"; //ALLOWED FILE TYPES FOR UPLOADS
    const fileInputRef = useRef();
    let { name, required, label, id, fileUploaded, error, showRequiredErrorOnLoad, applicationId } = props
    const [err, setErr] = useState((!showRequiredErrorOnLoad ||  fileUploaded?.blobKey ? '' : (label + ' is required')));
    const [downloading, setDownloading] = useState(false);

    const dispatch = useDispatch();
    const file = useSelector((state) => {
        return state.document.file;
    });
    const apiError = useSelector((state) => {
        return state.document.error;
    });
    const isFetching = useSelector((state) => {
        return state.document.isFetching;
    });
    const onUploadClick = event => {        
        fileInputRef.current.click();
    }
    const onInputClick = event => {
        event.target.value = '';
    }

    const onChangeHandler = event => {
        var selectedFile = event.target.files[0];
        // Validations around required, fileType and fileSize constraints
        let err = '';
        if (!selectedFile) {
            setErr(required ? (label + " is required") : '');
        } else {
            err = validators.validateFileUpload(selectedFile, validFileTypes, maxFileSize);
            setErr(err);
        }
        var f = (typeof selectedFile == undefined || selectedFile == null || err.length > 0) ? "" : selectedFile;
        var p = typeof applicationId == "undefined" ? "" : applicationId;
        if (f || p) {
            //Upload selected file and delete previous file
            const formData = new FormData();
            formData.append("file", f);
            formData.append("applicationId", p)
            dispatch({ type: types.FILE_UPLOAD_START, file: formData, name: event.target.name });
        }
    }
    const getFile = (e) => {
        e.preventDefault();
        setDownloading(true);
        dispatch({ type: types.FILE_DOWNLOAD_START, key: fileUploaded?.blobKey, fileName: fileUploaded?.fileName});
    }
    useEffect(() => {
        if (!isFetching && downloading) {
            setDownloading(false);
            if (file && apiError.length === 0)
                FileSaver.saveAs(file, fileUploaded?.fileName);
        }
    }, [file, apiError]);

    useEffect(() => {
        setErr((!showRequiredErrorOnLoad || fileUploaded?.blobKey ? '' : (label + ' is required') ));
    }, [showRequiredErrorOnLoad]);

    return (
        <div>
            <Row>
                <Col xs="auto">
                    <FormGroup>
                        <Label className={required ? "required-field" : ""} for={name}>{label}</Label>
                        <div>
                            <Input innerRef={fileInputRef} type="file" id={id} name={name} onClick={onInputClick} onChange={onChangeHandler} accept={validFileTypes} hidden />
                            <Button id={id} name={name} className="upload-button" onClick={onUploadClick}>Upload File</Button>
                            {fileUploaded && fileUploaded.blobKey &&
                                <a id={id} href="#" onClick={getFile} target="_blank">{fileUploaded.fileName}</a>}
                        </div>
                        {error && <span className='error'>{error}</span>}
                        {err.length > 0 && <span className='error'>{err}</span>}
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

FileUpload.propTypes = {
    required: PropTypes.bool,
    name: PropTypes.string,
    onUploadComplete: PropTypes.func,
    label: PropTypes.string,
    id: PropTypes.string,
    applicationId: PropTypes.string,
    fileUploaded: PropTypes.object,
    error: PropTypes.string,
    showRequiredErrorOnLoad: PropTypes.bool
};