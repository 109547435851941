export const suffix = [
    { value: 'Jr', label: 'Jr' },
    { value: 'Sr', label: 'Sr' },
    { value: 'III', label: 'III' },
    { value: 'IV', label: 'IV' },
    { value: 'V', label: 'V' },
    { value: 'VI', label: 'VI' },
    { value: 'VII', label: 'VII' },
    { value: 'VIII', label: 'VIII' },
    { value: 'IX', label: 'IX' },
    { value: 'X', label: 'X' }
];

Object.freeze(suffix);