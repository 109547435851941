import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as types from '../constants/actionTypes';

export const AutoLogoutTimer = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()

    const [idleModal, setIdleModal] = useState(false);
    const logOutSuccess = useSelector((state) => state.user.success);
    const userInfo = useSelector((state) => state.user.userInfo);
    const timeoutSettings = useSelector((state) => state.user.timeoutSettings);
    const idlTtimeoutSetting = timeoutSettings ? 1000 * 60 * timeoutSettings.idleTimeoutMinutes : 300000; // Default - // 5 minutes idle timer
    const sessionTimeoutSetting = timeoutSettings ? 1000 * 60 * timeoutSettings.sessionTimeoutMinutes : 60000;// Default - 60 seconds - Logout automatically if session is still idle after pop-up is displayed
    let sessionTimeout = useRef(null);
    const [timeoutId, setTimeoutId] = useState('');

    useEffect(() => {
        if (logOutSuccess) {
            history.push('/');
        }
    }, [logOutSuccess]);

    const handleOnIdle = event => {
        if (userInfo) {
            setIdleModal(true);
            sessionTimeout = () => setTimeout(() => logOut(), sessionTimeoutSetting);
            setTimeoutId(sessionTimeout());
        }
        else {
            setIdleModal(false);
        }
    }   

    const stayActive = () => {
        clearTimeout(timeoutId);
        reset();
        setIdleModal(false);      
    };
    const logOut = () => {
        setIdleModal(false);
        clearTimeout(timeoutId);
        dispatch({ type: types.LOGOUT });
    }

    const { reset } =
        useIdleTimer({
            timeout: idlTtimeoutSetting,
            onIdle: handleOnIdle,
            debounce: 500
        });


    return (
        <div>
             <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
                <ModalHeader toggle={() => setIdleModal(false)}>
                    Session expire warning
        </ModalHeader>
                <ModalBody>
                    Due to inactivity your session will expire soon. Do you want to extend the session?
        </ModalBody>
                <ModalFooter>
                    <button className="btn btn-info" onClick={() => logOut()}>Logout</button>
                    <button className="btn btn-success" onClick={() => stayActive()}>Stay Active</button>
                </ModalFooter>
            </Modal>
            
        </div>

    )
}
