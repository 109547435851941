import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import * as types from '../../constants/actionTypes';
import * as validators from '../../constants/validations';
import { SectionHeader } from '../common/SectionHeader';
import { FormField } from '../common/FormField';
import * as providerTypes from '../../constants/providerTypes';
import { ClientLoggingModel } from '../../models/ClientLoggingModel';
import { VerifyEmail } from '../common/VerifyEmail';
import { useFeatureFlag } from '../../common/useFeatureFlag';

export const SignUp = (props) => {
    const dispatch = useDispatch();
    const accountCreated = useSelector((state) => state.user.accountCreated);
    const error = useSelector((state) => state.user.error);
    const [createActionDone, setCreateActionDone] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const isFetching = useSelector((state) => state.user.isFetching);
    const npiIsValid = useSelector((state) => state.user.npiIsValid);
    const taxIdInUse = useSelector((state) => state.user.taxIdInUse);
    const userExists = useSelector((state) => state.user.userExists);
    const providerTypesList = providerTypes.ProviderTypes;
    const { enabled: multipleApplicationsEnabled } = useFeatureFlag('125081_PAMMultipleApplicationsPerUser');
    
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);

    const [user, setUser] = useState({
        npi: null,
        taxId: null,
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
        providerType: null,
        phoneNumber: null
    });
    const [errors, setErrors] = useState({
        npi: '',
        taxId: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        providerType: '',
        phoneNumber: ''
    });

    const createAccount = (e) => {
        e.preventDefault();
        var message = "Submitting API call to create account for " + user.firstName + " " + user.lastName + ".";
        var methodName = "createAccount";
        let userInfoTolog = { ...user, password: "", confirmPassword: "" };
        var jsonResult = JSON.stringify(userInfoTolog);
        logInfoWrite(message, methodName, jsonResult);
        dispatch({
            type: types.SIGNUP_UPDATE_USER_INFO_START, npi: user.npi, taxId: user.taxId, firstName: user.firstName, lastName: user.lastName, email: user.email, password: user.password, providerType: user.providerType, phoneNumber: user.phoneNumber
        });
    }

    useEffect(() => {
        dispatch({ type: types.SIGNUP_UPDATE_USER_INFO_INITIALIZE_START });
        dispatch({ type: types.GET_TIMEOUT_SETTINGS_START });
    }, []);

    useEffect(() => {
        if (accountCreated || error?.length > 0) {
            setCreateActionDone(true);
        }
        else {
            setCreateActionDone(false);
        }
    }, [accountCreated, error]);

    useEffect(() => {
        canSubmit();
    }, [errors]);
    useEffect(() => {
        if (!isFetching)
            setErrors({ ...errors, npi: npiIsValid });
    }, [npiIsValid]);
    useEffect(() => {
        if (!isFetching)
            setErrors({ ...errors, taxId: taxIdInUse });
    }, [taxIdInUse]);
    useEffect(() => {
        if (!isFetching)
            setErrors({ ...errors, email: (userExists ? 'Email already exists.' : '') });
    }, [userExists]);

    const onFocusOutTrimSpaces = event => {
        const { name, value } = event.target;
        var newValue = value.trim();
        let updatedErrors = { ...errors };
        event.preventDefault();
        switch (name) {
            case 'firstName':
                updatedErrors.firstName = validators.validateLength(name, value, 15, 1);
                break;
            case 'lastName':
                updatedErrors.lastName = validators.validateLength(name, value, 60, 1);
                break;
        }
        setUser({ ...user, [name]: newValue });
        setErrors(updatedErrors);

    }

    const inputChangeHandler = event => {
        const { name, value } = event.target;
        event.preventDefault();
        let message = "";
        let methodName = "inputChangeHandler";
        let jsonResult = "";

        let updatedErrors = { ...errors };
        switch (name) {
            case 'npi':
                let npiErr = validators.validateNpi(value);
                if (npiErr === '') {
                    message = "Performing API call to validate NPI " + value + ".";
                    jsonResult = JSON.stringify(value);
                    logInfoWrite(message, methodName, jsonResult);
                }

                updatedErrors.npi = (npiErr === '')
                    ? dispatch({ type: types.SIGNUP_GET_NPI_START, npi: value })
                    : npiErr;
                break;
            case 'taxId':
                let taxIdErr = validators.validateTaxId(value);
                if (taxIdErr === '') {
                    message = "Performing API call to validate tax ID " + value + ".";
                    jsonResult = JSON.stringify(value);
                    logInfoWrite(message, methodName, jsonResult);
                }
                updatedErrors.taxId = (taxIdErr === '')
                    ? dispatch({ type: types.SIGNUP_GET_TAX_ID_START, taxId: value })
                    : taxIdErr;
                break;
            case 'firstName':
                updatedErrors.firstName = validators.validateLength(name, value, 15, 1);
                break;
            case 'lastName':
                updatedErrors.lastName = validators.validateLength(name, value, 60, 1);
                break;
            case 'email':
                let emailErr = validators.validateEmail(value);
                if (emailErr === '') {
                    message = "Performing API call to get user by e-mail address " + value + ".";
                    jsonResult = JSON.stringify(value);
                    logInfoWrite(message, methodName, jsonResult);
                }
                updatedErrors.email = (emailErr === '')
                    ? dispatch({ type: types.SIGNUP_GET_USER_BY_EMAIL_START, email: value })
                    : emailErr;
                break;
            case 'password':
                updatedErrors.password = validators.validatePassword(value);
                if (user.confirmPassword) {
                    updatedErrors.confirmPassword = validators.validateConfirmPassword(user.confirmPassword, value)
                }
                break;
            case 'confirmPassword':
                updatedErrors.confirmPassword = validators.validateConfirmPassword(value, user.password);
                break;
            case 'providerType':
                updatedErrors.providerType = value ? '' : 'Please select valid Provider Type';
                break;
            case 'phoneNumber':
                updatedErrors.phoneNumber = validators.validatePhone(value);
                break;
            default:
                break;
        }

        setUser({ ...user, [name]: value });
        setErrors(updatedErrors);
    }

    const canSubmit = () => {
        if ((!multipleApplicationsEnabled && (!user.npi || !user.taxId))
            || !user.firstName || !user.lastName || !user.email || !user.password || !user.confirmPassword || !user.providerType
            || (multipleApplicationsEnabled && !user.phoneNumber)                      
            || !validators.validateForm(errors)) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "SignUp",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, user });
        }
    }

    return <div>
        {!createActionDone ?
            <div className="sign-up">
                <SectionHeader>Register</SectionHeader>
                <p>Please create an account and enter all fields.</p>
                <div className="form-card">
                    <p>* All fields are required</p>
                    <Form className="form" onSubmit={createAccount}>
                        {!multipleApplicationsEnabled &&
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="npi">National Provider Identifier (NPI)</Label>
                                        <Input className={(errors.npi.length > 0 ? "input-error" : "")} type="text" name="npi" id="npi" value={user.npi || ""} onChange={inputChangeHandler} autoComplete="off" />
                                        {errors.npi.length > 0 &&
                                            <span className='error'>{errors.npi}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="taxId">Tax ID</Label>
                                        <Input className={(errors.taxId.length > 0 ? "input-error" : "")} type="text" name="taxId" id="taxId" value={user.taxId || ""} onChange={inputChangeHandler} autoComplete="off" />
                                        {errors.taxId.length > 0 &&
                                            <span className='error'>{errors.taxId}</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">Contact First Name</Label>
                                    <Input className={(errors.firstName.length > 0 ? "input-error" : "")} type="text" name="firstName" id="firstName" value={user.firstName || ""} onChange={inputChangeHandler} autoComplete="off" onBlur={onFocusOutTrimSpaces} />
                                    {errors.firstName.length > 0 &&
                                        <span className='error'>{errors.firstName}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Contact Last Name</Label>
                                    <Input className={(errors.lastName.length > 0 ? "input-error" : "")} type="text" name="lastName" id="lastName" value={user.lastName || ""} onChange={inputChangeHandler} autoComplete="off" onBlur={onFocusOutTrimSpaces} />
                                    {errors.lastName.length > 0 &&
                                        <span className='error'>{errors.lastName}</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        {multipleApplicationsEnabled &&
                            <Row form>
                                <Col md={12}>
                                    <FormField type="InputMask"
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        label="Contact Phone"
                                        value={user.phoneNumber || ""}
                                        onChange={inputChangeHandler}
                                        error={errors.phoneNumber}
                                        placeholder="XXX-XXX-XXXX"
                                        mask="999-999-9999"
                                    />
                                </Col>
                            </Row>
                        }
                        <FormGroup>
                            <Label for="email">Email Address</Label>
                            <Input className={(errors.email.length > 0 ? "input-error" : "")} type="email" name="email" id="email" value={user.email || ""} onChange={inputChangeHandler} autoComplete="off" onBlur={onFocusOutTrimSpaces} />
                            {errors.email.length > 0 &&
                                <span className='error'>{errors.email}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input className={(errors.password.length > 0 ? "input-error" : "")} type="password" name="password" id="password" value={user.password || ""} onChange={inputChangeHandler} />
                            {errors.password.length > 0 &&
                                <span className='error'>{errors.password}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="confirmPassword">Confirm Password</Label>
                            <Input className={(errors.confirmPassword.length > 0 ? "input-error" : "")} type="password" name="confirmPassword" id="confirmPassword" value={user.confirmPassword || ""} onChange={inputChangeHandler} />
                            {errors.confirmPassword.length > 0 &&
                                <span className='error'>{errors.confirmPassword}</span>}
                        </FormGroup>

                        <FormField type="select"
                            name="providerType"
                            id="providerType"
                            label="Provider Type"
                            options={providerTypesList}
                            onChange={inputChangeHandler}
                            error={errors.providerType}
                            value={user.providerType || ""}
                        />

                        <Button className="btn-submit" size="lg" block disabled={submitDisabled}>Create Account</Button>
                    </Form>
                </div>
            </div>
            :
            <div>
                <Row>
                    <Col md={12}>
                        {accountCreated ?
                            <VerifyEmail email={user.email} />
                            :
                            <span className="error">
                                {error}
                            </span>
                        }
                    </Col>
                </Row>
            </div>
        }
    </div>;
}

