import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as types from '../../constants/actionTypes';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';
import { ClientLoggingModel } from '../../models/ClientLoggingModel';

export const ConfirmEmail = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const emailVerified = useSelector((state) => state.user.emailVerified);
    const error = useSelector((state) => state.user.error);
    const [confirmationComplete, setConfirmationComplete] = useState(false);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    const userInfo = useSelector((state) => { return state.user.userInfo; });

    useEffect(() => {
        const { location } = props;
        const locationParams = location.search ? queryString.parse(location.search)
            : location.searchParms ? queryString.parse(location.searchParms) : null;
        var message = "Performing API call to confirm e-mail address " + locationParams.email + ".";
        var methodName = "useEffect[]";
        var jsonResult = JSON.stringify(locationParams.token);
        logInfoWrite(message, methodName, jsonResult);

        dispatch({
            type: types.CONFIRM_EMAIL_START,
            email: locationParams.email, token: locationParams.token
        });
    }, []);
    useEffect(() => {
        if (emailVerified || error?.length > 0)
            setConfirmationComplete(true);
    }, [emailVerified, error]);

    const goToLogin = (e) => {
        e.preventDefault();
        var message = "Returning to login page.";
        var methodName = "goToLogin";
        var jsonResult = JSON.stringify(userInfo);
        logInfoWrite(message, methodName, jsonResult);
        history.push('/');
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "ConfirmEmail",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    return <div>

        {confirmationComplete &&
            <div>
                <Row>
                    <Col md={12}>
                    {emailVerified ?
                            <span>
                                Your email has been successfully confirmed. Click here to <a href="#" onClick={goToLogin}> Login </a>.
                            </span>
                            :
                            <span className="page-error">
                                {error}
                            </span>
                        }
                    </Col>
                </Row>
            </div>}
    </div>;
}
ConfirmEmail.propTypes = {
    location: PropTypes.object
}


