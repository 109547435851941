import { AppConfigurationClient } from '@azure/app-configuration';
import { useEffect } from 'react';
import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../constants/actionTypes';
/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (flagKey = '') => {
    const appSettings = useSelector((state) => state.user.timeoutSettings);
    const flags = useSelector((state) => state.user.featureFlags);
    const dispatch = useDispatch();
    let flagValue = flags ? flags[flagKey] : false;
    const [enabled, setEnabled] = useState(typeof flagValue === 'undefined' ? false : flagValue);
    const [client, setClient] = useState(appSettings?.appConfigurationConnectionString ?
        new AppConfigurationClient(appSettings.appConfigurationConnectionString)
        : null);

    useMemo(async () => {
        if (!flagKey || !flagKey.toString().trim().length) {
            console.error('A feature flag key must be supplied.');
        } else {
            try {
                if (!flags || !(flagKey in flags)) {
                    let configClient;
                    if (client) {
                        configClient = client;

                    }
                    else {
                        if (appSettings?.appConfigurationConnectionString) {
                            console.log("if statement");
                            let newConfigClient = new AppConfigurationClient(appSettings?.appConfigurationConnectionString)
                            setClient(newConfigClient);
                            configClient = newConfigClient;
                        }
                        else {
                            return;
                        }
                    }
                    const result = await configClient.getConfigurationSetting({
                        key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
                    });
                    if (result && typeof result === 'object') {
                        console.debug('Feature: ' + JSON.parse(result.value).id, JSON.parse(result.value).enabled);
                        setEnabled(JSON.parse(result.value).enabled);
                        dispatch({ type: types.FEATURE_FLAG_SUCCESS, key: flagKey.toString().trim(), value: JSON.parse(result.value).enabled });
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [flagKey, appSettings]);

    return { enabled };
};

/**
 * Retrieves the specified configuration from Azure App Configuration.
 * @param {string} configKey App Config Key
 * @returns the configuration for the specified key
 */
const useConfiguration = (configKey = '') => {
    const [config, setConfig] = useState('');
    const appSettings = useSelector((state) => state.user.timeoutSettings);
    const [client, setClient] = useState(appSettings?.appConfigurationConnectionString ?
        new AppConfigurationClient(appSettings.appConfigurationConnectionString)
        : null);

    useMemo(async () => {
        if (!configKey || !configKey.toString().trim().length) {
            console.error('A config key must be supplied.');
        } else {
            try {
                let configClient;
                if (client) {
                    configClient = client;

                }
                else {
                    if (appSettings?.appConfigurationConnectionString) {
                        console.log("if statement");
                        let newConfigClient = new AppConfigurationClient(appSettings?.appConfigurationConnectionString)
                        setClient(newConfigClient);
                        configClient = newConfigClient;
                    }
                    else {
                        return;
                    }
                }
                const result = await configClient.getConfigurationSetting({ key: configKey.toString().trim() });
                if (result) {
                    console.debug('Config: ' + result.key, result.value);
                    setConfig(result.value);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [configKey, appSettings]);

    return { config };
};

export { useFeatureFlag, useConfiguration };