import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Col, Form, Label, Input, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as types from '../../../../constants/actionTypes';
import * as lists from '../../../../constants/listType';
import { validateForm } from '../../../../constants/validations';
import { NavigationFooter, scrollToTop } from '../Common/NavigationFooter';
import { DataGrid } from '@mui/x-data-grid';
import { SectionHeader } from '../../../common/SectionHeader';
import { SubSectionHeader } from '../../../common/SubSectionHeader';
import { InitPracticeLocation } from '../../../../models/ProfessionalApplication';
import { CustomAlert } from '../../../common/CustomAlert';
import { AddressForm } from '../Common/AddressForm';
import { LoadingAnimation } from '../../../common/LoadingAnimation';
import { useCookies } from 'react-cookie';
import { ClientLoggingModel } from '../../../../models/ClientLoggingModel';
import uuid from 'react-uuid';

export const PracticeLocationInfo = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [navigation, setNavigation] = useState(null);
    const [cookies, setCookie] = useCookies(['isFormDataSaved']);
    const [showAlert, setShowAlert] = useState("");
    let application = useSelector((state) => state.professionalApplication.applicationBeingUpdated);
    const isFetching = useSelector((state) => state.user.isFetching);
    const npiIsValid = useSelector((state) => state.user.npiIsValid);
    const [npiField, setNpiField] = useState(null);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);

    const userInfo = useSelector((state) => state.user.userInfo);
    if (!userInfo) {
        history.push("/")
    }

    const stateList = useSelector((state) => state.professionalApplication.stateList);
    const stateListSelections = stateList
        ? stateList.items.map((s) => { return { value: s.code, label: s.name } })
        : [];

    const practiceLocations = application?.practiceLocations ? application?.practiceLocations : [];
    const [primaryLocation, setPrimaryLocation] = useState(practiceLocations.length > 0 ? practiceLocations.find(l => l.type === "Primary") : { ...InitPracticeLocation, type: "Primary" });
    const [primaryLocationErrors, setPrimaryLocationErrors] = useState(InitPracticeLocation);

    const [billingLocation, setBillingLocation] = useState(practiceLocations.length > 0 ? practiceLocations.find(l => l.type === "Billing") : { ...InitPracticeLocation, type: "Billing" });
    const [billingLocationErrors, setBillingLocationErrors] = useState(InitPracticeLocation);

    const [mailingLocation, setMailingLocation] = useState(practiceLocations.length > 0 ? practiceLocations.find(l => l.type === "Mailing") : { ...InitPracticeLocation, type: "Mailing" });
    const [mailingLocationErrors, setMailingLocationErrors] = useState(InitPracticeLocation);

    const [billingSameAsPrimaryAddress, setBillingAddressSameAsPrimary] = useState(false);
    const [mailingSameAsPrimaryAddress, setMailingAddressSameAsPrimary] = useState(false);
    const [billingChecked, setBillingChecked] = useState(true);
    const [mailingChecked, setMailingChecked] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);

    const countyMap = useSelector((state) => state.professionalApplication.countyLookup);

    const getState = (code) => {
        if (code?.length > 0 && stateList)
            return stateList.items.filter(selectedState => selectedState.code === code)[0].name;
        else
            return "";
    }

    const apiErrors = useSelector((state) => state.professionalApplication.error);
    const [modalFormIsValid, setModalFormIsValid] = useState(false);
    const [viewAdditionalLocationModal, setViewAdditionalLocationModal] = useState(false);
    const [additionalLocationForGrid, setAdditionalLocationForGrid] = useState({ ...InitPracticeLocation, type: "Alternate" });
    const [additionalLocationErrors, setAdditionalLocationErrors] = useState(InitPracticeLocation);

    useEffect(() => {
        if (validateAddress(additionalLocationForGrid, true) && validateForm(additionalLocationErrors)) {
            setModalFormIsValid(true);
        }
        else {
            setModalFormIsValid(false);
        }
    }, [additionalLocationForGrid, additionalLocationErrors]);

    const [rows, setRows] = useState([]);
    const [editingAdditionalAddress, setEditingAdditionalAddress] = useState(false);
    const [rowIdBeingEdited, setRowIdBeingEdited] = useState("-1");

    const toggleViewAdditionalLocationModal = () => { setViewAdditionalLocationModal(!viewAdditionalLocationModal) };
    const toggleViewAdditionalLocationAndReinitializeModal = (editingExistingAddress) => {
        toggleViewAdditionalLocationModal();
        setModalFormIsValid(false);
        reinitializeModal(editingExistingAddress);
    }
    const cancelModal = () => {
        setEditingAdditionalAddress(false);
        toggleViewAdditionalLocationModal();

        reinitializeModal();
    }

    const reinitializeModal = (editingExistingAddress) => {
        if (!editingExistingAddress) {
            setRowIdBeingEdited("-1");
        }
        setAdditionalLocationForGrid({ ...InitPracticeLocation, type: "Alternate" });
        setAdditionalLocationErrors({ ...InitPracticeLocation });
    }

    /*ERRORS AND VALIDATION FUNCTIONS BEGIN*/

    const onNpiChange = (npiValue, field) => {
        setNpiField(field);
        var message = "Validating NPI against NPPES.";
        var methodName = "onNpiChange";
        var jsonResult = JSON.stringify(application);
        logInfoWrite(message, methodName, jsonResult);
        dispatch({ type: types.SIGNUP_GET_NPI_START, npi: npiValue });
    }

    const generateRequiredFields = () => {
        let fields = [];
        [primaryLocation, billingLocation, mailingLocation].forEach(addressObject => {
            fields = fields.concat([
                { key: addressObject.type + ' Address', value: addressObject.streetLine1 },
                { key: addressObject.type + ' City', value: addressObject.city },
                { key: addressObject.type + ' County', value: addressObject.county },
                { key: addressObject.type + ' Phone', value: addressObject.phone },
                { key: addressObject.type + ' State', value: addressObject.state },
                { key: addressObject.type + ' Zip Code', value: addressObject.zipCode },
                { key: addressObject.type + ' Zip Code', value: addressObject.zipPlus4 }
            ]);
            if (addressObject.type === "Primary") {
                fields = fields.concat([
                    { key: addressObject.type + ' Group NPI', value: addressObject.npi }
                ]);
            }
        })
        return fields;
    }

    let requiredFields = generateRequiredFields();


    const isValidData = () => {
        return validateForm(primaryLocationErrors) && validateForm(billingLocationErrors) && validateForm(mailingLocationErrors);
    }
    useEffect(() => {
        if (!isFetching && npiField) {
            if (npiField === "primary") {
                setPrimaryLocationErrors({ ...primaryLocationErrors, npi: npiIsValid });
            }
            else {
                setAdditionalLocationErrors({ ...additionalLocationErrors, npi: npiIsValid });
            }
            setNpiField(null);
        }
    }, [npiIsValid]);

    const validateAddress = (addressObject, validateNpiAndMedicaid = false) => {
        if (addressObject.city && addressObject.county && addressObject.phone
            && addressObject.state && addressObject.streetLine1 && addressObject.zipCode && addressObject.zipPlus4) {
            if (!validateNpiAndMedicaid) return true;
            else if (addressObject.npi) return true;
        }
        return false;
    }

    useEffect(() => {
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
    }, [primaryLocation, billingLocation, mailingLocation, primaryLocationErrors, billingLocationErrors, mailingLocationErrors,
        modalFormIsValid]);

    /*ERRORS AND VALIDATION FUNCTIONS END*/

    /*SAME AS PRIMARY ADDRESS CHECKBOX FUNCTIONS START */

    const checkBillingSameAsPrimaryAddress = () => {
        if (billingSameAsPrimaryAddress) {
            let billing = primaryLocation;
            if (billingLocation) {
                billing.practiceLocationId = billingLocation.practiceLocationId;
            }
            setBillingLocation({ ...billing });
            setBillingChecked(true);
        }
        else if ((!billingLocation.streetLine1 && !billingLocation.city && !billingLocation.county && !billingLocation.state && !billingLocation.zipCode && !billingLocation.zipPlus4 &&
            !billingLocation.phone && billingLocation.Fax) || !billingSameAsPrimaryAddress) {
            setBillingChecked(false);
        }
    }

    const checkMailingSameAsPrimaryAddress = () => {

        if (mailingSameAsPrimaryAddress) {
            let mailing = primaryLocation;
            if (mailingLocation) {
                mailing.practiceLocationId = mailingLocation.practiceLocationId;
            }
            setMailingLocation({ ...mailing });
            setMailingChecked(true);
        }
        else if ((!mailingLocation.streetLine1 && !mailingLocation.city && !mailingLocation.county && !mailingLocation.state && !mailingLocation.zipCode && !mailingLocation.zipPlus4 &&
            !mailingLocation.phone && mailingLocation.fax) || !mailingSameAsPrimaryAddress) {
            setMailingChecked(false);
        }
    }

    //checkbox toggling for save primary as billing address
    function toggle(value) {
        return !value;
    }

    /*SAME AS PRIMARY ADDRESS CHECKBOX FUNCTIONS END */

    /*useEffects START */

    useEffect(() => {
        if (userInfo) {
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.StateList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.CountyLookup });
        }
    }, []);

    useEffect(() => {
        if (stateList) {
            let additionalPracticeLocations = practiceLocations.filter(l => l.type === "Alternate");
            if (additionalPracticeLocations) {
                let rowsToDisplay = [];
                for (var i = 0; i < additionalPracticeLocations.length; i++) {
                    let loc = additionalPracticeLocations[i];
                    var state = getState(loc.state);
                    var cityStateZip = loc.city + ", " + state + "    " + loc.zipCode + "-" + loc.zipPlus4;
                    var addressGridDisplay = [loc.streetLine1, cityStateZip];
                    rowsToDisplay.push(
                        {
                            id: loc.practiceLocationId,
                            streetAddress1_Grid: loc.streetLine1,
                            streetAddress2_Grid: loc.streetLine2,
                            city_Grid: loc.city,
                            county_Grid: loc.county,
                            state_Grid: loc.state,
                            zip_Grid: loc.zipCode + "-" + loc.zipPlus4,
                            phone_Grid: loc.phone,
                            fax_Grid: loc.fax,
                            npi_Grid: loc.npi,
                            medicaidNumber_Grid: loc.medicaidNumber,
                            entireAddress_Grid: addressGridDisplay
                        }
                    );
                }

                setRows(rowsToDisplay);
            }
        }
    }, [stateList]);

    useEffect(() => {
        if (stateList && countyMap) {
            setPageLoading(false);
        }
    }, [stateList, countyMap])

    useEffect(() => {
        checkBillingSameAsPrimaryAddress();
    }, [billingSameAsPrimaryAddress]);

    useEffect(() => {
        checkMailingSameAsPrimaryAddress();
    }, [mailingSameAsPrimaryAddress]);

    /* useEffects END */

    /* ASSIGN TO APPLICATION AND SAVE FUNCTIONS START */

    const onSaveClick = () => {
        setShowAlert("");
        //Intermittent Save
        if (validateForm(primaryLocationErrors)
            && validateForm(billingLocationErrors)
            && validateForm(mailingLocationErrors)
            && validateForm(additionalLocationErrors)
        ) {
            let newApplication = application;
            var basicAddressList = assignAddresses();
            var additionalAddressList = assignAdditionalAddresses();
            var addressList = [...basicAddressList, ...additionalAddressList];
            newApplication.practiceLocations = addressList;

            if (application.providers) {
                var providerLocations = [];
                if (addressList.length > 0) {
                    var addreses = addressList.filter(l => l.type === 'Alternate' || l.type === "Primary");
                    var list = addreses.map(function (l) { return (l.practiceLocationId); });
                    list.sort();
                    providerLocations = providerLocations.concat(list);
                }

                newApplication.providers = application.providers.map(provider => {
                    if (provider.primaryLocation) {
                        if (!providerLocations || !providerLocations.some(l => l === provider.primaryLocation)) {
                            provider.primaryLocation = null;
                        }
                    }

                    let updatedAdditionalLocations = provider.additionalLocations?.filter(loc => {
                        if (providerLocations.some(l => l === loc)) {
                            return loc;
                        }
                    });
                    provider.additionalLocations = updatedAdditionalLocations;
                    return provider;
                });
            }

            var message = "Updating professional contract application.";
            var methodName = "onSaveClick";
            var jsonResult = JSON.stringify(newApplication);
            logInfoWrite(message, methodName, jsonResult);

            dispatch({ type: types.UPDATE_PROFESSIONAL_APPLICATION_START, application: newApplication, submit: false });
            dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: newApplication });
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
        }
        else {
            setNavigation(null);
        }
    }
    const onBackButtonClick = () => {
        history.push("/practiceInformation");
    }
    const onNextButtonClick = () => {
        setNavigation("/practiceContactInfo");
        onSaveClick();
    }

    const apiCallSuccess = useSelector((state) => state.professionalApplication.applicationApiCalledSuccess);
    useEffect(() => {
        if (apiCallSuccess && application) {
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            if (navigation)
                history.push(navigation);
            else {
                scrollToTop();
                setShowAlert("success");
            }
        } else if (apiErrors) {
            scrollToTop();
            setShowAlert("danger");
        }
        setNavigation(null);
    }, [apiCallSuccess, apiErrors]);

    const assignAddresses = () => {
        let addresses = [];

        let primaryAddress = {
            ...primaryLocation,
            practiceLocationId: primaryLocation.practiceLocationId ? primaryLocation.practiceLocationId : uuid(),
            streetLine1: primaryLocation.streetLine1.trim(),
            city: primaryLocation.city.trim(),
            type: "Primary"
        };

        let billingAddress = {
            ...billingLocation,
            practiceLocationId: billingLocation.practiceLocationId ? billingLocation.practiceLocationId : uuid(),
            streetLine1: billingLocation.streetLine1.trim(),
            city: billingLocation.city.trim(),
            type: "Billing",
            npi: "",
            medicaidNumber: ""
        };

        let mailingAddress = {
            ...mailingLocation,
            practiceLocationId: mailingLocation.practiceLocationId ? mailingLocation.practiceLocationId : uuid(),
            streetLine1: mailingLocation.streetLine1.trim(),
            city: mailingLocation.city.trim(),
            type: "Mailing",
            npi: "",
            medicaidNumber: ""
        };

        addresses.push(primaryAddress);
        addresses.push(billingAddress);
        addresses.push(mailingAddress);
        return addresses;
    }

    const assignAdditionalAddresses = () => {
        let additionalAddresses = rows.map(location => {
            return {
                practiceLocationId: location.id ? location.id : uuid(),
                streetLine1: location.streetAddress1_Grid.trim(),
                streetLine2: location.streetAddress2_Grid,
                city: location.city_Grid.trim(),
                county: location.county_Grid,
                zipCode: location.zip_Grid.substring(0, 5),
                zipPlus4: location.zip_Grid.substring(6, 10),
                state: location.state_Grid,
                type: "Alternate",
                fax: location.fax_Grid,
                phone: location.phone_Grid,
                npi: location.npi_Grid,
                medicaidNumber: location.medicaidNumber_Grid
            }
        });

        return additionalAddresses;
    }

    /* ASSIGN TO APPLICATION AND SAVE FUNCTIONS END */

    /* ADDITIONAL ADDRESSES GRID FUNCTIONS START */

    const columns = [
        {
            field: 'entireAddress_Grid',
            headerName: 'Address',
            flex: 1,
            minWidth: 280,
            editable: false,
            sortable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            renderCell: (params) => (
                <span className="renderAddressCell">
                    {params.value[0]}
                    <br />
                    {params.value[1]}
                </span>
            )
        },
        {
            field: 'streetAddress1_Grid',
            headerName: 'Street Address Line 1',
            flex: 1,
            minWidth: 300,
            editable: false,
            sortable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'streetAddress2_Grid',
            headerName: 'Street Address Line 2',
            minWidth: 240,
            flex: 1,
            editable: false,
            sortable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'city_Grid',
            headerName: 'City',
            minWidth: 150,
            flex: 1,
            editable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'county_Grid',
            headerName: 'County',
            minWidth: 150,
            flex: 1,
            editable: false,
            sortable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'state_Grid',
            headerName: 'State',
            minWidth: 150,
            flex: 1,
            editable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'zip_Grid',
            headerName: 'Zip',
            minWidth: 150,
            flex: 1,
            editable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'phone_Grid',
            headerName: 'Phone',
            minWidth: 150,
            flex: 1,
            editable: false,
            sortable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'fax_Grid',
            headerName: 'Fax',
            minWidth: 150,
            flex: 1,
            editable: false,
            sortable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
            hide: true
        },
        {
            field: 'npi_Grid',
            headerName: 'Location NPI',
            minWidth: 170,
            flex: 1,
            editable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center'
        },
        {
            field: 'medicaidNumber_Grid',
            headerName: 'Medicaid #',
            minWidth: 170,
            flex: 1,
            editable: false,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center'
        },
        {
            field: 'editLocation_Grid',
            headerName: 'Edit',
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: () => (
                <strong> <Button className="btn btn-primary" size="small" > Edit </Button> </strong>
            ),
            align: 'center'
        },
        {
            field: 'removeLocation_Grid',
            headerName: 'Remove',
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: () => (
                <strong> <Button className="btn btn-primary" size="small" > Remove </Button> </strong>
            ),
            align: 'center'
        },
    ];

    const selectOrRemoveLocation = (params, e) => {
        let message = "";
        let methodName = "selectOrRemoveLocation";
        let jsonResult = "";

        if (params.field === "removeLocation_Grid") {
            message = "Removing row " + params.row.id + " from additional locations grid.";
            jsonResult = JSON.stringify(application);
            logInfoWrite(message, methodName, jsonResult);
            let id = params.row.id;
            setRows(rows.filter(function (obj) {
                return obj.id !== id;
            }));
        }

        if (params.field === "editLocation_Grid") {
            let id = params.row.id;
            setRowIdBeingEdited(id);
            let additionalLocToEdit = {
                npi: params.row.npi_Grid,
                streetLine1: params.row.streetAddress1_Grid,
                streetLine2: params.row.streetAddress2_Grid,
                city: params.row.city_Grid,
                state: params.row.state_Grid,
                county: params.row.county_Grid,
                zipCode: params.row.zip_Grid.substring(0, 5),
                zipPlus4: params.row.zip_Grid.substring(6, 10),
                phone: params.row.phone_Grid,
                fax: params.row.fax_Grid,
                medicaidNumber: params.row.medicaidNumber_Grid
            };
            setEditingAdditionalAddress(true);
            toggleViewAdditionalLocationAndReinitializeModal(true);
            setAdditionalLocationForGrid(additionalLocToEdit);
            message = "Editing row for location " + additionalLocToEdit.streetLine1 + " in additional locations grid.";
            jsonResult = JSON.stringify(application);
            logInfoWrite(message, methodName, jsonResult);
        }
    }

    const addLocationToGrid = () => {
        const stateName = stateListSelections.find(state => state.value === additionalLocationForGrid.state)?.label;

        var cityStateZip = additionalLocationForGrid.city + ", "
            + stateName + "    " + additionalLocationForGrid.zipCode + "-" + additionalLocationForGrid.zipPlus4;
        var addressGridDisplay = [additionalLocationForGrid.streetLine1, cityStateZip];
        let updatedRows = [...rows];
        let maxId = updatedRows.length > 0 ?
            Math.max.apply(Math, updatedRows.map(function (o) { return o.id; }))
            : 0;
        let currentGridRow = {
            id: (editingAdditionalAddress ? rowIdBeingEdited : uuid()),
            streetAddress1_Grid: additionalLocationForGrid.streetLine1,
            streetAddress2_Grid: additionalLocationForGrid.streetLine2,
            city_Grid: additionalLocationForGrid.city,
            county_Grid: additionalLocationForGrid.county,
            state_Grid: additionalLocationForGrid.state,
            zip_Grid: additionalLocationForGrid.zipCode + "-" + additionalLocationForGrid.zipPlus4,
            phone_Grid: additionalLocationForGrid.phone,
            fax_Grid: additionalLocationForGrid.fax,
            npi_Grid: additionalLocationForGrid.npi,
            medicaidNumber_Grid: additionalLocationForGrid.medicaidNumber,
            entireAddress_Grid: addressGridDisplay
        }
        if (editingAdditionalAddress) {
            const targetIndex = updatedRows.findIndex(l => l.id === rowIdBeingEdited);
            updatedRows[targetIndex] = currentGridRow;
        } else {
            updatedRows.push(currentGridRow);
        }
        setRows(updatedRows);
        setEditingAdditionalAddress(false);
        toggleViewAdditionalLocationModal();

        var message = "Adding new row for location " + currentGridRow.locationName_Grid + " to additional locations grid.";
        var methodName = "addLocationToGrid";
        var jsonResult = JSON.stringify(application);
        logInfoWrite(message, methodName, jsonResult);
    }

    /* ADDITIONAL ADDRESSES GRID FUNCTIONS END */

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "PracticeLocationInfo",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    return (<div className="practiceLocationInfo">
        {showAlert.length > 0 && <CustomAlert type={showAlert} />}
        <SectionHeader>Practice Location Information</SectionHeader>
        {
            pageLoading ?
                <LoadingAnimation /> :
                <div>
                    <Form>

                        <SubSectionHeader>Primary Address Information</SubSectionHeader>
                        <AddressForm address={primaryLocation}
                            key={"primary"}
                            childKey={"primary"}
                            errors={primaryLocationErrors}
                            setAddress={setPrimaryLocation}
                            setErrors={setPrimaryLocationErrors}
                            showNpiAndMedicare={true}
                            stateList={stateListSelections}
                            countyList={countyMap}
                            validateNpiChange={(npi) => { onNpiChange(npi, "primary") }} />
                        <br />
                        <SubSectionHeader>Billing Address Information</SubSectionHeader>
                        <Row>
                            <Col md={12}>
                                <Input className="checkbox" type="checkbox" name="billingSameAsPrimaryAddress" checked={billingChecked}
                                    id="billingSameAsPrimaryAddress" onChange={() => setBillingAddressSameAsPrimary(toggle)} />
                                <span><Label className="checkbox-label"> Same as Primary Address</Label></span>
                            </Col>
                        </Row>
                        <br />
                        <AddressForm address={billingLocation}
                            key={"billing"}
                            childKey={"billing"}
                            errors={billingLocationErrors}
                            setAddress={setBillingLocation}
                            setErrors={setBillingLocationErrors}
                            showNpiAndMedicare={false}
                            stateList={stateListSelections}
                            countyList={countyMap} />
                        <br />
                        <SubSectionHeader>Mailing Address Information</SubSectionHeader>
                        <Row>
                            <Col md={4}>
                                <Input className="checkbox" type="checkbox" name="mailingSameAsPrimaryAddress" checked={mailingChecked}
                                    id="mailingSameAsPrimaryAddress" onChange={() => setMailingAddressSameAsPrimary(toggle)} />
                                <span><Label className="checkbox-label"> Same as Primary Address</Label></span>
                            </Col>
                        </Row>
                        <br />
                        <AddressForm address={mailingLocation}
                            key={"mailing"}
                            childKey={"mailing"}
                            errors={mailingLocationErrors}
                            setAddress={setMailingLocation}
                            setErrors={setMailingLocationErrors}
                            showNpiAndMedicare={false}
                            stateList={stateListSelections}
                            countyList={countyMap} />
                        <br />
                        <br />
                        <br />
                        <div>
                            <div>
                                <span className="addLocationBtn" onClick={toggleViewAdditionalLocationAndReinitializeModal}>+</span>
                                <span className="addLocationLabelSpan"> Add Additional Location(s)</span>
                            </div>
                            <br />
                            <br />
                            <br />
                            <Modal isOpen={viewAdditionalLocationModal} toggle={toggleViewAdditionalLocationModal} className="modal-dialog-centered modal-lg" trapFocus={true}>
                                <ModalBody className="additional-location-modal">
                                    <SubSectionHeader>Additional Address Information</SubSectionHeader>
                                    <AddressForm address={additionalLocationForGrid}
                                        key={rowIdBeingEdited}
                                        childKey={rowIdBeingEdited}
                                        errors={additionalLocationErrors}
                                        setAddress={setAdditionalLocationForGrid}
                                        setErrors={setAdditionalLocationErrors}
                                        showNpiAndMedicare={true}
                                        stateList={stateListSelections}
                                        countyList={countyMap}
                                        validateNpiChange={(npi) => { onNpiChange(npi, "additional") }} />
                                </ModalBody>
                                <ModalFooter className="additional-location-modal-footer">
                                    <Button className="cancel" onClick={cancelModal}>Cancel</Button>
                                    <Button className="add" onClick={addLocationToGrid} disabled={!modalFormIsValid} >Add/Update Location</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                rowHeight={70}
                                columns={columns}
                                pageSize={5}
                                columnBuffer={2}
                                onCellClick={(params, event) => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    selectOrRemoveLocation(params, event);
                                }}
                            />
                        </div>
                    </Form>
                    <NavigationFooter onNextPageClick={onNextButtonClick}
                        onSaveClick={onSaveClick}
                        onBackButtonClick={onBackButtonClick}
                        isValidData={isValidData}
                        requiredFields={requiredFields}
                    />
                </div >
        }
    </div >
    );
}