export const PortalApplicationStatus = {
    AdditionalInformationReceived: "Additional Information Received",
    AdditionalInformationRequestCheckYourEmailForDetails: "Additional Information Request.  Check your email for details.",
    AdditionalInformationRequestCheckYourEmail: "Additional Information Request.  Check your email.",
    AppliedForContract: "Applied for Contract",
    ContractApproved: "Contract Approved",
    ContractBeingCreated: "Contract Being Created",
    ContractReceived: "Contract Received",
    ContractSignatureAndCredentialingInformationNeedsToBeReturnedToUs: "Contract Signature and Credentialing information needs to be returned to us.",
    CredentialingComplete: "Credentialing Complete",
    Denied: "Denied",
    InCredentialing: "In Credentialing",
    InReview: "In Review",
    IncompleteApplicationPleaseCheckYourEmailForDtails: "Incomplete Application-Please check your email for details.",
    PendingSetUp: "Pending Set-up",
    PSSNeedsMoreInfoFromPRContracting: "PSS needs more info from PR/Contracting",
    RequestClosedCheckYourEmail: "Request Closed-Check your email.",
    Submitted: "Applied for Contract",
    Drafted: "Drafted",
    Created: "Created",
};

Object.freeze(PortalApplicationStatus);