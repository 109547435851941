import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import * as types from '../../../../constants/actionTypes';
import * as lists from '../../../../constants/listType'
import * as limitations from '../../../../constants/PracticeLimitationsTypes'
export const PracticeLimitations = (props) => {
    let { updatePracticeLimitations, currentPracticeLimitations } = props;
    const dispatch = useDispatch();

    const [error, setError] = useState("");
    const [limitationsPatientsOlder, setLimitationsPatientsOlder] = useState(false);
    const [limitationsPatientsOlderOption, setLimitationsPatientsOlderOption] = useState('Select Age');
    const [limitationsPatientsYounger, setLimitationsPatientsYounger] = useState(false);
    const [limitationsPatientsYoungerOption, setLimitationsPatientsYoungerOption] = useState('Select Age');
    const [limitationsYoungerChildren, setLimitationsYoungerChildren] = useState(false);
    const [limitationsYoungerChildrenOption, setLimitationsYoungerChildrenOption] = useState('Select Age');
    const [limitationsNoChildrenUnderTheAge, setLimitationsNoChildrenUnderTheAge] = useState(false);
    const [limitationsNoChildrenUnderTheAgeOption, setLimitationsNoChildrenUnderTheAgeOption] = useState('Select Age');

    const [limitationsNA, setLimitationsNA] = useState(false);
    const [limitationsAcceptsPatientsThrough19, setLimitationsAcceptsPatientsThrough19] = useState(false);
    const [limitationsAcceptsPatientsThrough20, setLimitationsAcceptsPatientsThrough20] = useState(false);
    const [limitationsAcceptsFemalePatientsOnly, setLimitationsAcceptsFemalePatientsOnly] = useState(false);
    const [limitationsAcceptsMalePatientsOnly, setLimitationsAcceptsMalePatientsOnly] = useState(false);
    const [limitationsCulturalCompetencyTrainingCompleted, setLimitationsCulturalCompetencyTrainingCompleted] = useState(false);
    const [limitationsNotAcceptingNewPatients, setLimitationsNotAcceptingNewPatients] = useState(false);
    const [limitationsOffersTelemedicineServices, setLimitationsOffersTelemedicineServices] = useState(false);
    const [limitationsOffersTelemonitoring, setLimitationsOffersTelemonitoring] = useState(false);

    useEffect(() => {
        dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.PracticeLimitationList });
    }, []);
    const practiceLimitationsOptions = useSelector((state) => state.professionalApplication.practiceLimitationList);

    const patientsOlder = practiceLimitationsOptions ? practiceLimitationsOptions.items.filter(s => s.type === limitations.PracticeLimitationsTypes.PatientsOlder) : null;
    const patientsOlderOptions = patientsOlder
        ? patientsOlder.map((t) => { return { value: t.name, label: t.value } }) : [];

    const patientsYounger = practiceLimitationsOptions ? practiceLimitationsOptions.items.filter(s => s.type === limitations.PracticeLimitationsTypes.PatientsYounger) : null;
    const patientsYoungerOptions = patientsYounger
        ? patientsYounger.map((t) => { return { value: t.name, label: t.value } }) : [];

    const youngerChildren = practiceLimitationsOptions ? practiceLimitationsOptions.items.filter(s => s.type === limitations.PracticeLimitationsTypes.YoungerChildren) : null;
    const youngerChildrenOptions = youngerChildren
        ? youngerChildren.map((t) => { return { value: t.name, label: t.value } }) : [];

    const noChildrenUnderTheAge = practiceLimitationsOptions ? practiceLimitationsOptions.items.filter(s => s.type === limitations.PracticeLimitationsTypes.NoChildrenUnderTheAge) : null;
    const noChildrenUnderTheAgeOptions = noChildrenUnderTheAge
        ? noChildrenUnderTheAge.map((t) => { return { value: t.name, label: t.value } }) : [];

    useEffect(() => {
        if (currentPracticeLimitations) {
            let id = currentPracticeLimitations.type;
            let value = currentPracticeLimitations.name;
            checkRadioBox(id);
            if (id === limitations.PracticeLimitationsTypes.PatientsOlder || id === limitations.PracticeLimitationsTypes.PatientsYounger || id === limitations.PracticeLimitationsTypes.YoungerChildren || id === limitations.PracticeLimitationsTypes.NoChildrenUnderTheAge) {
                setDropDownValue(id, value);
            }
        }
    }, [currentPracticeLimitations]);

    const onAgeChange = (e) => {
        let limitations = practiceLimitationsOptions.items.find((t) => {
            return t.name.toLowerCase() === e.target.value.toLowerCase()
        });
        updatePracticeLimitations(limitations);
        setError("");
    }

    const handleRadioChange = (e) => {
        let id = e.target.id;
        setRadiosToFalse();
        checkRadioBox(id);
        updatePracticeLimitations(null);
        setLimitationsPatientsOlderOption('Select Age');
        setLimitationsPatientsYoungerOption('Select Age');
        setLimitationsYoungerChildrenOption('Select Age');
        setLimitationsNoChildrenUnderTheAgeOption('Select Age');
        if (id !== limitations.PracticeLimitationsTypes.PatientsOlder && id !== limitations.PracticeLimitationsTypes.PatientsYounger && id !== limitations.PracticeLimitationsTypes.YoungerChildren && id !== limitations.PracticeLimitationsTypes.NoChildrenUnderTheAge) {
            setError("");
            let limitations = practiceLimitationsOptions.items.find((t) => {
                return t.type.toLowerCase() === e.target.id.toLowerCase()
            });
            updatePracticeLimitations(limitations);
        } else {
            setError("Select Age from dropdown");
        }
    }

    const setDropDownValue = (id, value) => {
        switch (id) {
            case limitations.PracticeLimitationsTypes.AcceptsPatientsOlder:
            case limitations.PracticeLimitationsTypes.PatientsOlder:
                setLimitationsPatientsOlderOption(value);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsPatientsYounger:
            case limitations.PracticeLimitationsTypes.PatientsYounger:
                setLimitationsPatientsYoungerOption(value);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsYoungerChildren:
            case limitations.PracticeLimitationsTypes.YoungerChildren:
                setLimitationsYoungerChildrenOption(value);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsNoChildrenUnderTheAge:
            case limitations.PracticeLimitationsTypes.NoChildrenUnderTheAge:
                setLimitationsNoChildrenUnderTheAgeOption(value);
                break;
        }
    }

    const checkRadioBox = (id) => {
        switch (id) {
            case limitations.PracticeLimitationsTypes.NA:
                setLimitationsNA(true);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsPatientsThrough19:
                setLimitationsAcceptsPatientsThrough19(true);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsPatientsThrough20:
                setLimitationsAcceptsPatientsThrough20(true);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsFemalePatientsOnly:
                setLimitationsAcceptsFemalePatientsOnly(true);
                break;
            case limitations.PracticeLimitationsTypes.AcceptsMalePatientsOnly:
                setLimitationsAcceptsMalePatientsOnly(true);
                break;
            case limitations.PracticeLimitationsTypes.CulturalCompetencyTrainingCompleted:
                setLimitationsCulturalCompetencyTrainingCompleted(true);
                break;
            case limitations.PracticeLimitationsTypes.NotAcceptingNewPatients:
                setLimitationsNotAcceptingNewPatients(true);
                break;
            case limitations.PracticeLimitationsTypes.OffersTelemedicineServices:
                setLimitationsOffersTelemedicineServices(true);
                break;
            case limitations.PracticeLimitationsTypes.OffersTelemonitoring:
                setLimitationsOffersTelemonitoring(true);
                break;
            case limitations.PracticeLimitationsTypes.PatientsYounger:
                setLimitationsPatientsYounger(true);
                break;
            case limitations.PracticeLimitationsTypes.PatientsOlder:
                setLimitationsPatientsOlder(true);
                break;
            case limitations.PracticeLimitationsTypes.YoungerChildren:
                setLimitationsYoungerChildren(true);
                break;
            case limitations.PracticeLimitationsTypes.NoChildrenUnderTheAge:
                setLimitationsNoChildrenUnderTheAge(true);
                break;
        }
    }

    const setRadiosToFalse = () => {
        setLimitationsNA(false);
        setLimitationsAcceptsPatientsThrough19(false);
        setLimitationsAcceptsPatientsThrough20(false);
        setLimitationsAcceptsFemalePatientsOnly(false);
        setLimitationsAcceptsMalePatientsOnly(false);
        setLimitationsCulturalCompetencyTrainingCompleted(false);
        setLimitationsNotAcceptingNewPatients(false);
        setLimitationsOffersTelemedicineServices(false);
        setLimitationsOffersTelemonitoring(false);
        setLimitationsPatientsYounger(false);
        setLimitationsPatientsOlder(false);
        setLimitationsYoungerChildren(false);
        setLimitationsNoChildrenUnderTheAge(false);
    }

    return <div className="practice-limitations">
        {error && <span className='error'>{error}</span>}
        <div><Input type="radio" id="N/A" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsNA} /><Label>N/A</Label></div>
        <div>
            <Input type="radio" id="PatientsOlder" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsPatientsOlder}/>
            <Label>Accepts Patients  
                <Input type="select" name="acceptsPatientsOlder" id="acceptsPatientsOlder" value={limitationsPatientsOlderOption} onChange={onAgeChange} disabled={!limitationsPatientsOlder}>
                        <option value="" >Select Age</option>
                        {patientsOlderOptions.map(o => <option value={o.value} key={"option-" + o.value} > {o.label}</option>)}
                 </Input>                
                  and Older
            </Label>
        </div>
        <div>
            <Input type="radio" id="PatientsYounger" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsPatientsYounger}/>
            <Label>Accepts Patients
                 <Input type="select" name="acceptsPatientsYounger" id="AcceptsPatientsYounger" value={limitationsPatientsYoungerOption} onChange={onAgeChange} disabled={!limitationsPatientsYounger}>
                    <option value="" >Select Age</option>
                    {patientsYoungerOptions.map(o => <option value={o.value} key={"option-" + o.value} > {o.label}</option>)}
                </Input>
                and Younger
            </Label>
        </div>
        <div>
            <Input type="radio" id="AcceptsPatientsThrough19" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsAcceptsPatientsThrough19} />
            <Label>Accepts Patients 4 Through 19 </Label>
        </div>
        <div>
            <Input type="radio" id="AcceptsPatientsThrough20" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsAcceptsPatientsThrough20} />
            <Label>Accepts Patients 16 Through 20 </Label>
        </div>
        <div>
            <Input type="radio" id="YoungerChildren" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsYoungerChildren} />
            <Label>Accepts Children
                <Input type="select" name="acceptsYoungerChildren" id="AcceptsYoungerChildren" value={limitationsYoungerChildrenOption} onChange={onAgeChange} disabled={!limitationsYoungerChildren}>
                    <option value="" >Select Age</option>
                    {youngerChildrenOptions.map(o => <option value={o.value} key={"option-" + o.value} > {o.label}</option>)}
                </Input>
                and Younger
            </Label>
        </div>
        <div><Input type="radio" id="NoChildrenUnderTheAge" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsNoChildrenUnderTheAge} />
            <Label>No Children Under the Age of
                  <Input type="select" name="acceptsNoChildrenUnderTheAge" id="AcceptsNoChildrenUnderTheAge" value={limitationsNoChildrenUnderTheAgeOption} onChange={onAgeChange} disabled={!limitationsNoChildrenUnderTheAge}>
                    <option value="" >Select Age</option>
                    {noChildrenUnderTheAgeOptions.map(o => <option value={o.value} key={"option-" + o.value} > {o.label}</option>)}
                </Input>
            </Label>
        </div>
        <div><Input type="radio" id="AcceptsFemalePatientsOnly" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsAcceptsFemalePatientsOnly} /><Label>Accepts Female Patients Only</Label></div>
        <div><Input type="radio" id="AcceptsMalePatientsOnly" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsAcceptsMalePatientsOnly} /><Label>Accepts Male Patients Only</Label></div>
        <div><Input type="radio" id="CulturalCompetencyTrainingCompleted" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsCulturalCompetencyTrainingCompleted} /><Label>Cultural Competency Training Completed</Label></div>
        <div><Input type="radio" id="NotAcceptingNewPatients" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsNotAcceptingNewPatients} /><Label>Not Accepting New Patients</Label></div>
        <div><Input type="radio" id="OffersTelemedicineServices" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsOffersTelemedicineServices} /><Label>Offers Telemedicine Services</Label></div>
        <div><Input type="radio" id="OffersTelemonitoring" name="practiceLimitations" onChange={(e) => handleRadioChange(e)} checked={limitationsOffersTelemonitoring} /><Label>Offers Telemonitoring</Label></div>
    </div>
}

PracticeLimitations.propTypes = {
    updatePracticeLimitations: PropTypes.func,
    currentPracticeLimitations: PropTypes.object,
}