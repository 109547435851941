import React from 'react';
import { useLocation } from "react-router-dom";
import bottomBorder from '../../images/Branding/footer-line.jpg';

export const Footer = () => {
	const location = useLocation();

	// Footer should only be displayed on the home/welcome page
	var showFooter = (location.pathname === "/");
    
	return (showFooter &&
		<div className="footer">
			<div className="border-line text-center">
				<img src={bottomBorder} className="bottom-border" alt=""/>
		</div>
		<div className="footer-links row">
			<div className="col-xs-12 col-sm-4 text-left">
				<ul className="footer-social">
					<li><a href="http://www.facebook.com/bswhealth" target="_blank" className="facebook-icon" title="Facebook" rel="noreferrer"></a></li>
					<li><a href="http://twitter.com/bswhealth" target="_blank" className="twitter-icon" title="Twitter" rel="noreferrer"></a></li>
					<li><a href="https://www.linkedin.com/company/bswhealth" target="_blank" className="linkedin-icon" title="LinkedIn" rel="noreferrer"></a></li>
				</ul>
				<br/>
				<ul className="footer-local-links">
					<li><a href="https://www.bswhealthplan.com/Pages/Contact.aspx" target="_blank" rel="noreferrer">Contact Us</a></li>
					<li><a href="https://www.bswhealthplan.com/Pages/About.aspx" target="_blank" rel="noreferrer">About Us</a></li>
					<li><a href="http://jobs.baylorscottandwhite.com/" target="_blank" rel="noreferrer">Careers</a></li>
					<li><a href="https://news.bswhealth.com/" target="_blank" rel="noreferrer">Newsroom</a></li>
				</ul>
			</div>
			<div className="col-xs-12 col-sm-4 text-center">
				<ul className="footer-local-links">
					<li><a href="https://www.bswhealthplan.com/Pages/PlanDocs.aspx" target="_blank" rel="noreferrer">Plan Documents</a></li>
					<li><a href="https://www.bswhealthplan.com/Pages/Maps.aspx" target="_blank" rel="noreferrer">Service Areas</a></li>
					<li><a href="https://www.bswhealthplan.com/Pages/App.aspx" target="_blank" rel="noreferrer">MyBSWHealth App</a></li>
				</ul>
				<div className="app-icons">
					<div className="app-store-icon">
						<a href="https://itunes.apple.com/us/app/mybswhealth/id1000856979?mt=8" target="_blank" title="Available on the App Store" className="apple-app-btn" rel="noreferrer"></a>
					</div>
					<div className="app-store-icon">
						<a href="https://play.google.com/store/apps/details?id=com.baylorscottandwhite.healthsource&amp;hl=en" target="_blank" title="Get it on Google Play" className="gp-app-btn" rel="noreferrer"></a>
					</div>
				</div>
			</div>
			<div className="col-xs-12 col-sm-4 text-right">
				<div className="footer-copyright"> © <script>document.write(new Date().getFullYear())</script>2022 Baylor Scott &amp; White Health Plan </div>
				<ul className="footer-legal-links">
					<li><a href="https://www.bswhealthplan.com/Pages/Legal.aspx" target="_blank" rel="noreferrer">Privacy, Compliance and Legal Notices</a></li>
					<li><a href="https://www.bswhealthplan.com/Pages/Legal.aspx#terms" target="_blank" rel="noreferrer">Terms of Use</a></li>
					<li><a href="https://www.bswhealthplan.com/Pages/Legal.aspx#nondescrimination" target="_blank" rel="noreferrer">Nondiscrimination Notice</a></li>
				</ul>
				<ul className="footer-legal-links">
					<li><a href="https://www.bswhealthplan.com/SiteCollectionDocuments/Compliance-Legal/GN_Interoperability-General-BSWHP.pdf" target="_blank" rel="noreferrer">Interoperability Notice</a></li>
					<li><a href="https://datastream-developer.inovalonone.com/" target="_blank" rel="noreferrer">Interoperability API for Developers</a></li>
				</ul>
			</div>
		</div>
		</div>
		);
}