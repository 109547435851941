import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as documentApis from '../apis/documentApis';

function* documentSagas() {
    yield takeLatest(types.FILE_UPLOAD_START, uploadDocument);
    yield takeLatest(types.FILE_DOWNLOAD_START, getDocument);
}

function* uploadDocument(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const result = yield call(documentApis.uploadDocument, params.file, userInfo);
        yield put({ type: types.FILE_UPLOAD_SUCCESS, result: JSON.parse(result), name: params.name });
    } catch (e) {
        yield put({ type: types.FILE_UPLOAD_FAILURE, error: e, name: params.name });
    }
}

function* getDocument(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const result = yield call(documentApis.getDocument, params.key, params.fileName, userInfo);
        yield put({ type: types.FILE_DOWNLOAD_SUCCESS, result: result });
    } catch (e) {
        yield put({ type: types.FILE_DOWNLOAD_FAILURE, error: e });
    }
}


export default documentSagas;