import React, { useState } from 'react';
import { Col, FormGroup, Label, Input, Row } from 'reactstrap';
import { validateForNotNullOrWhitespace, validateCityName, validateZipWithPlus4, validatePhone, validateFax, validateMedicaid, validateNpi, validateNA } from '../../../../constants/validations';
import { FormField } from '../../../common/FormField';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

export const AddressForm = (props) => {
    let { address, errors, setAddress, setErrors, showNpiAndMedicare, stateList, countyList, validateNpiChange, childKey } = props;

    const stateName = stateList.find(state => state.value === address?.state)?.label;
    const countyOptions = countyList && address?.state ? countyList[stateName] : [];
    const [countyRequiredError, setCountyRequiredError] = useState(null);

    const onFieldChange = (event, field) => {
        let newError = '';
        let newValue = event.target.value;

        if (field === "zip") {
            newError = validateZipWithPlus4(newValue);

            setErrors({ ...errors, [field]: newError });

            let zipCode = '';
            let zipPlus4 = '';

            if (newValue.length < 5) {
                zipCode = newValue;
            }
            else {
                zipCode = newValue.substring(0, 5);
                zipPlus4 = newValue.substring(6);
            }

            setAddress({ ...address, zipCode, zipPlus4 });
            return;
        }
        switch (field) {
            case "county":
                setCountyRequiredError(validateForNotNullOrWhitespace(newValue));
                break;
            case "streetLine1":
                newError = validateNA(newValue);
                break;
            case "city":
                newError = validateCityName(newValue);
                break;
            case "phone":
                newError = validatePhone(newValue);
                break;
            case "fax":
                newError = validateFax(newValue);
                break;
            case "npi":
                newError = validateNpi(newValue);
                if (newError === '' && newValue) {
                    validateNpiChange(newValue);
                }
                break;
            case "medicaidNumber":
                newError = validateMedicaid(newValue);
                break;
            default:
                break;

        }
        setErrors({ ...errors, [field]: newError });
        if (field === "state") {
            let field = address;
            field.state = newValue;
            field.county = '';
            setAddress(field);
        }
        else {
            setAddress({ ...address, [field]: newValue });
        }
    }

    return <div className="address-form">       
        <Row>
            <Col md={12}>
                <FormField type="text"
                    key={"LocationStreetAddr1-"+childKey}
                    required
                    className="required-field"
                    name="LocationStreetAddr1"
                    value={address.streetLine1}
                    label={address.type === "Primary" ? "Street Address" : "Address"}
                    error={errors.streetLine1}
                    autoComplete="off"
                    onChange={(e) => { onFieldChange(e, "streetLine1") }}
                    maxLength={55}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormField type="text"
                    key={"LocationCity-" + childKey}
                    required
                    className="required-field"
                    name="LocationCity"
                    value={address.city}
                    label="City"
                    error={errors.city}
                    autoComplete="off"
                    onChange={(e) => { onFieldChange(e, "city") }}
                />
            </Col>
            <Col md={6}>
                <FormField type="select"
                    key={"LocationStateName-" + childKey}
                    required
                    className="required-field"
                    name="LocationStateName"
                    label="State"
                    placeholder="Select Value"
                    error={errors.state}
                    options={stateList}
                    onChange={(e) => { onFieldChange(e, "state") }}
                    value={address.state}>
                </FormField>
            </Col>
        </Row>
        <Row>
            <Col md={3}>
                <FormGroup>
                    <Label className="required-field"> County</Label>
                    <Input type="select" name="LocationCountyName"
                        key={"LocationCountyName-" + childKey}
                        value={address.county} placeholder="Select Value" onChange={(e) => { onFieldChange(e, "county") }} >
                        <option value={''} key={"option-null"}> Select </option>
                        {countyOptions ? countyOptions.map(o => <option value={o} key={"option-" + o} > {o}</option>) : ""}
                    </Input>
                    {errors.county &&
                        <span className='error'>{errors.county}</span>}
                    {countyRequiredError &&
                        <span className='error'>{countyRequiredError}</span>}
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <Label className="required-field"> Zip (9 digits)</Label>
                    <InputMask mask="99999-9999" maskChar={null} name="mailingZip" id="LocationZip" key={"LocationZip-" + childKey} value={address.zipCode + "-" + address.zipPlus4}
                        onChange={(e) => { onFieldChange(e, "zip") }} autoComplete="off" className="zipInputMask, form-control" placeholder="XXXXX-XXXX" />
                    {errors.zip &&
                        <span className='error'>{errors.zip}</span>}
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <Label className="required-field"> Phone (10 digits)</Label>
                    <InputMask mask="999-999-9999" maskChar={null} name="LocationPhone" key={"LocationPhone-" + childKey}
                        value={address.phone} errors="errors.phone" onChange={(e) => { onFieldChange(e, "phone") }}
                        autoComplete="off" className="phoneInputMask, form-control" placeholder="XXX-XXX-XXXX" />
                    {errors.phone &&
                        <span className='error'>{errors.phone}</span>}
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <Label className="fax-label"> Fax (10 digits)</Label>
                    <InputMask mask="999-999-9999" maskChar={null} name="LocationFax"
                        value={address.fax} errors="errors.fax" key={"LocationFax-" + childKey}
                        onChange={(e) => { onFieldChange(e, "fax") }} autoComplete="off" className="phoneInputMask, form-control" placeholder="XXX-XXX-XXXX" />
                    {errors.fax &&
                        <span className='error'>{errors.fax}</span>}
                </FormGroup>
            </Col>
        </Row>
        {showNpiAndMedicare && < Row >
            <Col md={6}>
                <FormField type="text"
                    key={"LocationNpi-" + childKey}
                    required
                    className="required-field"
                    name="LocationNpi"
                    value={address.npi}
                    label={address.type === "Primary" ? "Group NPI" : "NPI"}
                    error={errors.npi}
                    autoComplete="off"
                    onChange={(e) => { onFieldChange(e, "npi") }}
                />
            </Col>
            <Col md={6}>
                <FormField type="text"
                    key={"LocationMedicaidNumber-" + childKey}
                    name="LocationMedicaidNumber"
                    value={address.medicaidNumber}
                    label="Medicaid Number"
                    error={errors.medicaidNumber}
                    autoComplete="off"
                    maxLength={9}
                    onChange={(e) => { onFieldChange(e, "medicaidNumber") }}
                />
            </Col>
        </Row>}
    </div>
}

AddressForm.propTypes = {
    address: PropTypes.object,
    errors: PropTypes.object,
    setAddress: PropTypes.func,
    setErrors: PropTypes.func,
    showNpiAndMedicare: PropTypes.bool,
    stateList: PropTypes.array,
    countyList: PropTypes.array,
    validateNpiChange: PropTypes.func,
    childKey: PropTypes.string
}