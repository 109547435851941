
export const Contact = {
    contactType: null,
    name: null,
    email: null,
    phone: null,
    fax: null,
    websiteAddress: null
}
export const HoursOfOperation = {
    Day: null,
    StartTime: null,
    EndTime: null
}

export const baseHoursOfOperation = [{ day: "Monday", startTime: "", endTime: "" }];

export const InitPracticeLocation = {
    practiceLocationId: "",
    city: "",
    county: "",
    fax: "",
    medicaidNumber: "",
    name: "",
    npi: "",
    phone: "",
    state: "",
    streetLine1: "",
    streetLine2: "",
    type: "",
    zipCode: "",
    zipPlus4: ""
}


export const ProviderFacilityAffiliation = {
    name: null,
    active: null
}

export const NonCaqhAttachments = {
    DeaRegistration: null,
    CredentialingApplication: null,
    CurriculumVitae: null,
    IrsW9: null
}
export const Practice = {
    LegalName: null,
    DoingBusinessAs: null,
    GroupNpi: null,
    GroupSpecialty: null, //name
    TaxId: null,
    TPI: null,
    MedicareNumber: null,
    NumberOfProviders: null,
    HoursOfOperation: Array(HoursOfOperation),
    PracticeLimitations: null,
}

export const Address = {
    name: null,
    streetLine1: null,
    streetLine2: null,
    city: null,
    county: null,
    zipCode: null,
    zipPlus4: null,
    state: null, 
    type: null,
    fax: null,
    phone: null,
    npi: null,
    medicaidNumber: null
}

export const SupervisingPhysician = {
    midlevelProtocolsAvailableOnRequest: null,
    name: null,
    npi: null,
    specialty: null,
    isPhysicianContracted: null,
}

export const MalpracticeInsuranceCarrier = {
    name: null,
    code: null,
    Active: null,
}

export const MalpracticeInsuranceType = {
    name: null,
    code: null,
    Active: null,
}

export const malpracticeInsurance = {
    carrier: null,
    type: MalpracticeInsuranceType,
    malpracticeEffectiveDate: null,
    malpracticeExpirationDate: null,
    amounts: null,
    malpracticePolicy: null
}

export const Provider = {
    providerGUID: null,
    firstName: null,
    middleInitial: null,
    lastName: null,
    suffix: null,
    npi: null,
    ssn: null,
    medicaidId: null,
    medicareId: null,
    licenseNumber: null,
    deaNumber: null,
    titleOrDegree: null,
    languages: Array(),
    birthDate: null,
    gender:null,
    hasAdmittingPrivileges: null,
    hospitalAffiliations: Array(ProviderFacilityAffiliation),
    caqhProviderId: null,
    nonCaqhAttachments: NonCaqhAttachments,
    primaryLocation: null,
    additionalLocations: Array(),
    primarySupervisingPhysician: null,
    malpracticeInsurance: malpracticeInsurance,
    isPcp: null,
    primaryTaxonomy: null,
    secondaryTaxonomy: null,
    providerPrimarySpecialty: null,
    providerSecondarySpecialty: null,
    primarySpecialtyBoardCertificate: null,
    secondarySpecialtyBoardCertificate: null,
    isHospitalBased: null,
    boardCertification: null,
    currentRadiologyCertificate: null,
    currentStateLicense: null
}
export const ProfessionalApplication = {
    id: null,
    username: null,
    providerType: null,
    applicationDate: null,
    createdDate: null,
    updatedDate: null,
    legalName: null,
    applicationStatus: null,
    PortalStatus: null,
    providerDefinition: null,
    percentComplete: 0,
    doingBusinessAsName: null,
    groupNpi: null,
    groupSpecialty: null,
    HoursOfOperation: baseHoursOfOperation,
    medicareNumber: null,
    practiceLimitations: null,
    providers: [],
    practiceLocations: [],
    practiceContacts: [],
    providerCount: null
}