import * as types from '../constants/actionTypes';
export const documentInitialState = {
    isFetching: true,
    error: "",
    success: false,
    attachment: null,
    file: null,
    uploadOccuredAt: null
};

export default function documentReducer(state = documentInitialState, action) {
    switch (action.type) {
        case types.SET_DEFAULT_DOCUMENT_STATE:
            return documentInitialState;
        case types.LOGOUT:
            return documentInitialState;

        case types.FILE_UPLOAD_START:
            return { ...state, isFetching: true, uploadOccuredAt: null };
        case types.FILE_UPLOAD_SUCCESS:
            return { ...state, attachment: action.result, isFetching: false, error: "", uploadOccuredAt: action.name };
        case types.FILE_UPLOAD_FAILURE:
            return { ...state, isFetching: false, error: action.error, uploadOccuredAt: action.name };

        case types.FILE_DOWNLOAD_START:
            return { ...state, isFetching: true, file: null };
        case types.FILE_DOWNLOAD_SUCCESS:
            return { ...state, file: action.result, isFetching: false, error: "" };
        case types.FILE_DOWNLOAD_FAILURE:
            return { ...state, isFetching: false, error: action.error };


        default:
            return { ...state };
    }
}