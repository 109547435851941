import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import PropTypes from 'prop-types';

export const SectionHeader = (props) => {
    let { children } = props;
    return <Row>
        <Col md={12}>
            <div className="div-pageTitle">
                <Label>{children}</Label>
            </div>
        </Col>
    </Row>;
}
SectionHeader.propTypes = {
    children: PropTypes.node
}