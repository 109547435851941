import React, { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { FormField } from "../../../common/FormField";
import PropTypes from 'prop-types';

export const HoursOfOperation = (props) => {
    let { updateHours, addHours, removeHours, value, index } = props;
    const [dayOfWeek, setDayOfWeek] = useState(value.day ?? "Monday");
    const [startTime, setStartTime] = useState(value.startTime ?? null);
    const [endTime, setEndTime] = useState(value.endTime ?? null);

    const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const daysOfTheWeekOptions = daysOfTheWeek.map((day) => { return { value: day , label: day} });

    const onFieldChange = (field, e) => {
        let hoursOfOperation = { day: dayOfWeek, startTime, endTime };
        hoursOfOperation = {...hoursOfOperation, [field]: e.target.value };

        switch (field) {
            case "day":
                setDayOfWeek(e.target.value);
                break;
            case "startTime":
                setStartTime(e.target.value);
                break;
            case "endTime":
                setEndTime(e.target.value);
                break;
        }

        updateHours(hoursOfOperation);
    }

    return <Row className="hours-of-operation">
        <Col md={3}>
            <FormField type="select"
                required
                name="dayOfWeek"
                id="dayOfWeek"
                label="Select Day"
                options={daysOfTheWeekOptions}
                value={value.day}
                onChange={(e) => onFieldChange("day", e)}
            />
        </Col>
        <Col md={3}>
            <FormField
                className="align-bottom"
                type="time"
                name="startTime"
                id="startTime"
                label="Start Time"
                required
                value={value.startTime}
                onChange={(e) => onFieldChange("startTime", e)}
            />
        </Col>
        <Col md={2}>
            <FormField
                className="align-bottom"
                type="time"
                name="endTime"
                id="endTime"
                label="End Time"
                required
                value={value.endTime}
                onChange={(e) => onFieldChange("endTime", e)}
            />
        </Col>
        <Col>
            <Button className="align-bottom" onClick={addHours}>+</Button>
            {index !== 0 && <Button className="align-bottom" onClick={removeHours}>-</Button>}
        </Col>
    </Row>
}

HoursOfOperation.propTypes = {
    updateHours: PropTypes.func,
    addHours: PropTypes.func,
    removeHours: PropTypes.func,
    index: PropTypes.number,
    value: PropTypes.object
}