import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as professionalApis from '../apis/professionalApis';
import * as lists from '../constants/listType';

function* professionalApplicationSagas() {
    yield takeLatest(types.GET_PROFESSIONAL_APPLICATIONS_START, getApplications);
    yield takeLatest(types.CREATE_PROFESSIONAL_APPLICATION_START, createApplication);
    yield takeLatest(types.UPDATE_PROFESSIONAL_APPLICATION_START, updateApplication);
    yield takeLatest(types.DELETE_PROFESSIONAL_APPLICATION_START, deleteApplication);
    yield takeEvery(types.APPLICATION_GET_REFERENCE_LIST_START, getReferenceList);
    yield takeEvery(types.GET_APPLICATION_DETAILS_START, getApplicationDetails);

}

function* getApplications(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const application = yield call(professionalApis.getApplications, userInfo);
        yield put({ type: types.GET_PROFESSIONAL_APPLICATIONS_SUCCESS, application });
    } catch (e) {
        yield put({ type: types.GET_PROFESSIONAL_APPLICATIONS_FAILURE, error: e });
    }
}

function* getApplicationDetails(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const applicationDetails = yield call(professionalApis.getApplicationDetails, params.appId, userInfo);
        yield put({ type: types.GET_APPLICATION_DETAILS_SUCCESS, applicationDetails });
    } catch (e) {
        yield put({ type: types.GET_APPLICATION_DETAILS_FAILURE, error: e });
    }
}

function* createApplication(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const application = yield call(professionalApis.createApplication, params.application, userInfo);
        if (params.multipleApplicationsPerUser) {
            yield put({ type: types.CREATE_PROFESSIONAL_APPLICATION_SUCCESS, application });
        }
        else {
            yield put({ type: types.CREATE_PROFESSIONAL_APPLICATION_FLAGOFF_SUCCESS, application });
        }
    } catch (response) {
        let result;
        yield response.then((r) => {
            if (r.errors) result = r.errors;
            else result = r;
        });
        yield put({ type: types.CREATE_PROFESSIONAL_APPLICATION_FAILURE, error: JSON.stringify(result) });
    }
}

function* updateApplication(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        const application = yield call(professionalApis.updateApplication, params.application, params.submit, userInfo);
        yield put({ type: types.UPDATE_PROFESSIONAL_APPLICATION_SUCCESS, application });
    } catch (e) {
        yield put({ type: types.UPDATE_PROFESSIONAL_APPLICATION_FAILURE, error: e });
    }
}

function* deleteApplication(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);
        var id = params.id;
        const success = yield call(professionalApis.deleteApplication, id, userInfo);
        if (success) {
            yield put({ type: types.DELETE_PROFESSIONAL_APPLICATION_SUCCESS, id });
        } 
    } catch (e) {
        yield put({ type: types.DELETE_PROFESSIONAL_APPLICATION_FAILURE, error: e });
    }
}

function* getReferenceList(params) {
    try {
        const userInfo = yield select((state) => state.user.userInfo);

        const list = yield call(professionalApis.getReferenceList, params.listname, userInfo);
        if (params.listname == "CountyLookup") {
            yield put({ type: types.APPLICATION_GET_COUNTY_REFERENCE_LIST_SUCCESS, list });
            return;
        }
        const isActive = (item) => {
            return item.active !== false;
        }
        let activeList = { ...list, items: list.items.filter(isActive) };

        switch (list.id) {
            case lists.ListType.TaxIdList:
                yield put({ type: types.APPLICATION_GET_TAXID_REFERENCE_LIST_SUCCESS, list });
                break;
            case lists.ListType.CountyList:
                yield put({ type: types.APPLICATION_GET_COUNTY_REFERENCE_LIST_SUCCESS, list });
                break;
            case lists.ListType.LanguageList:
                yield put({ type: types.APPLICATION_GET_LANGUAGE_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.StateList:
                yield put({ type: types.APPLICATION_GET_STATE_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.TaxonomyList:
                yield put({ type: types.APPLICATION_GET_TAXONOMY_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.AccreditationTypeList:
                yield put({ type: types.APPLICATION_GET_ACCREDITATIONTYPE_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.DegreeAndCertificationList:
                yield put({ type: types.APPLICATION_GET_DEGREEANDCERTIFICATION_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.AddressTypeList:
                yield put({ type: types.APPLICATION_GET_ADDRESSTYPE_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.ContactTypeList:
                yield put({ type: types.APPLICATION_GET_CONTACTTYPE_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.MalpracticeInsuranceTypeList:
                yield put({ type: types.APPLICATION_GET_MALPRACTICEINSURANCETYPE_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.MalpracticeInsuranceCarrierList:
                yield put({ type: types.APPLICATION_GET_MALPRACTICEINSURANCECARRIER_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.MalpracticeInsuranceAmountsList:
                yield put({ type: types.APPLICATION_GET_MALPRACTICEINSURANCEAMOUNTS_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.ProviderFacilityAffiliationList:
                yield put({ type: types.APPLICATION_GET_PROVIDERFACILITYAFFILIATION_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.SpecialtyList:
                yield put({ type: types.APPLICATION_GET_SPECIALTY_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.PracticeLimitationList:
                yield put({ type: types.APPLICATION_GET_PRACTICELIMITATION_REFERENCE_LIST_SUCCESS, list: activeList });
                break;
            case lists.ListType.MentalHealthServiceTypeList:
                yield put({ type: types.APPLICATION_GET_MENTALHEALTH_REFERENCE_LIST_SUCCESS, list: activeList });
                break;

        }
    } catch (e) {
        yield put({ type: types.APPLICATION_GET_REFERENCE_LIST_FAILURE, error: e });
    }
}

export default professionalApplicationSagas;