import * as clientLoggingApis from "../apis/clientLoggingApis";
import * as types from "../constants/actionTypes";
import { call, takeLatest } from "redux-saga/effects";

function* clientLoggingSagas() {
    yield takeLatest(types.LOG_INFO_START, logInfo);
    yield takeLatest(types.LOG_DEBUG_START, logDebug);
    yield takeLatest(types.LOG_WARNING_START, logWarning);
    yield takeLatest(types.LOG_ERROR_START, logError);
}

function* logInfo(params) {
    try {
        yield call(clientLoggingApis.logInfo, params.clientLoggingModel, params.userInfo);
    } catch (e) {
        //why log the fact that you didn't log?????
    }
}

function* logDebug(params) {
    try {
        yield call(clientLoggingApis.logDebug, params.clientLoggingModel, params.userInfo);
    } catch (e) {
        //why log the fact that you didn't log?????
    }
}

function* logWarning(params) {
    try {
        yield call(clientLoggingApis.logWarning, params.clientLoggingModel, params.userInfo);
    } catch (e) {
        //why log the fact that you didn't log?????
    }
}

function* logError(params) {
    try {
        yield call(clientLoggingApis.logError, params.clientLoggingModel, params.userInfo);
    } catch (e) {
        //why log the fact that you didn't log?????
    }
}

export default clientLoggingSagas;