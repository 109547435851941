import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Label, Row, Col, Input, FormGroup, Button } from 'reactstrap';
import { SectionHeader } from '../../../common/SectionHeader';
import { SubSectionHeader } from '../../../common/SubSectionHeader';
import { FormField } from '../../../common/FormField';
import * as types from '../../../../constants/actionTypes';
import { NavigationFooter, scrollToTop } from './NavigationFooter';
import * as lists from '../../../../constants/listType';
import { validateForm, validateMedicaid, validateNpi, validateNumberBetween, validateTaxId, validateNA } from '../../../../constants/validations';
import { HoursOfOperation } from './HoursOfOperation';
import { PracticeLimitations } from './PracticeLimitations';
import { useHistory } from 'react-router';
import { CustomAlert } from '../../../common/CustomAlert';
import { useCookies } from 'react-cookie';
import { ClientLoggingModel } from '../../../../models/ClientLoggingModel';
import { LoadingAnimation } from '../../../common/LoadingAnimation';
import { useFeatureFlag } from '../../../../common/useFeatureFlag';
import { TaxIdModal } from '../../Dashboard/TaxIdModal';

export const PracticeInformation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const applicationId = history && history.location.state ? history.location.state.appId : "";
    const [pageLoading, setPageLoading] = useState(true);
    const userInfo = useSelector((state) => state.user.userInfo);
    if (!userInfo) {
        history.push("/")
    }
    const [cookies, setCookie] = useCookies(['isFormDataSaved']);
    const [pageLoad, setPageLoad] = useState(0);
    const { enabled: credentialingEnabled } = useFeatureFlag('125081_PAMMultipleApplicationsPerUser');
    const [showTinModal, setShowTinModal] = useState(false);

    const [errors, setErrors] = useState({
        MedicaidNumber: '',
        MedicareNumber: '',
        TaxId: '',
        GroupNpi: '',
        NumOfProviders: ''
    })

    const username = useSelector((state) => {
        return state.user.userInfo ? state.user.userInfo.userName : "";
    });
    const providerType = useSelector((state) => {
        return state.user.userInfo ? state.user.userInfo.providerType : "";
    });

    const specialties = useSelector((state) => state.professionalApplication.specialtyList);
    const specialtiesListOptions = specialties
        ? specialties.items.map((s) => { return { value: s.name, label: s.name } })
        : [];
    const applications = useSelector((state) => state.professionalApplication.applications);
    const applicationDetails = useSelector((state) => state.professionalApplication.applicationDetails);
    const applicationBeingUpdated = useSelector((state) => state.professionalApplication.applicationBeingUpdated);
    let currentPractice = applicationBeingUpdated ? applicationBeingUpdated : applicationDetails;

    const baseHoursOfOperation = [{ day: "Monday", startTime: "", endTime: "" }];

    const [LegalName, setLegalName] = useState(currentPractice?.legalName);
    const [DoingBusinessAsName, setDoingBusinessAs] = useState(currentPractice?.doingBusinessAsName);
    const [GroupNpi, setGroupNpi] = useState(currentPractice?.groupNpi);
    const isFetching = useSelector((state) => state.user.isFetching);
    const npiIsValid = useSelector((state) => state.user.npiIsValid);
    const taxIdInUse = useSelector((state) => state.user.taxIdInUse);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    const onGroupNpiChange = (e) => {
        let error = validateNpi(e.target.value);
        if (error === '') {
            var message = "Performing API call to validate NPI " + e.target.value + ".";
            var methodName = "onGroupNpiChange";
            var jsonResult = JSON.stringify(e.target.value);
            logInfoWrite(message, methodName, jsonResult);
            dispatch({ type: types.SIGNUP_GET_NPI_START, npi: e.target.value });
        }

        setErrors({ ...errors, GroupNpi: error });
        setGroupNpi(e.target.value);
    }
    const [GroupSpecialty, setGroupSpecialty] = useState(currentPractice?.groupSpecialty);
    const onGroupSpecialtyChange = (e) => {
        let specialty = specialties.items.find((s) => { return s.name === e.target.value });
        setGroupSpecialty(specialty);
    }
    const [TaxId, setTaxId] = useState(currentPractice?.taxId);
    const onTaxIdChange = (e) => {
        let error = validateTaxId(e.target.value);
        if (error === '') {
            var message = "Performing API call to validate tax ID " + e.target.value + ".";
            var methodName = "onGroupNpiChange";
            var jsonResult = JSON.stringify(e.target.value);
            logInfoWrite(message, methodName, jsonResult);
            dispatch({ type: types.SIGNUP_GET_TAX_ID_START, taxId: e.target.value });
        }
        setErrors({ ...errors, TaxId: error });
        setTaxId(e.target.value);
    }
    const onTaxIdModalChange = (taxId) => {
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
        setTaxId(taxId);
    }
    const [MedicaidNumber, setMedicaidNumber] = useState(currentPractice?.tpi);
    const onMedicaidNumberChange = (e) => {
        let error = validateMedicaid(e.target.value);

        setErrors({ ...errors, MedicaidNumber: error });

        setMedicaidNumber(e.target.value)
    }

    const onMedicareNumberChange = (e) => {
        let error = validateNA(e.target.value);

        setErrors({ ...errors, MedicareNumber: error });

        setMedicareNumber(e.target.value)
    }
    const [navigation, setNavigation] = useState(null);
    const [MedicareNumber, setMedicareNumber] = useState(currentPractice?.medicareNumber);
    const [NumOfProviders, setNumOfProviders] = useState(currentPractice?.providerCount);
    const onNumOfProvidersChange = (value) => {
        let error = validateNumberBetween(value, 1, 999);

        setErrors({ ...errors, NumOfProviders: error });

        setNumOfProviders(value);
    }
    const [defaultHoursChecked, setDefaultHoursChecked] = useState(false);
    const [hoursOfOperation, setHoursOfOperation] = useState(currentPractice?.hoursOfOperation ?? baseHoursOfOperation);
    const onHoursOfOperationChange = (index, value) => {
        let hours = [...hoursOfOperation];
        hours[index] = value;
        setHoursOfOperation(hours);
    }
    const addHoursOfOperation = () => {
        if (hoursOfOperation.length >= 7) return;
        let hours = [...hoursOfOperation];
        hours.push({ day: "Monday", startTime: "", endTime: "" });
        setHoursOfOperation(hours);
    }
    const removeHoursOfOperation = (index) => {
        let hours = hoursOfOperation.filter((x, xIndex) => xIndex !== index);

        setHoursOfOperation(hours);
    }
    const handleDefaultHoursOfOperationChecked = (e) => {
        if (!e.target.checked) {
            setHoursOfOperation(baseHoursOfOperation);
            setDefaultHoursChecked(false);
        }
        else {
            let defaultHoursOfOperation = [
                { day: daysOfTheWeek[0], startTime: "08:00", endTime: "17:00" },
                { day: daysOfTheWeek[1], startTime: "08:00", endTime: "17:00" },
                { day: daysOfTheWeek[2], startTime: "08:00", endTime: "17:00" },
                { day: daysOfTheWeek[3], startTime: "08:00", endTime: "17:00" },
                { day: daysOfTheWeek[4], startTime: "08:00", endTime: "17:00" },
            ];

            setHoursOfOperation(defaultHoursOfOperation);
            setDefaultHoursChecked(true);
        }
    }
    const validateHoursOfOperation = () => {
        if (!hasHoursOfOperationBeenInput()) {
            return true;
        }

        let invalidDays = hoursOfOperation.filter((day) => {
            if (day.day && day.startTime && day.endTime) {
                return false;
            }
            return true;
        });
        return invalidDays.length === 0;
    }
    const hasHoursOfOperationBeenInput = () => {
        return !(hoursOfOperation.length === 1
            && hoursOfOperation[0].day === baseHoursOfOperation[0].day
            && hoursOfOperation[0].startTime === baseHoursOfOperation[0].startTime
            && hoursOfOperation[0].endTime === baseHoursOfOperation[0].endTime);
    }

    const [Limitations, setPracticeLimitations] = useState(currentPractice?.practiceLimitations ? currentPractice?.practiceLimitations : null);

    const [showAlert, setShowAlert] = useState("");
    const [disableButton, setDisableButton] = useState(false);

    const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const requiredFields = [{ key: 'Legal Name', value: LegalName }, { key: 'Doing Business As', value: DoingBusinessAsName },
    { key: 'Group Npi', value: GroupNpi }, { key: 'Tax Id', value: TaxId }, { key: 'Number Of Providers', value: NumOfProviders },
    { key: 'Practice Limitations', value: Limitations }, { key: 'Hours of Operation', value: (hasHoursOfOperationBeenInput() ? hoursOfOperation : null) }];

    const isValidData = () => {
        return validateForm(errors) && validateHoursOfOperation();
    }

    const apiErrors = useSelector((state) => state.professionalApplication.error);

    useEffect(() => {
        if (userInfo) {
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.SpecialtyList });
        }
    }, [])

    useEffect(() => {
        if (specialties) {
            setPageLoading(false);
        }
    }, [specialties])

    useEffect(() => {
        if (pageLoad > 2) {
            setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
        }
        setPageLoad(pageLoadCount => pageLoadCount + 1);
    }, [LegalName, DoingBusinessAsName, GroupNpi, GroupSpecialty, TaxId, NumOfProviders, hoursOfOperation, MedicaidNumber, Limitations, errors])

    useEffect(() => {
        if (!isFetching)
            setErrors({ ...errors, GroupNpi: npiIsValid });
    }, [npiIsValid]);
    useEffect(() => {
        if (!isFetching)
            setErrors({ ...errors, TaxId: taxIdInUse });
    }, [taxIdInUse]);

    const onSaveClick = () => {
        setShowAlert("");
        //Intermittent Save
        if (validateForm(errors)) {
            let application = {
                ...currentPractice,
                providerType: providerType,
                username: username,
                legalName: LegalName,
                doingBusinessAsName: DoingBusinessAsName,
                groupNpi: GroupNpi,
                groupSpecialty: GroupSpecialty,
                taxId: TaxId,
                tpi: MedicaidNumber,
                medicareNumber: MedicareNumber,
                providerCount: NumOfProviders,
                hoursOfOperation,
                practiceLimitations: Limitations
            };
            let message = "";
            let methodName = "onSaveClick";
            let jsonResult = "";
            if (!currentPractice || !currentPractice.id) {
                message = "Creating professional contract application.";
                jsonResult = JSON.stringify(application);
                logInfoWrite(message, methodName, jsonResult);
                //Refer bug 106166 - Duplicate application were created on multiple clicks of save/next button. 
                //So disable button only when we dispatch create application api call which will happen only once.
                setDisableButton(true);
                if (credentialingEnabled) {
                    dispatch({ type: types.CREATE_PROFESSIONAL_APPLICATION_START, application: application, multipleApplicationsPerUser: true });
                } else {
                    dispatch({ type: types.CREATE_PROFESSIONAL_APPLICATION_START, application: application, multipleApplicationsPerUser: false});
                }
            } else {
                message = "Updating professional contract application.";
                jsonResult = JSON.stringify(application);
                logInfoWrite(message, methodName, jsonResult);
                dispatch({ type: types.UPDATE_PROFESSIONAL_APPLICATION_START, application: application, submit: false });
            }
            dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: application });

            //set isFormSaved = true whenever form is saved
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
            var contractApiResultMessage = "Professional contract save success is " + apiCallSuccess + ".";
            if (apiCallSuccess) {
                message = contractApiResultMessage;
                jsonResult = JSON.stringify(currentPractice);
                logInfoWrite(message, methodName, jsonResult);
            }

            if (apiErrors) {
                message = contractApiResultMessage;
                methodName = "onSaveClick";
                jsonResult = JSON.stringify(apiErrors);
                logErrorWrite(message, methodName, jsonResult);
            }
        }
        else {
            setNavigation(null);
        }
    }
    const onBackButtonClick = () => {
        dispatch({ type: types.CLEAN_PROFESSIONAL_APPLICATIONS });
        dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: null });
        history.push("/Dashboard");
    }
    const onNextButtonClick = () => {
        setNavigation("/practiceLocationInfo");
        onSaveClick();
    }

    const apiCallSuccess = useSelector((state) => state.professionalApplication.applicationApiCalledSuccess);
    useEffect(() => {
        if (apiCallSuccess && currentPractice) {
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            if (navigation) {
                history.push(navigation);
            }
            else {
                scrollToTop();
                setShowAlert("success");
            }
        } else if (apiErrors) {
            scrollToTop();
            setShowAlert("danger");
        }
        setNavigation(null);
        // Re-enable button when api call returns. 
        setDisableButton(false);
    }, [apiCallSuccess, apiErrors]);

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "PracticeInformation",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    const logErrorWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "PracticeInformation",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_ERROR_START, clientLoggingModel, userInfo });
        }
    }

    return <div className='practice-information'>
        {showAlert.length > 0 && <CustomAlert type={showAlert} />}
        <SectionHeader>Practice Information</SectionHeader>
        {
            pageLoading ?
                <LoadingAnimation /> :
                <div>
                    <Form>
                        <SubSectionHeader>Identification </SubSectionHeader>
                        <FormField type="text"
                            required
                            name="legalName"
                            id="legalName"
                            label="Legal Name (as reflected on W9)"
                            value={LegalName}
                            maxLength={70}
                            onFocusOut={(e) => setLegalName(e.target.value.trim())}
                            onChange={(e) => setLegalName(e.target.value)}
                        />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="required-field" for="dba">Doing Business As</Label>
                                    <span className="margin-left">
                                        <Input type="checkbox" name="check" id="matchLegalName" onClick={(e) => { if (e.target.checked) { setDoingBusinessAs(LegalName); } }} />
                                        <Label className="checkbox-label" for="matchLegalName" check>Same as Legal Name</Label>
                                    </span>
                                    <Input type="text" name="dba" id="dba" value={DoingBusinessAsName} onChange={(e) => setDoingBusinessAs(e.target.value)}
                                        maxLength={70} onBlur={(e) => setDoingBusinessAs(e.target.value.trim())} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormField type="text"
                                    required
                                    name="groupNpi"
                                    id="groupNpi"
                                    label="Group NPI"
                                    value={GroupNpi}
                                    error={errors.GroupNpi}
                                    onChange={onGroupNpiChange}
                                />
                            </Col>
                            <Col md={6}>
                                <FormField type="select"
                                    name="groupSpecialty"
                                    id="groupSpecialty"
                                    label="Group Specialty"
                                    value={GroupSpecialty?.name}
                                    options={specialtiesListOptions}
                                    onChange={onGroupSpecialtyChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <FormField type="text"
                                    required
                                    name="taxId"
                                    id="taxId"
                                    label="Tax ID"
                                    value={TaxId}
                                    error={errors.TaxId}
                                    onChange={onTaxIdChange}
                                    disabled={credentialingEnabled}
                                />
                            </Col>
                            {credentialingEnabled && <Col md={1}>
                                <br />
                                <Button className='edit-tax-id' onClick={() => { setShowTinModal(true) }}>Edit</Button>
                            </Col>}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormField type="text"
                                    name="medicaidNumber"
                                    id="medicaidNumber"
                                    label="Medicaid Number(Primary)"
                                    value={MedicaidNumber}
                                    error={errors.MedicaidNumber}
                                    onChange={onMedicaidNumberChange}
                                    maxLength={9}
                                />
                            </Col>
                            <Col md={6}>
                                <FormField type="text"
                                    name="medicareNumber"
                                    id="medicareNumber"
                                    label="Medicare Number"
                                    value={MedicareNumber}
                                    error={errors.MedicareNumber}
                                    onChange={onMedicareNumberChange}
                                    maxLength={14}
                                />
                            </Col>
                        </Row>
                        <SubSectionHeader>General Info</SubSectionHeader>
                        <Row>
                            <Col md={4}>
                                <FormField type="number"
                                    required
                                    name="numOfProviders"
                                    id="numOfProviders"
                                    label="Number Of Providers"
                                    value={NumOfProviders}
                                    min={1}
                                    max={999}
                                    error={errors.NumOfProviders}
                                    onChange={(e) => onNumOfProvidersChange(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Label className="required-field">Hours of Operation</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Input type="checkbox" value={defaultHoursChecked} onClick={handleDefaultHoursOfOperationChecked} /> <Label className="checkbox-label"> Click here if your hours are Mon-Fri 8am-5pm, otherwise list below</Label>
                            </Col>
                        </Row>
                        {!defaultHoursChecked && hoursOfOperation.map((h, index) => <HoursOfOperation
                            key={"hours-of-operation-" + index}
                            index={index}
                            addHours={addHoursOfOperation}
                            removeHours={() => removeHoursOfOperation(index)}
                            updateHours={(value) => onHoursOfOperationChange(index, value)}
                            value={h} />)}
                        <Row>
                            <Col md={12}>
                                <Label className="required-field">Practice Limitations (Select only one)</Label>
                            </Col>
                        </Row>
                        <PracticeLimitations updatePracticeLimitations={setPracticeLimitations} currentPracticeLimitations={Limitations} />
                    </Form>
                    <TaxIdModal showTinModal={showTinModal}
                        initTaxId={TaxId}
                        closeModal={() => { setShowTinModal(false) }}
                        changeTaxId={onTaxIdModalChange}
                    />
                    <NavigationFooter BackButtonIsDisplayed={false}
                        onNextPageClick={onNextButtonClick}
                        onSaveClick={onSaveClick} isButtonDisabled={disableButton}
                        onBackButtonClick={onBackButtonClick}
                        isValidData={isValidData}
                        requiredFields={requiredFields}
                    />
                </div>
        }
    </div>;
}