import * as types from '../constants/actionTypes';
import { ProfessionalApplication } from '../models/ProfessionalApplication';

export const applicationInitialState = {
    taxIdList: null,
    countyList: null,
    languageList: null,
    StateList: null,
    taxonomyList: null,
    accreditationTypeList: null,
    degreeAndCertificationList: null,
    addressTypeList: null,
    contactTypeList: null,
    malpracticeInsuranceTypeList: null,
    malpracticeInsuranceCarrierList: null,
    malpracticeInsuranceAmountsList: null,
    providerFacilityAffiliationList: null,
    specialtyList: null,
    practiceLimitationList: null,
    mentalHealthServiceTypeList: null,
    applications: null,
    applicationBeingUpdated: null,
    isFetching: true,
    isAppDataFetching: true,
    countyLookup: new Map(),
    error: "",
    applicationApiCalledSuccess: false,
    applicationDetails: null,
};

export default function professionalApplicationReducer(state = applicationInitialState, action) {
    switch (action.type) {
        case types.LOGOUT:
            return applicationInitialState;

        case types.REFRESH_APPLICATION_API_CALL_FLAG:
            return { ...state, applicationApiCalledSuccess: false };

        case types.INITIALIZE_PROFESSIONAL_APPLICATION:
            let newArray = [action.application].concat(state.applications);
            return { ...state, applications: newArray };
        case types.CLEAN_PROFESSIONAL_APPLICATIONS:
            let cleanArray = state.applications.filter(app => { return app.id });
            return { ...state, applications: cleanArray };
        case types.STORE_APPLICATION:
            return { ...state, applicationBeingUpdated: action.applicationBeingUpdated, error: null };

        case types.GET_APPLICATION_DETAILS_START:
            return { ...state, isAppDataFetching: true, error: null };
        case types.GET_APPLICATION_DETAILS_SUCCESS:
            return { ...state, applicationDetails: action.applicationDetails, isAppDataFetching: false };
        case types.GET_APPLICATION_DETAILS_FAILURE:
            return { ...state, isAppDataFetching: true, error: action.error };

        case types.GET_PROFESSIONAL_APPLICATIONS_START:
            return { ...state, isAppDataFetching: true, error: null };
        case types.GET_PROFESSIONAL_APPLICATIONS_SUCCESS:
            return { ...state, applications: action.application, isAppDataFetching: false };
        case types.GET_PROFESSIONAL_APPLICATIONS_FAILURE:
            return { ...state, isAppDataFetching: true, error: action.error };

        case types.CREATE_PROFESSIONAL_APPLICATION_START:
            return { ...state, isFetching: true, error: null };
        case types.CREATE_PROFESSIONAL_APPLICATION_SUCCESS:
            let newApplicationsArray = state.applications.map(app => {
                if (app.taxId === action.application.taxId) {
                    return action.application;
                }
                return app;
            });
            let updatedApplication = { ...state.applicationBeingUpdated, id: action.application.id }
            return { ...state, applications: newApplicationsArray, applicationBeingUpdated: updatedApplication, isFetching: false, applicationApiCalledSuccess: true };
        case types.CREATE_PROFESSIONAL_APPLICATION_FLAGOFF_SUCCESS:
            let array = [action.application];
            let newApplication = { ...state.applicationBeingUpdated, id: action.application.id }
            return { ...state, applications: array, applicationBeingUpdated: newApplication, isFetching: false, applicationApiCalledSuccess: true };
        case types.CREATE_PROFESSIONAL_APPLICATION_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case types.UPDATE_PROFESSIONAL_APPLICATION_START:
            return { ...state, isFetching: true, error: null };
        case types.UPDATE_PROFESSIONAL_APPLICATION_SUCCESS:
            let newApplications = state.applications.map(app => {
                if (app.id === action.application.id) {
                    return action.application;
                }
                return app;
            })
            return { ...state, applications: newApplications, isFetching: false, applicationApiCalledSuccess: true };
        case types.UPDATE_PROFESSIONAL_APPLICATION_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case types.DELETE_PROFESSIONAL_APPLICATION_START:
            return { ...state, isFetching: true, error: null };
        case types.DELETE_PROFESSIONAL_APPLICATION_SUCCESS:
            let newList = state.applications.filter(function (value) {
                return value.id !== action.id;
            });
            return { ...state, applications: newList, isFetching: false };
        case types.DELETE_PROFESSIONAL_APPLICATION_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case types.APPLICATION_GET_REFERENCE_LIST_START:
            return { ...state, isFetching: true, error: null };
        case types.APPLICATION_GET_TAXID_REFERENCE_LIST_SUCCESS:
            return { ...state, taxIdList: action.list, isFetching: false };
        case types.APPLICATION_GET_COUNTY_REFERENCE_LIST_SUCCESS:
            return { ...state, countyLookup: action.list, isFetching: false };
        case types.APPLICATION_GET_LANGUAGE_REFERENCE_LIST_SUCCESS:
            return { ...state, languageList: action.list, isFetching: false };
        case types.APPLICATION_GET_STATE_REFERENCE_LIST_SUCCESS:
            return { ...state, stateList: action.list, isFetching: false };
        case types.APPLICATION_GET_TAXONOMY_REFERENCE_LIST_SUCCESS:
            return { ...state, taxonomyList: action.list, isFetching: false };
        case types.APPLICATION_GET_ACCREDITATIONTYPE_REFERENCE_LIST_SUCCESS:
            return { ...state, accreditationTypeList: action.list, isFetching: false };
        case types.APPLICATION_GET_DEGREEANDCERTIFICATION_REFERENCE_LIST_SUCCESS:
            return { ...state, degreeAndCertificationList: action.list, isFetching: false };
        case types.APPLICATION_GET_ADDRESSTYPE_REFERENCE_LIST_SUCCESS:
            return { ...state, addressTypeList: action.list, isFetching: false };
        case types.APPLICATION_GET_CONTACTTYPE_REFERENCE_LIST_SUCCESS:
            return { ...state, contactTypeList: action.list, isFetching: false };
        case types.APPLICATION_GET_MALPRACTICEINSURANCETYPE_REFERENCE_LIST_SUCCESS:
            return { ...state, malpracticeInsuranceTypeList: action.list, isFetching: false };
        case types.APPLICATION_GET_MALPRACTICEINSURANCECARRIER_REFERENCE_LIST_SUCCESS:
            return { ...state, malpracticeInsuranceCarrierList: action.list, isFetching: false };
        case types.APPLICATION_GET_MALPRACTICEINSURANCEAMOUNTS_REFERENCE_LIST_SUCCESS:
            return { ...state, malpracticeInsuranceAmountsList: action.list, isFetching: false };
        case types.APPLICATION_GET_PROVIDERFACILITYAFFILIATION_REFERENCE_LIST_SUCCESS:
            return { ...state, providerFacilityAffiliationList: action.list, isFetching: false };
        case types.APPLICATION_GET_SPECIALTY_REFERENCE_LIST_SUCCESS:
            return { ...state, specialtyList: action.list, isFetching: false };
        case types.APPLICATION_GET_PRACTICELIMITATION_REFERENCE_LIST_SUCCESS:
            return { ...state, practiceLimitationList: action.list, isFetching: false };
        case types.APPLICATION_GET_MENTALHEALTH_REFERENCE_LIST_SUCCESS:
            return { ...state, mentalHealthServiceTypeList: action.list, isFetching: false };
        case types.APPLICATION_GET_REFERENCE_LIST_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        default:
            return { ...state };
    }
}