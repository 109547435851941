import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PortalApplicationStatus } from '../../../constants/PortalApplicationStatus';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../constants/actionTypes';
import { useCookies } from 'react-cookie';
import { ClientLoggingModel } from '../../../models/ClientLoggingModel';

export const ApplicationStatus = (props) => {
    let { status, description, percentComplete, appId, app } = props;
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const [cookies, setCookie] = useCookies(['isFormDataSaved']);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    const userInfo = useSelector((state) => state.user.userInfo);
    const applicationDetails = useSelector((state) => state.professionalApplication.applicationDetails);
    const [applicationBeingUpdated, setApplicationBeingUpdated] = useState(false);

    const initModal = {
        modalOpen: false,
        modalBody: '',
        modalButtonText: '',
        modalButtonClick: () => { },
    };
    const [modal, setModal] = useState(initModal);
    const toggle = () => setModal(initModal);

    const [isCollapsed, setIsCollapsed] = useState(false);
    let statusClass = "application-status-pending";
    if (app) {
        if (app.portalStatus === PortalApplicationStatus.Drafted || app.portalStatus === PortalApplicationStatus.Created) {
            statusClass = "application-status-notSubmitted";
        }
        else if (app.percentComplete === 100 && app.portalStatus !== PortalApplicationStatus.ContractApproved) {
            statusClass = "application-status-denied";
        }
        else if (app.percentComplete === 100 && app.portalStatus === PortalApplicationStatus.ContractApproved) {
            statusClass = "application-status-approved";
        }
    }
    let pathColor = "#0078d4";
    if (percentComplete === 100 && status !== PortalApplicationStatus.ContractApproved) {
        pathColor = "#ffa366";
    }
    else if (percentComplete === 100 && status === PortalApplicationStatus.ContractApproved) {
        pathColor = "#009900";
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "ProviderInformation",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    const deleteApplication = (app) => {
        setModal({
            modalOpen: true,
            modalBody: 'Are you sure that you want to permanently delete this application?  It cannot be restored after it is deleted.',
            modalButtonText: 'Okay',
            modalButtonClick: () => { modalDeleteApplication(app) }
        });
    }
    const modalDeleteApplication = (app) => { //DELETE confirmed
        var message = "Deleting professional application.";
        var methodName = "modalDeleteApplication";
        var jsonResult = JSON.stringify(app);
        logInfoWrite(message, methodName, jsonResult);
        setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
        dispatch({ type: types.DELETE_PROFESSIONAL_APPLICATION_START, id: app.id });
        toggle();
    }

    const toggleProviderTable = () => {
        setIsCollapsed(!isCollapsed);
    }

    const updateApplication = (applicationId) => {
        dispatch({ type: types.GET_APPLICATION_DETAILS_START, appId: applicationId });
        setApplicationBeingUpdated(true);
    }

    useEffect(() => {
        if (applicationDetails && applicationBeingUpdated) {
            history.push("/practiceInformation");
        }
    }, [applicationDetails])

    useEffect(() => {
        if (app) {
            let rowArray = [];
            if (app?.providerSummary) {
                let list = app?.providerSummary;
                for (var i = 0; i < list.length; i++) {
                    let nm = list[i].name + " " + (list[i].lastName ? list[i].lastName : "");
                    rowArray.push({
                        id: list[i].providerGUID,
                        name: nm,
                        npi: list[i].npi
                    });

                }
            }
            setRows(rowArray);
        }
    }, [app]);

    const columns = [
        {
            field: 'name',
            headerName: 'Provider Name',
            flex: 1,
            headerClassName: 'tileGrid-header',
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'npi',
            headerName: 'NPI',
            type: 'number',
            flex: 1,
            headerClassName: 'tileGrid-header',
            headerAlign: 'center',
            align: 'center',
        },

    ];

    if (app) {
        if (app.portalStatus === PortalApplicationStatus.Drafted || app.portalStatus === PortalApplicationStatus.Created) {
            return <div className="nav-bar">
                <div className={"row col-12 titleBar-NotSubmitted " + statusClass}>
                    <div className="col-10">
                        {app.legalName}
                    </div>
                    <div className="col-2" >
                        <Button onClick={() => deleteApplication(app)}>
                            <FontAwesomeIcon className='envelope-icon' icon={faTrash} />
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="row col-9">
                        <div className="row col-12 groupDetails">{"NPI: " + app.groupNpi}</div>
                        <div className="row col-12 groupDetails">{"Tax Id: " + app.taxId}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="row col-12">
                        <div className="header">
                            {isCollapsed ? <FontAwesomeIcon icon={faAngleRight} onClick={toggleProviderTable} />
                                : <FontAwesomeIcon icon={faAngleDown} onClick={toggleProviderTable} />}
                        </div>
                        <div className="header">
                            Providers
                        </div>
                    </div>
                    {isCollapsed ? "" :
                        <div className="row col-12 providerTable">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowHeight={24}
                                headerHeight={24}
                                components={{
                                    NoRowsOverlay: (params) => (
                                        <div className="tableEmptyMsg">
                                            No provider data saved
                                        </div>
                                    ),

                                }}
                            />
                        </div>
                    }
                </div>
                <div className="row">
                    <Button className="row col-6 nav-link1" onClick={() => updateApplication(app.id)}
                    >
                        <h2 className="link">Continue Application</h2>
                    </Button>
                </div>

                <Modal isOpen={modal.modalOpen} toggle={toggle} >
                    <ModalBody>
                        {modal.modalBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={modal.modalButtonClick}>{modal.modalButtonText}</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div >
        }
        return <div className="nav-bar">
            <div className={"row col-12 titleBar " + statusClass}>
                {app.legalName}
            </div>

            <div className="row">
                <div className="col-9">
                    <div className="row col-12 groupDetails">{"NPI: " + app.groupNpi}</div>
                    <div className="row col-12 groupDetails">{"Tax Id: " + app.taxId}</div>
                </div>
                <div className="col-3">
                    <CircularProgressbar
                        className="circularBar"
                        value={app.percentComplete}
                        text={`${app.percentComplete}%`}
                        strokeWidth={50}
                        styles={buildStyles({
                            textColor: "White",
                            pathColor: "#0078d4",
                            strokeLinecap: "butt"
                        })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="row col-12 header">{"Submitted - " + app.portalStatus}</div>
                <div className="row col-12 providerdescription">{app.providerDefinition}</div>
            </div >
            <div className="row">
                <div className="row col-12">
                    <div className="header">
                        {isCollapsed ? <FontAwesomeIcon icon={faAngleRight} onClick={toggleProviderTable} />
                            : <FontAwesomeIcon icon={faAngleDown} onClick={toggleProviderTable} />}
                    </div>
                    <div className="header">
                        Providers
                    </div>
                </div>
                {isCollapsed ? "" :
                    <div className="row col-12 providerTable">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowHeight={24}
                            headerHeight={24}
                            components={{
                                NoRowsOverlay: () => (
                                    <div className="tableEmptyMsg">
                                        No provider data saved
                                    </div>
                                )
                            }}
                        />
                    </div>
                }
            </div>
        </div >
    }
    else {
        if (status === "Drafted" || status === "Created") {
            return <div className="row col-12">
                <div className="col-12 application-status">
                   Please select Update Application to continue your application.
                </div>
                <div className="col-12 dashboard-nav-link">
                    <Button className="nav-link" onClick={() => updateApplication(appId)}>
                        <h2 className="text-center title">Update Application</h2>
                    </Button>
                </div>
            </div>


        }
        if (status) {
            return <div className="row col-12">
                <div className="col-3">
                    <CircularProgressbar
                        value={percentComplete}
                        text={`${percentComplete}%`}
                        strokeWidth={50}
                        styles={buildStyles({
                            textColor: "White",
                            pathColor: pathColor,
                            strokeLinecap: "butt"
                        })}
                    />
                </div>
                <div className="col-9 application-status align-middle" >
                    <div className="col-12"><span className="status-name">{status}</span></div>
                    <div className="col-12">{description}</div>
                </div>
            </div>;
        }

        return <div className={"row application-status"}>
            <div className="col-12">Please select New Application to start a new application</div>
        </div>;
    }

}

ApplicationStatus.propTypes = {
    status: PropTypes.string,
    description: PropTypes.string,
    percentComplete: PropTypes.number,
    app: PropTypes.object,
}
