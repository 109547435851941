export const ListType = {
    TaxIdList : "TaxIdList",
    CountyList: "CountyList",
    CountyLookup: "CountyLookup",
    LanguageList : "LanguageList",
    StateList : "StateList",
    TaxonomyList : "TaxonomyList",
    AccreditationTypeList : "AccreditationTypeList",
    DegreeAndCertificationList : "DegreeAndCertificationList",
    AddressTypeList : "AddressTypeList",
    ContactTypeList : "ContactTypeList",
    MalpracticeInsuranceTypeList: "MalpracticeInsuranceTypeList",
    MalpracticeInsuranceCarrierList: "MalpracticeInsuranceCarrierList",
    MalpracticeInsuranceAmountsList: "MalpracticeInsuranceAmountsList",
    ProviderFacilityAffiliationList : "ProviderFacilityAffiliationList",
    SpecialtyList : "SpecialtyList",
    PracticeLimitationList : "PracticeLimitationList",
    MentalHealthServiceTypeList : "MentalHealthServiceTypeList",
    ApplicationStatusList: "ApplicationStatusList",
};

Object.freeze(ListType);