import React, { useState, useEffect } from 'react';
import logo from '../../images/Branding/2_Baylor Scott & White Health Plan_Horz_Color.png';
import { ProviderNavigation } from '../common/ProviderNavigation';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as types from '../../constants/actionTypes';
import { useCookies } from 'react-cookie';
import "bootstrap/dist/js/bootstrap.min.js";

export const Header = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const logOutSuccess = useSelector((state) => state.user.success);
    const userInfo = useSelector((state) => state.user.userInfo);
    const welcomeComplete = useSelector((state) => state.user.welcomeComplete);
    const [hideLogOut, sethideLogOut] = useState(false);
    const [logOut, setlLogOut] = useState(false);
    const [cookies, removeCookie] = useCookies(['isFormDataSaved']);

    const navigateToWelcome = () => {
        var logout = true;
        if (cookies.isFormDataSaved === "false") {
            logout = window.confirm("Unsaved changes will be lost, Do you want to Continue?");
        }
        if (logout) {
            setlLogOut(true);
            removeCookie("isFormDataSaved");
            dispatch({ type: types.LOGOUT });
        }
    }

    useEffect(() => {
        if (logOutSuccess && (userInfo == null || !userInfo) && logOut) {
            history.push('/');
        }

        if (userInfo == null || !userInfo || !welcomeComplete) {
            sethideLogOut(true);
        }
        else {
            sethideLogOut(false);
        }
    }, [logOutSuccess, userInfo, welcomeComplete]);

    return <div className= "header fixed-top">
        <div className="headerTop">
            <nav className="text-right">
                <div className="logo-and-menu">
                <a href="https://swhp.org/" target="_blank" className="sw-header-logo-link" rel="noreferrer">
                    <img src={logo} alt="swhp-logo" className="logo" />
                    </a>
                {hideLogOut ? "" : <Button className="provider-login text-center" onClick={navigateToWelcome}>LOG OUT</Button>}
                    <button className="navbar-toggler menu-icon tooltip-hbm collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="animated-icon3"><span></span><span></span><span></span></div>
                    <span className="tooltiptext">MENU</span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                    <div className="menu hamburger-menu alert-hamburger-menu" id="hamburger-menu">
                        <section className="ac-container">
                            <div className="menu-col-one">
                                    <label htmlFor="ac-1">I am...</label>
                                    <article className="ac-small">
                                        <ul className="menu-links">
                                            <li>
                                            <a href="https://www.bswhealthplan.com/Providers/Pages/Resources.aspx" target="_blank" rel="noreferrer">A Member</a>
                                            </li>
                                            <li>
                                            <a href="https://www.bswhealthplan.com/Group/" target="_blank" rel="noreferrer">An Employer</a>
                                            </li>
                                            <li>
                                            <a href="https://www.bswhealthplan.com/Providers/" target="_blank" rel="noreferrer">A Provider</a>
                                            </li>
                                            <li>
                                            <a href="https://www.bswhealthplan.com/Agent/" target="_blank" rel="noreferrer">An Agent</a>
                                            </li>
                                        </ul>
                                    </article>
                            </div>
                            <div className="menu-col-two">
                                <label htmlFor="ac-2">Plan Information</label>
                                        <article className="ac-medium">
                                            <ul className="menu-links">
                                                <li>
                                            <a href="https://www.bswhealthplan.com/individuals-families/Pages/Medicare.aspx" target="_blank" rel="noreferrer">Medicare Advantage</a>
                                                    <span>Plan details, resources and pharmacy information.</span>
                                                </li>
                                                <li>
                                            <a href="https://www.bswhealthplan.com/individuals-families/Pages/Marketplace.aspx" target="_blank" rel="noreferrer">Marketplace</a>
                                                    <span>Plan details, resources and pharmacy information.</span>
                                                </li>
                                                <li>
                                            <a href="https://www.bswhealthplan.com/individuals-families/Pages/Medicaid.aspx" target="_blank" rel="noreferrer">STAR Medicaid</a>
                                                    <span>Plans for low-income families.</span>
                                                </li>
                                                <li>
                                            <a href="https://www.bswhealthplan.com/individuals-families/Pages/CHIP.aspx" target="_blank" rel="noreferrer">CHIP</a>
                                            <span>Children&apos;s Health Insurance Program plans.</span>
                                                </li>
                                                <li>
                                            <a href="https://www.bswhealthplan.com/Group/Pages/default.aspx" target="_blank" rel="noreferrer">Small &amp; Large Group Plans</a>
                                                    <span>Plans for organizations of all sizes.</span>
                                                </li>
                                            </ul>
                                    </article>
                                </div>
                            <div className="menu-col-three">
                                <label htmlFor="ac-3">Resources</label>
                                            <article className="ac-large">
                                                <ul className="menu-links">
                                                    <li>
                                            <a href="https://www.bswhealthplan.com/Pages/App.aspx" target="_blank" rel="noreferrer">MyBSWHealth App</a>
                                                        <span>Stay connected to your health.</span>
                                                    </li>
                                                    <li>
                                            <a href="https://bsw.softheon.com/account/payments/" target="_blank" rel="noreferrer">Make a Marketplace Payment</a>
                                                        <span>Make a payment securely online.</span>
                                                    </li>
                                                    <li>
                                            <a href="https://www.bswhealthplan.com/Pages/Resources.aspx#virtualcare" target="_blank" rel="noreferrer">Virtual Care</a>
                                                        <span>Access to board-certified healthcare online.</span>
                                                    </li>
                                                </ul>
                                            </article>

                            </div>
                            <div className="menu-col-four">
                                <label htmlFor="ac-4">Quick Links</label>
                                                <article className="ac-large">
                                                    <ul className="menu-links">
                                                        <li>
                                            <a href="https://www.bswhealthplan.com/Pages/COVID-19.aspx" target="_blank" rel="noreferrer">COVID-19</a>
                                                            <span>Information and resources.</span>
                                                        </li>
                                                        <li>
                                            <a href="https://www.bswhealthplan.com/Pages/PlanDocs.aspx" target="_blank" rel="noreferrer">Plan Documents</a>
                                                            <span>Documents available in PDF format for download.</span>
                                                        </li>
                                                        <li>
                                            <a href="https://www.bswhealthplan.com/Pages/Pharmacy.aspx" target="_blank" rel="noreferrer">Pharmacy Information</a>
                                                            <span>Formularies, pharmacy locator and more.</span>
                                                        </li>
                                                    </ul>
                                    </article>

                            </div>
                        </section>
                    </div>
                </div>
            </nav>
        </div>
        <ProviderNavigation />
    </div>;
}