export const validateMedicaid = (value) => {
    const validMedicaidRegex = RegExp("^[0-9]{9}$");
    let err =
        !value || validMedicaidRegex.test(value)
            ? ''
            : 'Medicaid Number should be 9-digit number.';
    return err;
};

export const validatePassword = (value) => {
    const validPasswordRegex =
        RegExp(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i);
    let err =
        validPasswordRegex.test(value)
            ? ''
            : 'Password must be atleast eight characters, at least one uppercase letter, one number and one special character';
    return err;
};

export const validateConfirmPassword = (value, password) => {
    let err =
        (value !== password)
            ? 'Confirm Password must match Password!'
            : '';
    return err;
};
export const validateTaxId = (value) => {
    const validTaxIdRegex = /^[0-9]{9}$/;
    const invalidTaxIdRegex = /^(0{9}|1{9}|2{9}|3{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9}|123456789)$/;

    let err = '';

    if (invalidTaxIdRegex.test(value)) {
        err = 'Invalid Tax ID';
    } else if (!validTaxIdRegex.test(value)) {
        err = 'Tax ID must be a 9-digit number.';
    }

    return err;
};

export const validateNpi = (value) => {
    const validNpiRegex = RegExp("^[0-9]{10}$");
    let err =
        !value || validNpiRegex.test(value)
            ? ''
            : 'Npi must be a 10-digit number.';
    return err;
};

export const validateNA = (value) => {
    let err = '';
    const naRegex = /n\/a|n\/A|N\/a|N\/A/gi;

    if (naRegex.test(value)) {
        err = "N/A or n/a is not acceptable.";
    }

    return err;
};

export const validateEmail = (value) => {
    const validEmailRegex =
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let err =
        !value || validEmailRegex.test(value)
            ? ''
            : 'Please enter valid Email.';
    return err;
};

export const validatePhone = (value) => {
    const validPhoneRegex = RegExp('^[2-9]{1}[0-9]{2}-[2-9]{1}[0-9]{2}-[0-9]{4}$');
    let err =
        !value || validPhoneRegex.test(value)
            ? ''
            : 'Phone number is not valid.';
    return err;
};

export const validateFax = (value) => {
    const validFaxRegex = RegExp('^[2-9]{1}[0-9]{2}-[2-9]{1}[0-9]{2}-[0-9]{4}$');
    let err =
        !value || validFaxRegex.test(value)
            ? ''
            : 'Fax number is not valid.';
    return err;
};

export const validateUrl = (value) => {
    const validFaxRegex = RegExp('^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&%\$#_]*)?$');
    let err =
        !value || validFaxRegex.test(value)
            ? ''
            : 'Please enter valid website address.';
    return err;
};

export const validateZipWithPlus4 = (value) => {
    //const validNineDigitZip = RegExp("^\d{5}(?:[-\s]\d{4})?$");
    const validNineDigitZip = RegExp("^[0-9]{5}-[0-9]{4}$");
    let err =
        validNineDigitZip.test(value)
            ? ''
            : '9-digit zip code is required.';
    return err;
}

export const validateForNotNullOrWhitespace = (value) => {
    let err =
        (!value || !/\S/.test(value))
            ? 'This field is required'
            : '';
    return err;
}

export const validateCityName = (value) => {
    let err = validateForNotNullOrWhitespace(value);
    if (err) {
        return err;
    }

    const valueContainsInvalidCharacter = RegExp('[^A-Za-z ]+');
    err =
        valueContainsInvalidCharacter.test(value)
            ? 'City name contains an invalid character.'
            : '';
    return err;
}

export const validateCaqh = (value) => {
    const validFaxRegex = RegExp('^[0-9]{8}$');
    let err =
        !value || validFaxRegex.test(value)
            ? ''
            : 'CAQH Number is not valid.';
    return err;
};

export const validateMedicareId = (value) => {
    const validMedicareRegex = RegExp("^[a-zA-Z0-9]+$");
    let err =
        !value || validMedicareRegex.test(value)
            ? ''
            : 'Invalid Medicare Number';
    return err;
};

export const validateLicenseNumber = (value) => {
    const validLicenseRegex = RegExp("^[a-zA-Z0-9]{1,20}$");
    let err =
        !value ||validLicenseRegex.test(value)
            ? ''
            : 'License Number must be 1 to 20 characters long.';
    return err;
};

export const validateSSN = (value) => {

    const validSSNRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
    const invalidSSNRegex = /^(0{9}|9{9}|000-00-0000|111-11-1111|222-22-2222|333-33-3333|444-44-4444|555-55-5555|666-66-6666|777-77-7777|888-88-8888|999-99-9999|123-45-6789)$/;

    if (invalidSSNRegex.test(value)) {
        return 'Invalid SSN.';
    }

    if (!value || validSSNRegex.test(value)) {
        return '';
    }

    return 'Invalid SSN.';
};


export const validateLength = (name, value, maxLength, minLength = 0) => {
    let required = minLength > 0 ? !value : false;
    let invalid =
        required || value.length < minLength || value.length > maxLength;
    let error = '';
    if (invalid) {
        switch (name) {
            case 'firstName':
                error = 'First Name must be 1 to 15 characters long.';
                break;
            case 'lastName':
                error = 'Last Name must be 1 to 60 characters long.';
                break;
            case 'middleInitial':
                error = 'Middle initial must be 1 character.';
                break;
            case 'deaNumber':
                error = 'DEA Number can be at most 20-characters.';
                break;
            case 'physicianName':
                error = minLength > 0 ? 'Physician name must be 1 to 20 characters long.'
                    : 'Physician name can be at most 20-characters.';
                break;
            case 'name':
                error = 'Name must be 1 to 80 characters long.';
                break;
        }
    }
    return error;
}

export const validateDate = (value) => {
    let err = '';
    if (value) {
        const startDate = new Date('01/01/1900');
        const endDate = new Date('12/31/9999');
        const date = new Date(value);

        let validDate = date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
        err =
            !validDate
                ? 'Enter valid Date.'
                : '';
    }
    return err;
};

export const validateExpirationDate = (value) => {
    let err = '';
    if (value) {;
        const eDate = new Date(value);

        var now = new Date();
        if (eDate < now) {
            err = "Expiration date must be later than today's date";
        }
    }
    return err;
};

export const validateDateRange = (sDate, eDate) => {
    let err = '';
    if (sDate && eDate) {
        const startDate = new Date(sDate);
        const endDate = new Date(eDate);

        let validDate = startDate < endDate;
        err =
            !validDate
                ? 'Expiration date should be after effective date.'
                : '';
    }
    return err;
};

export const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export const validateFileUpload = (file, validFileTypes, maxFileSize) => {
    const fileExtention = file.name.split('.').pop().toLowerCase();
    const fileSizeInMb = file.size ? (file.size / 1024 / 1024).toFixed(2) : 0;
    if (validFileTypes.split(',').indexOf("." + fileExtention) < 0) {
        return `Please select a valid file type: ${validFileTypes}`;
    }
    else if (parseFloat(fileSizeInMb) > maxFileSize) {
        return `Please select a file less than: ${maxFileSize}MB.`;
    }
    else {
        return '';
    }
}
export const validateNumberBetween = (numberToCheck, min, max=null) => {
    if (numberToCheck >= min && (!max || (max && numberToCheck <= max))) {
        return '';
    }
    if (max) {
        return `Value must be between ${min} and ${max}.`;
    }
    return `Value must be greater than ${min}`;
}

//input: array of key/value pairs with the field name as key, and current value
//output: name of first missing field encountered or '' if no missing values
export const validateRequiredFields = (requiredFields) => {
    let missingField = requiredFields.find(field => {
        return !field.value || '' !== validateForNotNullOrWhitespace(field.value)
    });

    if (!missingField) {//Check expiration dates when user clicks submit.
        missingField = requiredFields.find(field => {
            if (field.key === "License Number Expiration Date" || field.key === "DEA Number Expiration Date"
                || field.key === "Malpractice Expiration Date") {
                let err = validateExpirationDate(field.value);
                if (err !== '') {
                    return field;
                }
            }
        });

    }
    return missingField ? missingField.key : '';

}