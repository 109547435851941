import React, { useState, useEffect } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/actionTypes';
import * as validations from '../../constants/validations';
import { FormField } from '../common/FormField';
import { SectionHeader } from '../common/SectionHeader';
import { ClientLoggingModel } from '../../models/ClientLoggingModel';

export const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const emailConfirmation = useSelector((state) => state.user.emailConfirmation);
    const emailError = useSelector((state) => state.user.forgotPasswordError);
    const [email, setEmail] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const userInfo = useSelector((state) => state.user.userInfo);

    const [errors, setErrors] = useState({
        email: '',
    });
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);

    useEffect(() => {
        let valid = validations.validateForm(errors);

        if (email && valid) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);
        }
    }, [email]);

    const saveInputs = (event) => {
        const { value } = event.target;
        let errs = { ...errors };
        errs.email = validations.validateEmail(value);
        setEmail(value);
        setErrors(errs);

    }

    const resetPassword = (e) => {
        var message = "Performing API call to perform password reset for " + email + ".";
        var methodName = "resetPassword";
        var jsonResult = JSON.stringify(userInfo);
        logInfoWrite(message, methodName, jsonResult);
        dispatch({ type: types.FORGOT_PASSWORD_START, email: email });
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "ForgotPassword",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }


    return <div className="welcome">
        <SectionHeader>Forgot Your Password?</SectionHeader>
        <Row>
            <Col md={6}>
                <FormField type="text"
                    required
                    name="email"
                    id="email"
                    label="Please enter the information below to reset your password."
                    error={errors.email}
                    onChange={saveInputs}
                    placeholder="Enter your email"
                    value={email}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <Button className="btn btn-primary" disabled={disableButton} size="small" onClick={ resetPassword }> Reset Password </Button>
            </Col>
        </Row>
        { emailConfirmation ?
            <Row>
                <Col md={12}>
                    <span>
                        Your request was successful. You will receive an email that contains instructions on how to reset your password.
                    </span>
                </Col>
            </Row> : ""}
        {emailError ?
            <Row>
                <Col md={12}>
                    <span className="page-error">
                        {emailError}
                    </span>
                </Col>
            </Row> : ""}
    </div>;
}



