import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "../sagas/index";

import rootReducer from '../reducers/index';

export default function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];

    const store = createStore(rootReducer, preloadedState, composeWithDevTools(
        applyMiddleware(...middlewares)
    ));


    sagaMiddleware.run(rootSaga);
    return store;
}

