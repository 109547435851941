import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Header } from './common/Header';
import { Footer } from './common/Footer';
import PropTypes from 'prop-types';

export class Layout extends Component {
  static displayName = Layout.name;

    render() {
        return (
            <div>
                <Header />
                <Container>
                {this.props.children}
                </Container>
                <Footer />
            </div>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node
}
