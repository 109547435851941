import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Modal, ModalBody, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';
import * as types from '../../../constants/actionTypes';
import * as validators from '../../../constants/validations';
import { ClientLoggingModel } from '../../../models/ClientLoggingModel';
import { baseHoursOfOperation } from '../../../models/ProfessionalApplication';
import { useCookies } from 'react-cookie';
import { LoadingCircle } from '../../common/LoadingCircle';
import PropTypes from 'prop-types';

export const TaxIdModal = (props) => {
    let { showTinModal, closeModal, changeTaxId, initTaxId } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(['isFormDataSaved']);

    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);

    const [taxId, setTaxId] = useState(initTaxId ? initTaxId : "");
    const [tinIsApproved, setTinIsApproved] = useState(false);
    const [taxIdError, setTaxIdError] = useState("");
    const taxIdInUse = useSelector((state) => state.user.taxIdInUse);
    const taxIdApiError = useSelector((state) => state.user.taxIdError);
    const isFetching = useSelector((state) => state.user.isFetching);
    const userInfo = useSelector((state) => state.user.userInfo);
    const username = useSelector((state) => {
        return state.user.userInfo ? state.user.userInfo.userName : "";
    });

    useEffect(() => {
        if (!isFetching) {
            if (taxIdInUse) {
                setTaxIdError(taxIdInUse);
            }
            else if (taxIdApiError) {
                setTaxIdError("An api error occurred. Please try again later");
            }
        }
    }, [taxIdInUse, taxIdApiError]);

    useEffect(() => {
        if (isFetching) {
            return;
        }
        if (taxId.length === 9 && !taxIdError && !taxIdInUse && !taxIdApiError) {
            setTinIsApproved(true);
        }
        else {
            setTinIsApproved(false);
        }
    }, [taxId, taxIdError, taxIdInUse, taxIdApiError, isFetching]);

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "Dashboard",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    const validateTaxId = event => {
        let { value } = event.target;
        let message = "";
        let methodName = "inputChangeHandler";
        let jsonResult = "";

        setTaxId(value);
        let taxIdErr = validators.validateTaxId(value);
        if (taxIdErr === '') {
            message = "Performing API call to validate tax ID " + value + ".";
            jsonResult = JSON.stringify(value);
            logInfoWrite(message, methodName, jsonResult);
        }

        if (taxIdErr === '') {
            dispatch({ type: types.SIGNUP_GET_TAX_ID_START, taxId: value })
        }
        setTaxIdError(taxIdErr);
    }

    const navigateToNewApplication = () => {
        if (!tinIsApproved) {
            return;
        }
        let newApplication = {
            providerType: "",
            username: username,
            legalName: null,
            doingBusinessAsName: null,
            groupNpi: null,
            groupSpecialty: null,
            taxId: taxId,
            tpi: null,
            medicareNumber: null,
            providerCount: null,
            hoursOfOperation: baseHoursOfOperation,
            practiceLimitations: null
        };
        dispatch({ type: types.INITIALIZE_PROFESSIONAL_APPLICATION, application: newApplication });
        dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: newApplication });
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
        history.push("/practiceInformation");
    }

    const updateTin = () => {
        changeTaxId(taxId);
        setTaxId("");
        setTaxIdError("");
        closeModal();
    }

    const resetModal = () => {
        if (taxIdInUse) {
            dispatch({ type: types.SIGNUP_GET_TAX_ID_CANCEL });
        }
        setTaxId("");
        setTaxIdError("");
        closeModal();
    }

    const continueClick = () => {
        let pathName = location.pathname;
        console.log(pathName);
        if (pathName === '/practiceInformation') {
            updateTin();
        }
        else if (pathName === '/Dashboard') {
            navigateToNewApplication();
        }
        else {
            resetModal();
        }

    }

    return <Modal className='tax-id-modal' isOpen={showTinModal}>
        <ModalBody>
            <FormGroup>
                <Label for="taxId">Enter Tax ID for the Application:</Label>
                <Input className={(taxId.length > 0 ? "input-error" : "")} type="text" name="taxId" id="taxId" value={taxId || ""} onChange={validateTaxId} autoComplete="off" maxLength="9" />
                {taxIdError.length > 0 &&
                    <span className='error'>{taxIdError}</span>}
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={continueClick} disabled={!tinIsApproved} >
                {isFetching && <LoadingCircle />}
                 {!isFetching && "Continue"}
            </Button>
            <Button color="info" onClick={resetModal}>Cancel</Button>
        </ModalFooter>
    </Modal>
}

TaxIdModal.propTypes = {
    showTinModal: PropTypes.bool,
    closeModal: PropTypes.func,
    changeTaxId: PropTypes.func,
    initTaxId: PropTypes.string
}