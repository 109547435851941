import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '../../common/useFeatureFlag';

export const NavLink = (props) => {
    let { icon, title, description, button, link, onClickFunction, appId } = props;
    const { enabled: multipleApplicationsEnabled } = useFeatureFlag('125081_PAMMultipleApplicationsPerUser');

    if (!link) {
        return <div className="new-bar">
            <div className="row col-12 title">{title}</div>
            <div className="row col-12 description">{description}</div>
            <Button className="row col-6 new-link" onClick={onClickFunction}><h2 className="link">{button}</h2></Button>
        </div>;
    }
    if (multipleApplicationsEnabled) {
        return <div className="new-bar">
            <div className="row col-12 title">{title}</div>
            <div className="row col-12 description">{description}</div>
            <Link className="row col-6 new-link" to={link ? link : ""} onClick={onClickFunction}>
                <h2 className="link">{button}</h2>
            </Link>
        </div>;
    }
    else {
        return <Link className="nav-link" to={link ? {
            pathname: link,
            state: { appId: appId }
        }:""}
            onClick={onClickFunction}>
            <h2 className="text-center title">{title}</h2>
            <div className="text-center description">{description}</div>
        </Link>;
    }
}

NavLink.propTypes = {
    icon : PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.string,
    link: PropTypes.string,
    onClickFunction: PropTypes.func
}

