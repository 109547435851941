import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { AutoLogoutTimer } from './components/AutoLogoutTimer';
import { SignUp } from './components/pages/SignUp';
import { ForgotPassword } from './components/pages/ForgotPassword';
import { ResetPassword } from './components/pages/ResetPassword';
import { ConfirmEmail } from './components/pages/ConfirmEmail';
import { Welcome } from './components/pages/Welcome';
import { Dashboard } from './components/pages/Dashboard';
import { PracticeLocationInfo } from './components/pages/Application/Professional/PracticeLocationInfo';
import { PracticeContactInfo } from './components/pages/Application/Common/PracticeContactInfo';
import { ProviderInformation } from './components/pages/Application/Professional/ProviderInformation';
import { PracticeInformation } from './components/pages/Application/Common/PracticeInformation';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faInfoCircle, faTimesCircle, faEnvelopeOpenText, faTrash, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import './scss/App.scss';
library.add(faCheckCircle, faTimesCircle, faInfoCircle, faEnvelopeOpenText, faTrash, faAngleDown, faAngleRight);

export default class App extends Component {
    static displayName = App.name;
    render() {
        return (
            <Layout>
                <AutoLogoutTimer />

                {/*// unsecured*/}
                <Route exact path='/' component={Welcome} />
                <Route exact path='/signup' component={SignUp} />
                <Route exact path='/ForgotPassword' component={ForgotPassword} />
                <Route exact path='/ConfirmEmail' component={ConfirmEmail} />

                {/*// secured*/}
                <Route exact path='/Dashboard' component={Dashboard} />
                <Route exact path='/ResetPassword' component={ResetPassword} />
                <Route exact path='/PracticeLocationInfo' component={PracticeLocationInfo} />
                <Route exact path='/practiceContactInfo' component={PracticeContactInfo} />
                <Route exact path='/practiceInformation' component={PracticeInformation} />
                <Route exact path='/providerInformation' component={ProviderInformation} />
            </Layout>
        );
    }
}
