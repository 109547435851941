import * as types from '../constants/actionTypes';
export const dashboardInitialState = {
    messageCount: null,
    status: null,
    isFetching: true,
    error: ""
};

export default function dashboardReducer(state = dashboardInitialState, action) {
    switch (action.type) {
        case types.DASHBOARD_GET_APPLICATION_STATUS:
            return { ...state, isFetching: true };
        case types.DASHBOARD_GET_APPLICATION_STATUS_SUCCESS:
            return { ...state, status: action.applicationStatus.status, isFetching: false };
        case types.DASHBOARD_GET_APPLICATION_STATUS_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        case types.DASHBOARD_GET_MESSAGES:
            return { ...state, isFetching: true };
        case types.DASHBOARD_GET_MESSAGES_SUCCESS:
            return { ...state, messageCount: action.messageCount, isFetching: false };
        case types.DASHBOARD_GET_MESSAGES_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        case types.LOGOUT:
            return dashboardInitialState;

        default:
            return { ...state };
    }
}