import "regenerator-runtime/runtime";
import { all, fork } from "redux-saga/effects";
import dashboardSaga from "./dashboardSagas";
import userSagas from "./userSagas";
import professionalApplicationSagas from "./professionalApplicationSagas";
import documentSagas from "./documentSagas";
import clientLoggingSagas from "./clientLoggingSagas";

export default function* startSagas() {
    let watchers = [
        fork(dashboardSaga),
        fork(userSagas),
        fork(professionalApplicationSagas),
        fork(documentSagas),
        fork(clientLoggingSagas)
    ];

    try {
        yield all(watchers);
    }
    catch (err) {
        console.error(err);
    }
}