import * as appSettingsApi from "../apis/appSettingsApi";

const npiExtension = "ApplicationUser/Npi/";
const taxIdExtension = "ApplicationUser/TaxId/";

export const signUp = async (npi, taxId, firstName, lastName, email, password, providerType, phoneNumber) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + 'ApplicationUser/create';
    return await fetch(apiExtension, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            npi: npi,
            taxId: taxId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            providerType: providerType,
            phoneNumber: phoneNumber
        }),
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                return response.text().then(Promise.reject.bind(Promise));
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};

export const signIn = async (username, password) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + 'ApplicationUser/signIn';
    return await fetch(apiExtension, {
        method: 'POST',
        referrerPolicy: 'origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: username,
            password: password
        }),
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(response.text());
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};

export const getUserByEmail = async (email) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + `ApplicationUser/?email=${email}`;
    return await fetch(apiExtension, {
        method: 'GET'
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(null);
        }
    }).catch(err => {
        console.log(err); // Todo: Include in Client logging 
        return Promise.reject(err);
    });
};

export const getTaxId = async (taxId, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + taxIdExtension + taxId;
    const token = userInfo?.access_token;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.toString());
        }
        else if (response && response.status && response.status === 404) {
            return Promise.resolve('');
        }
        else {
            return Promise.reject('Internal Server Error');
        }
    }).catch(err => {
        console.log(err); // Todo: Include in Client logging 
        return Promise.reject(err);
    });
}

export const getNpi = async (npi) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + npiExtension + npi;
    return await fetch(url, {
        method: 'GET'
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(null);
        }
    }).catch(err => {
        console.log(err); // Todo: Include in Client logging 
        return Promise.reject(err);
    });
}

export const logOut = async () => {
    return true;
}

export const forgotPassword = async (email) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + 'ApplicationUser/forgotPassword';
    return await fetch(apiExtension, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email
        }),
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                return response.text().then(Promise.reject.bind(Promise));
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};

export const resetPassword = async (password, email, token) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + 'ApplicationUser/resetPassword';
    return await fetch(apiExtension, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            password: password,
            email: email,
            token: token
        }),
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                return response.text().then(Promise.reject.bind(Promise));
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};

export const confirmEmail = async (email, token) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + 'ApplicationUser/confirmEmail';
    return await fetch(apiExtension, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            token: token
        }),
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                return response.text().then(Promise.reject.bind(Promise));
            }
            return Promise.reject(response.text());
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};

export const resendEmail = async (email) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const apiExtension = apiUrl + 'ApplicationUser/resendConfirmationEmail';
    return await fetch(apiExtension, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email
        }),
    }).then(response => {
        if (response && response.status && response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                return response.text().then(Promise.reject.bind(Promise));
            }
            return Promise.reject(response.text());
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};