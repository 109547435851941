import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from '../common/NavLink';
import { ApplicationStatus } from './Dashboard/ApplicationStatus';
import * as types from '../../constants/actionTypes';
import { LoadingAnimation } from '../common/LoadingAnimation';
import { SectionHeader } from '../common/SectionHeader';
import { useHistory } from "react-router-dom";
import { ClientLoggingModel } from '../../models/ClientLoggingModel';
import { Row, Form } from 'reactstrap';
import { useFeatureFlag } from '../../common/useFeatureFlag';
import { TaxIdModal } from './Dashboard/TaxIdModal';
import { PortalApplicationStatus } from '../../constants/PortalApplicationStatus';
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Dashboard = () => {
    const { enabled: multipleApplicationsEnabled } = useFeatureFlag('125081_PAMMultipleApplicationsPerUser');
    const dispatch = useDispatch();
    const messageCount = useSelector((state) => state.dashboard.messageCount);
    const applications = useSelector((state) => state.professionalApplication.applications);
    const currentApplication = applications !== null && applications.length > 0 ? applications[0] : applications;
    const status = currentApplication?.portalStatus;
    const description = currentApplication?.providerDefinition;
    const percentComplete = currentApplication?.percentComplete;
    const appId = currentApplication?.id;
    const [isNotsubmittedCollapsed, setIsNotsubmittedCollapsed] = useState(false);
    const [isSubmittedCollapsed, setIsSubmittedCollapsed] = useState(true);
    const [isClosedCollapsed, setIsClosedCollapsed] = useState(true);

    const userInfo = useSelector((state) => state.user.userInfo);
    const history = useHistory();
    if (!userInfo) {
        history.push("/")
    }

    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    const [showTinModal, setShowTinModal] = useState(false);
    const [submittedApplications, setSubmittedApplications] = useState([]);
    const [closedApplications, setClosedApplications] = useState([]);
    const [notSubmittedApplications, setNotSubmittedApplications] = useState([]);


    useEffect(() => {
        var message = "Getting existing professional contract application, getting contract status, getting dashboard messages.";
        var methodName = "useEffect[]";
        var jsonResult = JSON.stringify(userInfo);
        logInfoWrite(message, methodName, jsonResult);
        dispatch({ type: types.WELCOME_COMPLETE });
        dispatch({ type: types.DASHBOARD_GET_APPLICATION_STATUS });
        dispatch({ type: types.DASHBOARD_GET_MESSAGES });
    }, []);


    useEffect(() => {
        let closedApps = applications?.filter(function (app) {
            return (app.percentComplete === 100);
        });

        let notSubmittedApps = applications?.filter(function (app) {
            return (app.portalStatus === PortalApplicationStatus.Drafted || app.portalStatus === PortalApplicationStatus.Created);
        });

        let submittedApps = applications?.filter(function (app) {
            return (app.portalStatus !== PortalApplicationStatus.Drafted && app.portalStatus !== PortalApplicationStatus.Created && app.percentComplete !== 100);
        });

        setClosedApplications(closedApps);
        setNotSubmittedApplications(notSubmittedApps);
        setSubmittedApplications(submittedApps);
    }, [applications]);

    let linkArray = [];
    if (!currentApplication || currentApplication?.length === 0) {
        linkArray = [{ icon: "file-new", title: "New Application", link: "/practiceInformation", description: "" }];
    }

    let getMessageCenter = () => {
        if (messageCount && messageCount > 0) {
            return <div key="message-center" className="badge-number">{messageCount}</div>
        }
        return "";

    }

    const toggleNotSubmittedGrouping = () => {
        setIsNotsubmittedCollapsed(!isNotsubmittedCollapsed);
    }

    const toggleSubmittedGrouping = () => {
        setIsSubmittedCollapsed(!isSubmittedCollapsed);
    }

    const toggleClosedGrouping = () => {
        setIsClosedCollapsed(!isClosedCollapsed);
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "Dashboard",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    const displayApplications = () => {
        return <div>
            <Form autoComplete="off">
                <Row>
                    <div key="createapplication" className="dashboard-new-link col-6" >
                        <NavLink key="Start a New Application"
                            title="Start a New Application"
                            description="Please select Create application to start a new application"
                            button="Create Application"
                            onClickFunction={() => { setShowTinModal(true) }}
                        />
                    </div>
                </Row>
                <Row>
                    <div className={"row col-12 titleBar applicationgrouping"} onClick={toggleNotSubmittedGrouping}>                       
                        <div className="col-10 groupHeader">
                            Not Submitted
                        </div>
                        <div className="col-1 toggleArrow">
                            {isNotsubmittedCollapsed ? <FontAwesomeIcon icon={faAngleRight} />
                                : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                    </div>
                    {isNotsubmittedCollapsed ? "" :
                        notSubmittedApplications?.map((app, index) => {
                            return (<div key={app.doingBusinessAsName + " " + index} className=" col-6" >
                                <ApplicationStatus app={app} />
                            </div>)
                        })}
                </Row>
                <Row>
                    <div className={"row col-12 titleBar applicationgrouping"} onClick={toggleSubmittedGrouping} >
                        <div className="col-10 groupHeader">
                            Submitted
                        </div>
                        <div className="col-1 toggleArrow">
                            {isSubmittedCollapsed ? <FontAwesomeIcon icon={faAngleRight} />
                                : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                    </div>
                    {isSubmittedCollapsed ? "" :
                        submittedApplications?.map((app, index) => {
                            return (<div key={app.doingBusinessAsName + " " + index} className=" col-6" >
                                <ApplicationStatus app={app} />
                            </div>)
                        })
                    }
                </Row>
                <Row>
                    <div className={"row col-12 titleBar applicationgrouping"} onClick={toggleClosedGrouping} >
                        <div className="col-10 groupHeader">
                            Closed                             
                        </div>
                        <div className="col-1 toggleArrow">
                            {isClosedCollapsed ? <FontAwesomeIcon icon={faAngleRight} />
                                : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                    </div>
                    {isClosedCollapsed ? "" : closedApplications?.map((app, index) => {
                        return (<div key={app.doingBusinessAsName + " " + index} className=" col-6" >
                            <ApplicationStatus app={app} />
                        </div>)
                    }) }
                </Row>
            </Form>
        </div>
    }

    const displayApplication = () => {
        return <div>
            {linkArray?.length > 0 && <div className="subtitle">What would you like to do?</div>}
            <ApplicationStatus status={status} description={description} percentComplete={percentComplete} appId={appId}/>
            <div>
                {linkArray.map((link, index) => {
                    return <div key={link.title + " " + index} className="dashboard-nav-link">
                        {link.title === "Message Center" && getMessageCenter()}
                        <NavLink key={link.title}
                            index={index}
                            icon={link.icon}
                            link={link.link}
                            appId={link.appId}
                            title={link.title}
                            description={link.description}
                            onClickFunction={link.onClick}
                        />
                    </div>;

                })}
            </div>
        </div>
    }

    return <div className="dashboard">
        <SectionHeader>Welcome!</SectionHeader>
        {multipleApplicationsEnabled ? displayApplications() : displayApplication()}
        <TaxIdModal showTinModal={showTinModal}
            initTaxId={""}
            closeModal={() => { setShowTinModal(false) }} />
    </div>;
}