export const PracticeLimitationsTypes = {
    NA: "N/A",
    AcceptsPatientsThrough19: "AcceptsPatientsThrough19",
    AcceptsPatientsThrough20: "AcceptsPatientsThrough20",
    AcceptsFemalePatientsOnly: "AcceptsFemalePatientsOnly",
    AcceptsMalePatientsOnly: "AcceptsMalePatientsOnly",
    CulturalCompetencyTrainingCompleted: "CulturalCompetencyTrainingCompleted",
    NotAcceptingNewPatients: "NotAcceptingNewPatients",
    OffersTelemedicineServices: "OffersTelemedicineServices",
    OffersTelemonitoring: "OffersTelemonitoring",
    PatientsYounger: "PatientsYounger",
    AcceptsPatientsYounger: "AcceptsPatientsYounger",
    PatientsOlder: "PatientsOlder",
    AcceptsPatientsOlder: "AcceptsPatientsOlder",
    YoungerChildren: "YoungerChildren",
    AcceptsYoungerChildren: "AcceptsYoungerChildren",
    NoChildrenUnderTheAge: "NoChildrenUnderTheAge",
    AcceptsNoChildrenUnderTheAge: "AcceptsNoChildrenUnderTheAge",
};

Object.freeze(PracticeLimitationsTypes);