export const getApiUrl = async () => {
    const apiExtension = `api/AppSettings`;
    const response = await fetch(apiExtension, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    const url = await response.json();
    return (url);
};


export const getTimoutSettings = async () => {
    const apiExtension = `api/AppSettings/getTimeoutConfig`;
    const response = await fetch(apiExtension, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    const timeout = await response.json();
    return (timeout);
};

