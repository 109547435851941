import { combineReducers } from "redux";
import dashboard from './dashboardReducer';
import user from './userReducer';
import professionalApplication from './professionalApplicationReducer';
import document from './documentReducer';

let rootReducer = combineReducers({ dashboard, user, professionalApplication, document });

rootReducer.cookiesObject = null;

export default rootReducer;