import * as appSettingsApi from "../apis/appSettingsApi";
const professionalApplicationExtension = "ProfessionalApplication/";
const referenceListExtension = "ReferenceLists/";

export const getApplications = async (userInfo) => {
    const username = userInfo.userName;
    const token = userInfo.access_token;
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + professionalApplicationExtension + username;
    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(null);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const getApplicationDetails = async (appId, userInfo) => {
    const token = userInfo.access_token;
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + professionalApplicationExtension + "getDetails/" + appId;
    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(null);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const createApplication = async (application, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + professionalApplicationExtension;
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(application),
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                let result = response.json()
                return Promise.reject(result);
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const updateApplication = async (application, submit, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + professionalApplicationExtension + '?submit=' + submit;
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(application),
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                let result = response.json()
                return Promise.reject(result);
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const deleteApplication = async (id, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + professionalApplicationExtension + '?id=' + id;
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(null);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const getReferenceList = async (listName, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + referenceListExtension + listName;
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            return Promise.resolve(null);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
};