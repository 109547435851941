import * as appSettingsApi from "../apis/appSettingsApi";

const clientLoggingExtension = "ClientLogging/";

export const logInfo = async (clientLoggingModel, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + clientLoggingExtension + "logInfo";
    const token = userInfo?.access_token;    
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(clientLoggingModel),
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                let result = response.json()
                return Promise.reject(result);
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const logDebug = async (clientLoggingModel, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + clientLoggingExtension + "logDebug";
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(clientLoggingModel),
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                let result = response.json()
                return Promise.reject(result);
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const logWarning = async (clientLoggingModel, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + clientLoggingExtension + "logWarning";
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(clientLoggingModel),
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                let result = response.json()
                return Promise.reject(result);
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}

export const logError = async (clientLoggingModel, userInfo) => {
    const apiUrl = await appSettingsApi.getApiUrl();
    const url = apiUrl + clientLoggingExtension + "logError";
    const token = userInfo.access_token;
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(clientLoggingModel),
    }).then(response => {
        if (response && response.status && response.status === 200) {
            return Promise.resolve(response.json());
        } else {
            if (response && response.status === 400) {
                let result = response.json()
                return Promise.reject(result);
            }
            return Promise.reject(response);
        }
    }).catch(err => {
        return Promise.reject(err);
    });
}