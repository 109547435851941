import * as types from '../constants/actionTypes';
export const userInitialState = {
    userInfo: null,
    isFetching: true,
    error: "",
    userExists: null,
    taxIdInUse: '',
    npiIsValid: '',
    success: false,
    emailConfirmation: false,
    resetSuccess: false,
    accountCreated: false,
    emailVerified: false,
    welcomeComplete: false,
    resendEmailError: null,
    forgotPasswordError: null,
    featureFlags:[]
};

export default function userReducer(state = userInitialState, action) {
    switch (action.type) {
        case types.SIGNUP_UPDATE_USER_INFO_START:
            return { ...state, isFetching: true, error: null, accountCreated: false };
        case types.SIGNUP_UPDATE_USER_INFO_SUCCESS:
            return { ...state, accountCreated: action.accountCreated, isFetching: false, resendEmailError: null};
        case types.SIGNUP_UPDATE_USER_INFO_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case types.SIGNUP_UPDATE_USER_INFO_INITIALIZE_SUCESS:
            return { ...state, isFetching: true, error: null, accountCreated: false };

        case types.SIGNUP_GET_USER_BY_EMAIL_START:
            return { ...state, isFetching: true, error: null, userExists: null };
        case types.SIGNUP_GET_USER_BY_EMAIL_SUCCESS:
            return { ...state, userExists: action.userExists, isFetching: false };
        case types.SIGNUP_GET_USER_BY_EMAIL_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case types.SIGNUP_GET_TAX_ID_START:
            return { ...state, isFetching: true, taxIdError: null, taxIdInUse: '' };
        case types.SIGNUP_GET_TAX_ID_SUCCESS:
            return { ...state, taxIdInUse: action.taxIdInUse, isFetching: false };
        case types.SIGNUP_GET_TAX_ID_FAILURE:
            return { ...state, isFetching: false, taxIdError: action.error };
        case types.SIGNUP_GET_TAX_ID_CANCEL:
            return { ...state, taxIdInUse: '', isFetching: false };

        case types.SIGNUP_GET_NPI_START:
            return { ...state, isFetching: true, error: null, npiIsValid: '' };
        case types.SIGNUP_GET_NPI_SUCCESS:
            return { ...state, npiIsValid: action.npiIsValid, isFetching: false };
        case types.SIGNUP_GET_NPI_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case types.SIGNUP_RESEND_EMAIL_START:
            return { ...state, isFetching: true, resendEmailError: null };
        case types.SIGNUP_RESEND_EMAIL_SUCCESS:
            return { ...state, isFetching: false };
        case types.SIGNUP_RESEND_EMAIL_FAILURE:
            return { ...state, isFetching: false, resendEmailError: action.error };
        case types.SIGNUP_RESEND_EMAIL_RESET:
            return { ...state, isFetching: false, resendEmailError: null };

        case types.SIGNIN_GET_USER_INFO_START:
            return { ...state, isFetching: true, error: null, ready: false};
        case types.SIGNIN_GET_USER_INFO_SUCCESS:
            return { ...state, userInfo: action.userInfo, authenticated: true, isFetching: false, ready: true};
        case types.SIGNIN_GET_USER_INFO_FAILURE:
            return { ...state, isFetching: false, error: action.error, ready: true };

        case types.LOGOUT:
            return { ...state, userInfo: null, authenticated: false, welcomeComplete: false };
        case types.LOGOUT_SUCCESS:
            return { ...state, success: action.success, isFetching: false };
        case types.LOGOUT_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        case types.FORGOT_PASSWORD_START:
            return { ...state, isFetching: true, forgotPasswordError: null, emailConfirmation: false };
        case types.FORGOT_PASSWORD_SUCCESS:
            return { ...state, emailConfirmation: action.emailConfirmation, isFetching: false };
        case types.FORGOT_PASSWORD_FAILURE:
            return { ...state, forgotPasswordError: action.error, isFetching: false };

        case types.RESET_PASSWORD_START:
            return { ...state, userInfo: null, resetSuccess: false };
        case types.RESET_PASSWORD_SUCCESS:
            return { ...state, resetSuccess: action.resetSuccess, isFetching: false };
        case types.RESET_PASSWORD_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        case types.CONFIRM_EMAIL_START:
            return { ...state, userInfo: null, emailVerified: false };
        case types.CONFIRM_EMAIL_SUCCESS:
            return { ...state, emailVerified: action.emailVerified, isFetching: false };
        case types.CONFIRM_EMAIL_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        case types.GET_TIMEOUT_SETTINGS_START:
            return { ...state, timeoutSettings: null};
        case types.GET_TIMEOUT_SETTINGS_SUCCESS:
            return { ...state, timeoutSettings: action.timeoutSettings, isFetching: false };
        case types.GET_TIMEOUT_SETTINGS_FAILURE:
            return { ...state, error: action.error, isFetching: false };

        case types.WELCOME_COMPLETE:
            return { ...state, welcomeComplete: true };

        case types.FEATURE_FLAG_SUCCESS:         
            return { ...state, featureFlags: { ...state.featureFlags, [action.key]: action.value } };
        default:
            return { ...state };
    }
}