import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../../constants/actionTypes';
import { validateRequiredFields } from '../../../../constants/validations';

export const NavigationFooter = (props) => {
    let { onNextPageClick, submitIsDisplayed = false, onSaveClick, onBackButtonClick, BackButtonIsDisplayed = true, isButtonDisabled = false,
        isValidData = (() => alert("isValidData not defined")), requiredFields } = props;

    const [cookies, setCookie] = useCookies(['isFormDataSaved']);
    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.user.userInfo);

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "PracticeInformation",
            methodName: methodName,
            jsonResult: jsonResult
        };

        if (loggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, loggingModel, userInfo });
        }
    }

    const navigateBack = () => {

        if (cookies.isFormDataSaved === "false") {
            if (!window.confirm("Unsaved changes will be lost, Do you want to Continue?")) {
                return;
            }
        }

        var message = BackButtonIsDisplayed ? "Navigating back in form" : "Returning to dashboard page.";
        var methodName = "onBackButtonClick";
        var jsonResult = JSON.stringify(userInfo);
        logInfoWrite(message, methodName, jsonResult);

        onBackButtonClick();
    }

    const saveData = () => {
        if (!isValidData()) {
            alert("Please correct errors on form before saving.");
            return;
        }
        onSaveClick();
    }

    const navigateNext = () => {
        let missingField = validateRequiredFields(requiredFields);
        if (missingField !== '') {
            alert(missingField + " is missing. Please enter value and try again.");
            return;
        }
        if (!isValidData()) {
            alert("Please correct errors on form before saving.");
            return;
        }

        var message = submitIsDisplayed ? "Submitting Form" : "Navigating to Next page.";
        var methodName = "onNextButtonClick";
        var jsonResult = JSON.stringify(userInfo);
        logInfoWrite(message, methodName, jsonResult);


        onNextPageClick();
    }

    return <Row className="navigation-footer navbar fixed-bottom">
        <Col md={6}></Col>
        <Col className="button-group pull-right">
            <Button onClick={navigateBack}>{BackButtonIsDisplayed ? "BACK" : "RETURN TO DASHBOARD"}</Button>
            <Button onClick={saveData} disabled={isButtonDisabled}>SAVE</Button>
            <Button onClick={navigateNext} disabled={isButtonDisabled}>{submitIsDisplayed ? "SUBMIT" : "NEXT"}</Button>
        </Col>
    </Row>;
}

NavigationFooter.propTypes = {
    onNextPageClick: PropTypes.func,
    submitIsDisplayed: PropTypes.bool,
    onSaveClick: PropTypes.func,
    onBackButtonClick: PropTypes.func,
    BackButtonIsDisplayed: PropTypes.bool,
    isValidData: PropTypes.func,
    requiredFields: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.any
        })
    )
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

