import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as types from "../../constants/actionTypes";
import { Col, Label, Input, Row, Form, Modal, ModalBody, Button, ModalHeader } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { ClientLoggingModel } from '../../models/ClientLoggingModel';
import { LoadingAnimation } from '../common/LoadingAnimation';
import { VerifyEmail } from '../common/VerifyEmail';
import { useFeatureFlag } from '../../common/useFeatureFlag';

export const Welcome = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((state) => state.user.userInfo);
    const error = useSelector((state) => state.user.error);
    const isFetching = useSelector((state) => state.user.isFetching);
    const [username, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const [showCredentialError, setShowCredentialError] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [saveEmailChecked, setSaveEmailChecked] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    //Get the feature flag value on welcome so we can catch it.
    const { enabled: credentialingEnabled } = useFeatureFlag('125081_PAMMultipleApplicationsPerUser');
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    const currentApplication = useSelector((state) => state.professionalApplication.applications);
    const isFetchingApp = useSelector((state) => state.professionalApplication.isAppDataFetching);

    useEffect(() => {
        if (cookies.saveEmailChecked === "true") {
            setSaveEmailChecked(true);
            if (cookies.userName) {
                setUserName(cookies.userName);
            }
        }

        dispatch({ type: types.GET_TIMEOUT_SETTINGS_START });
    }, []);

    useEffect(() => {
        let message = "";
        let methodName = "useEffect[userInfo, isFetching, error]";
        let jsonResult = "";

        if (!isFetching) {
            if (userInfo && error === null) {
                setShowCredentialError(false);
                setCookie('saveEmailChecked', saveEmailChecked, { path: '/', maxAge: 31536000 });
                if (saveEmailChecked) {
                    var date = new Date();
                    date.setDate(date.getDate() + 30);
                    setCookie('userName', username, { path: '/', maxAge: 31536000 });
                }
                else {
                    removeCookie("userName");
                }

                message = "Getting application data.";
                jsonResult = JSON.stringify(username);
                logInfoWrite(message, methodName, jsonResult);
                dispatch({ type: types.GET_PROFESSIONAL_APPLICATIONS_START, username: username });
            }
            else if (userInfo === null && error) {
                message = "Login failure for " + username + ".";
                jsonResult = JSON.stringify(username);
                logWarningWrite(message, methodName, jsonResult);
                if (error.includes("Email is not confirmed.")) {
                    setShowModal(true);
                } else {
                    setShowCredentialError(true);
                }
                setPageLoading(false);
            }
        }
    }, [userInfo, isFetching, error]);

    useEffect(() => {
        console.log(currentApplication);
        if (!isFetchingApp) {
            let message = "Application data fetch complete. Navigate to dashboard";
            let jsonResult = JSON.stringify(username);
            logWarningWrite(message, "UserEffect-isFetchingApp", jsonResult);
            setPageLoading(false);
            history.push(`/Dashboard`);
        }
    }, [isFetchingApp]);

    const handleCheckboxChange = (e) => {
        setSaveEmailChecked(e);
    }

    const goToSignUp = (e) => {
        var message = "Navigating to SignUp page.";
        var methodName = "goToSignUp";
        var jsonResult = JSON.stringify(username) ?? null;
        var loggingModel = {
            message: message,
            pageName: "Welcome",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        logInfoWrite(message, methodName, jsonResult);
        history.push(`/SignUp`);
    }

    const goToForgotPassword = (e) => {
        e.preventDefault();
        var message = "Navigating to ForgotPassword page.";
        var methodName = "goToForgotPassword";
        var jsonResult = JSON.stringify(username);
        logInfoWrite(message, methodName, jsonResult);
        history.push(`/ForgotPassword`);
    }

    const goToDashboard = (e) => {
        e.preventDefault();
        var message = username + " navigating to Dashboard page.";
        var methodName = "goToDashboard";
        var jsonResult = JSON.stringify(username);
        logInfoWrite(message, methodName, jsonResult);
        dispatch({ type: types.SIGNIN_GET_USER_INFO_START, username: username, password: password });
        setPageLoading(true);
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "Welcome",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    const logWarningWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "Welcome",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        dispatch({ type: types.LOG_WARNING_START, clientLoggingModel, userInfo });
    }

    return <div className="welcome">
        <Form onSubmit={goToDashboard}>
            <Row>
                <h3>Register and Log In</h3>
            </Row>
            <Row>
                <Col md={5} className="div-welcome">
                    <h5 className="welcome-heading">Step 1 - Register</h5>
                    <p className="text-msg">Whether you are interested in becoming a contracted provider/facility or
                        if you are already a contracted provider/facility; we have dedicated staff here to assist you.
                    </p>
                    <br />
                    <p className="text-msg">Our staff can help you with:</p>
                    <ul className="text-msg">
                        <li>Applications to become a new contracted provider or facility (Facility Coming Soon)</li>
                        <li>Modify your existing contract (Coming Soon)</li>
                        <li>Update addresses, phone & fax numbers, etc. (Coming Soon)</li>
                        <li>Terminate a provider or facility (Coming Soon)</li>
                        <li>Add providers or facilities to your existing contract (Coming Soon)</li>
                        <li>Check the status of your application/request</li>
                    </ul>
                    <br />
                    <p className="text-msg">The information, tools and resources needed to support your office are on this website.
                        <strong> Please Register to be able to Log In and stay connected with us.</strong> Together, we can help you give your patients-and our members-the quality, cost-efficient care they deserve.</p>
                    <Input type="button" className="btn btn-primary btn-register" value="REGISTER" onClick={goToSignUp} />
                </Col>
                <Col md={5} className="div-welcome">

                    <h5 className="welcome-heading">Step 2 - Log In</h5>
                    <div><Label className="text-msg">Manage your account</Label></div>
                    {showCredentialError ? <div><Label className="text-error">Invalid Username/Password.</Label></div> : ""}
                    {
                        pageLoading ?
                            <LoadingAnimation /> :
                            <div>

                                <Input required type="text" className="input" placeholder="Email Address" value={username} onChange={(e) => setUserName(e.target.value)} />
                                <Input required type="password" className="input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <Row className="checkbox-wrapper">
                                    <Col><Input className="checkbox " type="checkbox" checked={saveEmailChecked} onClick={(e) => handleCheckboxChange(e.target.checked)} /> Save Email </Col>
                                    <Col><a href="#" onClick={goToForgotPassword} > Forgot Password?</a> </Col>
                                </Row>

                                <Input type="submit" className="login btn lg-button" value="LOG IN" />
                            </div>
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12}><span className="sub-header">Resources</span></Col>
                <Col md={12}><span className="header-links">Helpful Links:</span></Col>
                <Col md={12}>
                    <p><a target="_blank" rel="noreferrer" href="https://www.bswhealthplan.com/SiteCollectionDocuments/Providers/Forms-Guides/Provider-Process-Joining-Network.pdf">Process for joining BSWHP</a></p>
                    <p><a target="_blank" rel="noreferrer" href="https://www.bswhealthplan.com/SiteCollectionDocuments/Providers/Provider-BSWHP-FAQ-on-New-Aperture-CVO.pdf">BSWHP FAQ on new Aperture CVO</a></p>
                </Col>
            </Row>
        </Form>
        <Modal isOpen={showModal} className="verify-email-modal modal-dialog-centered">
            <ModalHeader close={<Button close onClick={() => { setShowModal(false) }} />} />
            <ModalBody>
                <VerifyEmail email={username} closeVerifyEmail={() => { setShowModal(false) }} />
            </ModalBody>
        </Modal>
    </div>;
}