import React, { useState, useEffect } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as types from '../../constants/actionTypes';
import * as validations from '../../constants/validations';
import { FormField } from '../common/FormField';
import { SectionHeader } from '../common/SectionHeader';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';
import { ClientLoggingModel } from '../../models/ClientLoggingModel';

export const ResetPassword = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const resetSuccess = useSelector((state) => state.user.resetSuccess);
    const errorWhileChangingPassword = useSelector((state) => state.user.error);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [enableResetButton, setEnableResetButton] = useState(false);
    const [params, setParams] = useState(null);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    

    const [errors, setErrors] = useState({
        newPassword: '',
        confirmPassword: '',
    });

    useEffect(() => {
        const { location } = props; 
        const locationParams = location.search ? queryString.parse(location.search)
            : location.searchParms ? queryString.parse(location.searchParms) : null;
        setParams(locationParams);
       
    }, []);

    useEffect(() => {
        let valid = validations.validateForm(errors);
        if (valid && newPassword && confirmPassword
        ) {
            setEnableResetButton(true);
        }
        else {
            setEnableResetButton(false);
        }
    }, [errors, newPassword, confirmPassword]);

    const saveInputs = (event) => {
        const { name, value } = event.target;
        let errs = { ...errors };
        switch (name) {
            case "newPassword":
                errs.newPassword = validations.validatePassword(value);
                if (confirmPassword) {
                    errs.confirmPassword = validations.validateConfirmPassword(confirmPassword, value);
                }
                setNewPassword(value);
                break;
            case "confirmPassword":
                errs.confirmPassword = validations.validateConfirmPassword(value, newPassword)
                setConfirmPassword(value);
                break;
        }
        setErrors(errs);

    }


    const resetNow = (e) => {
        //var loggingModel = {
        //    message: "Performing API call to perform password reset for " + userInfo.userName + ".",
        //    pageName: "ResetPassword",
        //    methodName: "resetNow",
        //    jsonResult: JSON.stringify(userInfo)
        //};

        var message = "Performing API call to perform password reset for " + params.email + ".";
        var methodName = "resetNow";
        var jsonResult = JSON.stringify(params.email)

        //setClientLoggingModel(loggingModel);

        logInfoWrite(message, methodName, jsonResult);
        dispatch({ type: types.RESET_PASSWORD_START, password: newPassword, email: params.email, token: params.token, });
    }

    const goToLogin = (e) => {
        e.preventDefault();
        var message = "Returning to login page.";
        var methodName = "goToLogin";
        var jsonResult = JSON.stringify(params.email);
        logInfoWrite(message, methodName, jsonResult);
        history.push('/');
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "ResetPassword",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel: clientLoggingModel, userInfo: params.email });
        }
    }
   
    return <div className="welcome">
        <SectionHeader>Reset Your Password</SectionHeader>
        <Row>
            <Col md={6}>
                <FormField type="password"
                    required
                    name="newPassword"
                    id="newPassword"
                    label="New Password"
                    error={errors.newPassword}
                    placeholder="New Password"
                    onChange={saveInputs}
                    value={newPassword}
                />                
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormField type="password"
                    required
                    name="confirmPassword"
                    id="confirmPassword"
                    label="Confirm Password"
                    error={errors.confirmPassword}
                    placeholder="Confirm Password"
                    onChange={saveInputs}
                    value={confirmPassword}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <Button className="btn btn-primary" size="small" disabled={!enableResetButton} onClick={resetNow}> Reset </Button>
            </Col>
        </Row>

        {resetSuccess ?
            <Row>
                <Col md={12}>
                    <span>
                        Reset password was successful. Click here to <a href="#" onClick={goToLogin}> login </a>.</span>
                </Col>
            </Row> : ""}

        {errorWhileChangingPassword ? <Row>
                <Col md={12}>
                <span className="error">
                    {errorWhileChangingPassword}</span>
                </Col>
            </Row> : ""}      
    </div>;
}

ResetPassword.propTypes = {
    location: PropTypes.object
}



