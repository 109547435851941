import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Label, Input } from 'reactstrap';
import * as types from '../../../../constants/actionTypes';
import * as validators from '../../../../constants/validations';
import { useHistory } from 'react-router';
import { NavigationFooter, scrollToTop } from './NavigationFooter';
import { SectionHeader } from '../../../common/SectionHeader';
import { SubSectionHeader } from '../../../common/SubSectionHeader';
import { CustomAlert } from '../../../common/CustomAlert';
import { useCookies } from 'react-cookie';
import { ClientLoggingModel } from '../../../../models/ClientLoggingModel';
import { FormField } from '../../../common/FormField';
import { LoadingAnimation } from '../../../common/LoadingAnimation';
import * as validations from '../../../../constants/validations';

export const PracticeContactInfo = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((state) => state.user.userInfo);
    if (!userInfo) {
        history.push("/")
    }
    const [pageLoading, setPageLoading] = useState(true);
    const [cookies, setCookie] = useCookies(['isFormDataSaved']);
    const [navigation, setNavigation] = useState(null);
    const [showAlert, setShowAlert] = useState("");
    const [checked, setChecked] = useState(false);
    let application = useSelector((state) => state.professionalApplication.applicationBeingUpdated);
    
    const apiErrors = useSelector((state) => state.professionalApplication.error);
    const contacts = application?.practiceContacts ?? [];
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);
    const primaryContact = contacts?.find(function (c) {
        return c.contactType === 'Primary'
    });
    const credentialingContact = contacts?.find(function (c) {
        return c.contactType === 'Credentialing'
    });
    const [pc, setPC] = useState({
        contactType: 'Primary',
        name: primaryContact?.name,
        email: primaryContact?.email,
        phone: primaryContact?.phone,
        fax: primaryContact?.fax,
        websiteAddress: primaryContact?.websiteAddress
    });
    const [cc, setCC] = useState({
        contactType: 'Credentialing',
        name: credentialingContact?.name,
        email: credentialingContact?.email,
        phone: credentialingContact?.phone,
        fax: credentialingContact?.fax,
        websiteAddress: null

    });
    const [errors, setErrors] = useState({
        pcName: '',
        pcEmail: '',
        pcPhone: '',
        pcFax: '',
        pcWebsiteAddress: '',
        ccName: '',
        ccEmail: '',
        ccPhone: '',
        ccFax: '',
    });

    const requiredFields = [{ key: 'Primary Contact Name', value: pc.name },
    { key: 'Primary Contact Email', value: pc.email },
    { key: 'Primary Contact Phone', value: pc.phone },
    { key: 'Primary Contact Website Address', value: pc.websiteAddress },
    { key: 'Credentialing Contact Name', value: cc.name },
    { key: 'Credentialing Contact Email', value: cc.email },
    { key: 'Credentialing Contact Phone', value: cc.phone },
    ];


    useEffect(() => {
        if (checked) {
            let updatedErrors = { ...errors };
            updatedErrors.ccName = validators.validateLength('name', cc.name, 80, 1) || validations.validateNA(cc.name);
            updatedErrors.ccEmail = validators.validateEmail(cc.email) || validations.validateNA(cc.email);
            updatedErrors.ccPhone = validators.validatePhone(cc.phone);
            updatedErrors.ccFax = validators.validateFax(cc.fax);
            setErrors(updatedErrors);
            setChecked(false);
        }
    }, [cc]);

    const onSaveClick = () => {
        setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
        setShowAlert("");
        //Intermittent Save
        if (validators.validateForm(errors)) {
            application.practiceContacts = [pc, cc];
            var message = "Updating professional contract.";
            var methodName = "onSaveClick";
            var jsonResult = JSON.stringify(application);
            logInfoWrite(message, methodName, jsonResult);
            application.practiceContacts = [pc, cc];
            dispatch({ type: types.UPDATE_PROFESSIONAL_APPLICATION_START, application: application, submit: false });
            dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: application });
        }
        else {
            setNavigation(null);
        }
    }
    const onBackButtonClick = () => {
        history.push("/practiceLocationInfo");
    }
    const onNextButtonClick = () => {
        setNavigation("/ProviderInformation");
        onSaveClick();
    }

    const apiCallSuccess = useSelector((state) => state.professionalApplication.applicationApiCalledSuccess);
    useEffect(() => {
        if (apiCallSuccess && application) {
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            if (navigation)
                history.push(navigation);
            else {
                scrollToTop();
                setShowAlert("success");
            }
        } else if (apiErrors) {
            scrollToTop();
            setShowAlert("danger");
        }
        setNavigation(null);
    }, [apiCallSuccess, apiErrors]);

    useEffect(() => {
        if (userInfo) {
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            setPageLoading(false);
        }
    }, []);

    const onFocusOutTrimSpaces = (e, type) => {
        const { name, value } = e.target;
        e.preventDefault();
        let updatedErrors = { ...errors };
        let newValue = value.trim()

        if (type === "pc") {
            switch (name) {
                case 'name':
                    updatedErrors.pcName = !newValue || validators.validateLength(name, newValue, 80, 1) || validations.validateNA(newValue);
                    break;
                case 'email':
                    updatedErrors.pcEmail = validators.validateEmail(newValue) || validations.validateNA(newValue);
                    break;
            }
            setErrors(updatedErrors);
            setPC({ ...pc, [name]: newValue });
        } else if (type === "cc") {
            switch (name) {
                case 'name':
                    updatedErrors.ccName = !newValue || validators.validateLength(name, newValue, 80, 1) || validations.validateNA(newValue);
                    break;
                case 'email':
                    updatedErrors.ccEmail = validators.validateEmail(newValue) || validations.validateNA(newValue);
                    break;
            }
            setErrors(updatedErrors);
            setCC({ ...cc, [name]: newValue });
        }

    }

    const inputPCchangeHandler = event => {
        const { name, value } = event.target;
        event.preventDefault();

        let updatedErrors = { ...errors };
        switch (name) {
            case 'name':
                updatedErrors.pcName = !value || validators.validateLength(name, value, 80, 1) || validations.validateNA(value);
                break;
            case 'email':
                updatedErrors.pcEmail = validators.validateEmail(value) || validations.validateNA(value);
                break;
            case 'phone':
                updatedErrors.pcPhone = validators.validatePhone(value);
                break;
            case 'fax':
                updatedErrors.pcFax = validators.validateFax(value);
                break;
            case 'websiteAddress':
                updatedErrors.pcWebsiteAddress = validators.validateUrl(value) || validations.validateNA(value);
                break;
            default:
                break;
        }
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
        setPC({ ...pc, [name]: value });
        setErrors(updatedErrors);
    }
    const inputCCchangeHandler = event => {
        const { name, value } = event.target;
        event.preventDefault();

        let updatedErrors = { ...errors };
        switch (name) {
            case 'name':
                updatedErrors.ccName = validators.validateLength(name, value, 80, 1) || validations.validateNA(value);
                break;
            case 'email':
                updatedErrors.ccEmail = validators.validateEmail(value) || validations.validateNA(value);
                break;
            case 'phone':
                updatedErrors.ccPhone = validators.validatePhone(value);
                break;
            case 'fax':
                updatedErrors.ccFax = validators.validateFax(value);
                break;
            default:
                break;
        }
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
        setCC({ ...cc, [name]: value });
        setErrors(updatedErrors);
    }

    const onMatchPrimaryClick = (event) => {
        if (event.target.checked) {
            let credentialing = { ...cc };
            credentialing.contactType = 'Credentialing';
            credentialing.name = pc?.name;
            credentialing.email = pc?.email;
            credentialing.phone = pc?.phone;
            credentialing.fax = pc?.fax;
            credentialing.websiteAddress = null;
            setCC(credentialing);
            setChecked(true);
        }
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "PracticeContactInfo",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    return (
        <div>
            {showAlert.length > 0 && <CustomAlert type={showAlert} />}
            <SectionHeader>Practice Contact Information</SectionHeader>
            {
                pageLoading ?
                    <LoadingAnimation /> :
                    <div>
                        <Form autoComplete="off">
                            <SubSectionHeader>Primary Contact</SubSectionHeader>
                            <Row form>
                                <Col md={6}>
                                    <FormField type="text"
                                        required
                                        name="name"
                                        id="pcContactName"
                                        label="Contact Name"
                                        value={pc.name || ""}
                                        onFocusOut={(e) => onFocusOutTrimSpaces(e, "pc")}
                                        onChange={inputPCchangeHandler}
                                        error={errors.pcName}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormField type="email"
                                        required
                                        name="email"
                                        id="pcContactEmail"
                                        label="Contact Email"
                                        value={pc.email || ""}
                                        onFocusOut={(e) => onFocusOutTrimSpaces(e, "pc")}
                                        onChange={inputPCchangeHandler}
                                        error={errors.pcEmail}
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormField type="InputMask"
                                        required
                                        name="phone"
                                        id="pcContactPhone"
                                        label="Contact Phone"
                                        value={pc.phone || ""}
                                        onChange={inputPCchangeHandler}
                                        error={errors.pcPhone}
                                        placeholder="XXX-XXX-XXXX"
                                        mask="999-999-9999"
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormField type="InputMask"
                                        name="fax"
                                        id="pcContactFax"
                                        label="Contact Fax"
                                        value={pc.fax || ""}
                                        onChange={inputPCchangeHandler}
                                        error={errors.pcFax}
                                        placeholder="XXX-XXX-XXXX"
                                        mask="999-999-9999"
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormField type="url"
                                        required
                                        name="websiteAddress"
                                        id="websiteAddress"
                                        label="Website Address"
                                        value={pc.websiteAddress || ""}
                                        onFocusOut={(e) => onFocusOutTrimSpaces(e, "pc")}
                                        onChange={inputPCchangeHandler}
                                        error={errors.pcWebsiteAddress}
                                        maxLength={200}
                                        placeholder="http://example.com"
                                    />
                                </Col>
                            </Row>
                            <SubSectionHeader>Credentialing Contact</SubSectionHeader>
                            <Row>
                                <Col>
                                    <Input type="checkbox" name="check" id="matchPrimary" onClick={(e) => onMatchPrimaryClick(e)} />
                                    <Label className="checkbox-label" for="matchPrimary" check>Same as Primary Contact</Label>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormField type="text"
                                        required
                                        name="name"
                                        id="ccContactName"
                                        label="Contact Name"
                                        value={cc.name || ""}
                                        onFocusOut={(e) => onFocusOutTrimSpaces(e, "cc")}
                                        onChange={inputCCchangeHandler}
                                        error={errors.ccName}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormField type="email"
                                        required
                                        name="email"
                                        id="pcContactEmail"
                                        label="Contact Email"
                                        value={cc.email || ""}
                                        onFocusOut={(e) => onFocusOutTrimSpaces(e, "cc")}
                                        onChange={inputCCchangeHandler}
                                        error={errors.ccEmail}
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormField type="InputMask"
                                        required
                                        name="phone"
                                        id="ccContactPhone"
                                        label="Contact Phone"
                                        value={cc.phone || ""}
                                        onChange={inputCCchangeHandler}
                                        error={errors.ccPhone}
                                        placeholder="XXX-XXX-XXXX"
                                        mask="999-999-9999"
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormField type="InputMask"
                                        name="fax"
                                        id="ccContactFax"
                                        label="Contact Fax"
                                        value={cc.fax || ""}
                                        onChange={inputCCchangeHandler}
                                        error={errors.ccFax}
                                        placeholder="XXX-XXX-XXXX"
                                        mask="999-999-9999"
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <NavigationFooter
                            onNextPageClick={onNextButtonClick}
                            onSaveClick={onSaveClick}
                            onBackButtonClick={onBackButtonClick}
                            isValidData={() => validators.validateForm(errors)}
                            requiredFields={requiredFields}
                        />
                    </div>
            }
        </div>
    );
}