export const DASHBOARD_GET_APPLICATION_STATUS = "DASHBOARD_GET_APPLICATION_STATUS";
export const DASHBOARD_GET_APPLICATION_STATUS_SUCCESS = "DASHBOARD_GET_APPLICATION_STATUS_SUCCESS";
export const DASHBOARD_GET_APPLICATION_STATUS_FAILURE = "DASHBOARD_GET_APPLICATION_STATUS_FAILURE";

export const DASHBOARD_GET_MESSAGES = "DASHBOARD_GET_MESSAGES";
export const DASHBOARD_GET_MESSAGES_SUCCESS = "DASHBOARD_GET_MESSAGES_SUCCESS";
export const DASHBOARD_GET_MESSAGES_FAILURE = "DASHBOARD_GET_MESSAGES_FAILURE";

export const SIGNUP_CREATE_USER_INFO_START = "SIGNUP_CREATE_USER_INFO_START";
export const SIGNUP_CREATE_USER_INFO_SUCCESS = "SIGNUP_CREATE_USER_INFO_SUCCESS";
export const SIGNUP_CREATE_USER_INFO_FAILURE = "SIGNUP_CREATE_USER_INFO_FAILURE";

export const SIGNUP_UPDATE_USER_INFO_START = "SIGNUP_UPDATE_USER_INFO_START";
export const SIGNUP_UPDATE_USER_INFO_SUCCESS = "SIGNUP_UPDATE_USER_INFO_SUCCESS";
export const SIGNUP_UPDATE_USER_INFO_FAILURE = "SIGNUP_UPDATE_USER_INFO_FAILURE";

export const SIGNUP_UPDATE_USER_INFO_INITIALIZE_START = "SIGNUP_UPDATE_USER_INFO_INITIALIZE_START";
export const SIGNUP_UPDATE_USER_INFO_INITIALIZE_SUCESS = "SIGNUP_UPDATE_USER_INFO_INITIALIZE_SUCESS";

export const SIGNIN_GET_USER_INFO_START = "SIGNIN_GET_USER_INFO_START";
export const SIGNIN_GET_USER_INFO_SUCCESS = "SIGNIN_GET_USER_INFO_SUCCESS";
export const SIGNIN_GET_USER_INFO_FAILURE = "SIGNIN_GET_USER_INFO_FAILURE";

export const SIGNUP_GET_USER_BY_EMAIL_START = "SIGNUP_GET_USER_BY_EMAIL_START";
export const SIGNUP_GET_USER_BY_EMAIL_SUCCESS = "SIGNUP_GET_USER_BY_EMAIL_SUCCESS";
export const SIGNUP_GET_USER_BY_EMAIL_FAILURE = "SIGNUP_GET_USER_BY_EMAIL_FAILURE";

export const SIGNUP_GET_TAX_ID_START = "SIGNUP_GET_TAX_ID_START";
export const SIGNUP_GET_TAX_ID_SUCCESS = "SIGNUP_GET_TAX_ID_SUCCESS";
export const SIGNUP_GET_TAX_ID_FAILURE = "SIGNUP_GET_TAX_ID_FAILURE";
export const SIGNUP_GET_TAX_ID_CANCEL = "SIGNUP_GET_TAX_ID_CANCEL";

export const SIGNUP_GET_NPI_START = "SIGNUP_GET_NPI_START";
export const SIGNUP_GET_NPI_SUCCESS = "SIGNUP_GET_NPI_SUCCESS";
export const SIGNUP_GET_NPI_FAILURE = "SIGNUP_GET_NPI_FAILURE";

export const SIGNUP_RESEND_EMAIL_START = "SIGNUP_RESEND_EMAIL_START";
export const SIGNUP_RESEND_EMAIL_SUCCESS = "SIGNUP_RESEND_EMAIL_SUCCESS";
export const SIGNUP_RESEND_EMAIL_FAILURE = "SIGNUP_RESEND_EMAIL_FAILURE";
export const SIGNUP_RESEND_EMAIL_RESET = "SIGNUP_RESEND_EMAIL_RESET";

export const REFRESH_APPLICATION_API_CALL_FLAG = "REFRESH_APPLICATION_API_CALL_FLAG";
export const GET_PROFESSIONAL_APPLICATIONS_START = "GET_PROFESSIONAL_APPLICATIONS_START";
export const GET_PROFESSIONAL_APPLICATIONS_SUCCESS = "GET_PROFESSIONAL_APPLICATIONS_SUCCESS";
export const GET_PROFESSIONAL_APPLICATIONS_FAILURE = "GET_PROFESSIONAL_APPLICATIONS_FAILURE";

export const STORE_APPLICATION = "STORE_APPLICATION";
export const INITIALIZE_PROFESSIONAL_APPLICATION = "INITIALIZE_PROFESSIONAL_APPLICATION";
export const CLEAN_PROFESSIONAL_APPLICATIONS = "CLEAN_PROFESSIONAL_APPLICATIONS";

export const CREATE_PROFESSIONAL_APPLICATION_START = "CREATE_PROFESSIONAL_APPLICATION_START";
export const CREATE_PROFESSIONAL_APPLICATION_SUCCESS = "CREATE_PROFESSIONAL_APPLICATION_SUCCESS";
export const CREATE_PROFESSIONAL_APPLICATION_FAILURE = "CREATE_PROFESSIONAL_APPLICATION_FAILURE";
export const CREATE_PROFESSIONAL_APPLICATION_FLAGOFF_SUCCESS = "CREATE_PROFESSIONAL_APPLICATION_FLAGOFF_SUCCESS";

export const UPDATE_PROFESSIONAL_APPLICATION_START = "UPDATE_PROFESSIONAL_APPLICATION_START";
export const UPDATE_PROFESSIONAL_APPLICATION_SUCCESS = "UPDATE_PROFESSIONAL_APPLICATION_SUCCESS";
export const UPDATE_PROFESSIONAL_APPLICATION_FAILURE = "UPDATE_PROFESSIONAL_APPLICATION_FAILURE";

export const DELETE_PROFESSIONAL_APPLICATION_START = "DELETE_PROFESSIONAL_APPLICATION_START";
export const DELETE_PROFESSIONAL_APPLICATION_SUCCESS = "DELETE_PROFESSIONAL_APPLICATION_SUCCESS";
export const DELETE_PROFESSIONAL_APPLICATION_FAILURE = "DELETE_PROFESSIONAL_APPLICATION_FAILURE";

export const GET_APPLICATION_DETAILS_START = "GET_APPLICATION_DETAILS_START";
export const GET_APPLICATION_DETAILS_SUCCESS = "GET_APPLICATION_DETAILS_SUCCESS";
export const GET_APPLICATION_DETAILS_FAILURE = "GET_APPLICATION_DETAILS_FAILURE";

export const APPLICATION_GET_REFERENCE_LIST_START = "APPLICATION_GET_REFERENCE_LIST_START";
export const APPLICATION_GET_TAXID_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_ACCREDITATIONTYPE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_COUNTY_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_COUNTY_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_LANGUAGE_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_LANGUAGE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_STATE_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_STATE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_TAXONOMY_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_TAXONOMY_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_ACCREDITATIONTYPE_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_ACCREDITATIONTYPE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_DEGREEANDCERTIFICATION_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_DEGREEANDCERTIFICATION_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_ADDRESSTYPE_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_ADDRESSTYPE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_CONTACTTYPE_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_CONTACTTYPE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_MALPRACTICEINSURANCETYPE_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_MALPRACTICEINSURANCETYPE_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_MALPRACTICEINSURANCECARRIER_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_MALPRACTICEINSURANCECARRIER_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_MALPRACTICEINSURANCEAMOUNTS_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_MALPRACTICEINSURANCEAMOUNTS_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_PROVIDERFACILITYAFFILIATION_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_PROVIDERFACILITYAFFILIATION_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_SPECIALTY_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_SPECIALTY_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_PRACTICELIMITATION_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_PRACTICELIMITATION_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_MENTALHEALTH_REFERENCE_LIST_SUCCESS = "APPLICATION_GET_MENTALHEALTH_REFERENCE_LIST_SUCCESS";
export const APPLICATION_GET_REFERENCE_LIST_FAILURE = "APPLICATION_GET_REFERENCE_LIST_FAILURE";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SET_DEFAULT_DOCUMENT_STATE = "SET_DEFAULT_DOCUMENT_STATE"

export const FILE_UPLOAD_START = "FILE_UPLOAD_START";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";

export const FILE_DOWNLOAD_START = "FILE_DOWNLOAD_START";
export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAILURE = "FILE_DOWNLOAD_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CONFIRM_EMAIL_START = "CONFIRM_EMAIL_START";
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";

export const GET_TIMEOUT_SETTINGS_START = "GET_TIMEOUT_SETTINGS_START";
export const GET_TIMEOUT_SETTINGS_SUCCESS = "GET_TIMEOUT_SETTINGS_SUCCESS";
export const GET_TIMEOUT_SETTINGS_FAILURE = "GET_TIMEOUT_SETTINGS_FAILURE";

export const WELCOME_COMPLETE = "WELCOME_COMPLETE";


export const LOG_INFO_START = "LOG_INFO_START";
export const LOG_DEBUG_START = "LOG_DEBUG_START";
export const LOG_WARNING_START = "LOG_WARNING_START";
export const LOG_ERROR_START = "LOG_ERROR_START";
export const FEATURE_FLAG_SUCCESS = "FEATURE_FLAG_SUCCESS";