import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'reactstrap';
import * as types from '../../constants/actionTypes';
import { useHistory, useLocation } from 'react-router';
import { ClientLoggingModel } from '../../models/ClientLoggingModel';


export const VerifyEmail = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let location = useLocation();
    let { email, closeVerifyEmail } = props;
    const error = useSelector((state) => state.user.resendEmailError);
    const [emailVerified, setEmailVerified] = useState(false);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);

    useEffect(() => {
        dispatch({
            type: types.SIGNUP_RESEND_EMAIL_RESET
        });
    }, []);

    useEffect(() => {
        setEmailVerified(error === 'Email already confirmed');
    }, [error]);

    const resendEmail = () => {
        if (email) {
            var message = "Resending Email " + email + ".";
            var methodName = "createAccount";
            var jsonResult = JSON.stringify(email);
            logInfoWrite(message, methodName, jsonResult);
            dispatch({
                type: types.SIGNUP_RESEND_EMAIL_START, email
            });
        }

    }

    const goToLogin = (e) => {
        e.preventDefault();
        if (location.pathname === "/" && closeVerifyEmail) {
            closeVerifyEmail();
        }
        else {
            var message = "Returning to login page.";
            var methodName = "goToLogin";
            var jsonResult = email;
            logInfoWrite(message, methodName, jsonResult);
            history.push('/');
        }
    }

    const logInfoWrite = (message, methodName, jsonResult) => {
        var loggingModel = {
            message: message,
            pageName: "VerifyEmail",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, user: null });
        }
    }

    return <div className='text-center verify-email'>
        <FontAwesomeIcon className='envelope-icon' icon={faEnvelopeOpenText} />
        <p><b>Confirm your email address</b></p>
        <p>A confirmation email has been sent to {email} <br />
        Check your email and click on the confirmation link to continue</p>
        <Button onClick={resendEmail} disabled={emailVerified}>Resend Email</Button>
        {emailVerified ?
            <p>
                Your email has been successfully confirmed. Click here to <a href="#" onClick={goToLogin}> Login </a>.
                            </p>
            :
            <p className="error">
                {error}
            </p>
        }
    </div>;
}

VerifyEmail.propTypes = {
    email: PropTypes.string,
    closeVerifyEmail: PropTypes.func
};